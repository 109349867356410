import React, { useState, useMemo } from "react";
import {
    Box,
    Typography,
    TextField,
    styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { axiosPostCall } from "../../../services/apisCall";
import { validateEmail, validateUserId } from "../../../utils/common";
import ErrorMessage from '../../../utils/ErrorMessage.json';
import Logger from "../../../lib/Logger";
import ResetPasswordForm from "../ResetPwd";
import AuthFooter from "../../../layouts/AuthFooter";
import { ToastContainer } from "react-toastify";
import { renderErrorMessage } from "../../../lib/toastMessage";
import LogoBlue from "../../../assets/svgs/logo";

const HeadingSection = styled('div')(({ theme }) => ({
    width: '450px',
    display: 'flex',
    background: '#FFFFFF',
    padding: '30px 40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
        borderRadius: '0px',
        width: 'auto',
        padding: '20px 30px',
    }
}));

const ForgotPwdForm = () => {
    const navigate = useNavigate();

    const logger = new Logger("ForgotPwdForm");

    const [userId, setUserId] = useState("");
    const [userIdError, setUserIdError] = useState("");
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [forgotLoading, setForgotLoading] = useState(false);

    const errorMessagesMap = {
        "blank": ErrorMessage?.[4003],
        "invalid": ErrorMessage?.[4006],
    }

    const handleSendOTP = async (e) => {
        try {
            e.preventDefault();
            if (!userId) return setUserIdError("blank");
            if (!validateUserId(userId) && !validateEmail(userId)) return setUserIdError("invalid");

            setForgotLoading(true);

            await axiosPostCall("forgotPassword", { userId });

            setForgotLoading(false);
            setOpenResetPassword(true);

        } catch (error) {
            logger.error(error.message);
            setForgotLoading(false);
            return renderErrorMessage(error.message);
        }
    }

    const resetUserIdError = useMemo(() => {
        return () => {
            setUserIdError("");
        };
        // eslint-disable-next-line
    }, [userIdError]);


    const handleUserIdChange = (e) => setUserId(e.target.value);
    const handleFormSubmit = (e) => e.preventDefault()

    return (
        <><ToastContainer />
            {(openResetPassword)
                ?
                <ResetPasswordForm
                    setOpenResetPassword={setOpenResetPassword}
                    userId={userId}
                />
                :
                <HeadingSection>
                    <Box className='FormContent' sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%'
                    }}
                        onSubmit={handleFormSubmit}
                    >

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Box sx={{
                                width: '210px',
                                height: '60px',
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <LogoBlue isAuth={true} height={60} />
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            gap: '24px'
                        }}>
                            <Box className='FormHeading' sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="custom24600" sx={{ color: '#282828' }}>
                                    Forgot your password?
                                </Typography>
                            </Box>
                            <Box className="FormGroup" sx={{
                                display: 'flex',
                                gap: '16px',
                                flexDirection: 'column',
                                width: '100%'
                            }}>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: '6px',
                                    flexDirection: 'column',
                                }}>

                                    <Typography variant="custom13500" sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        color: '#282828',
                                    }}>Email address</Typography>

                                    <TextField
                                        id="userID"
                                        placeholder="Enter your email"
                                        type={"text"}
                                        variant="outlined"
                                        size="small"
                                        error={Boolean(userIdError)}
                                        onClick={resetUserIdError}
                                        helperText={userIdError ? errorMessagesMap[userIdError] : ""}
                                        onChange={(e) => handleUserIdChange(e)}
                                        value={userId}
                                        inputProps={{
                                            sx: {
                                                height: '30px',
                                                fontSize: '14px',
                                                color: '#282828'
                                            },
                                        }} fullWidth />

                                </Box>

                                <AuthFooter
                                    btnText={"Forgot password"}
                                    navigationMessage={"Back to"}
                                    navigateText={"Login"}
                                    btnCheckHandler={(e) => handleSendOTP(e)}
                                    navigationCheckHandler={() => navigate("/login")}
                                    loadingBtn={forgotLoading}
                                />
                            </Box>
                        </Box>


                    </Box>
                </HeadingSection>
            }
        </>

    )
}

export default ForgotPwdForm;