
// eslint-disable-next-line
const DashboardActiveIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.37339 1.39906V9.37339H1.39906V1.39906H9.37339ZM10.7724 0H0.800033C0.358015 0 0 0.358015 0 0.800033V10.7724H10.7724V0Z" fill="#0C66E4" />
            <path d="M9.37339 14.6096V22.5839H1.39906V14.6096H9.37339ZM10.7724 13.2105H0V23.183C0 23.625 0.358015 23.983 0.800033 23.983H10.7724V13.2105Z" fill="#E56910" />
            <path d="M14.6256 14.6136L22.5999 14.6256L22.5879 22.5999L14.6136 22.5879L14.6256 14.6136ZM13.2285 13.2125L13.2115 23.985L23.184 24C23.626 24 23.985 23.643 23.985 23.201L24 13.2285L13.2275 13.2115L13.2285 13.2125Z" fill="#0C66E4" />
            <path d="M22.584 1.39906V9.37339H14.6096V1.39906H22.584ZM13.2106 0V10.7724H23.983V0.800033C23.983 0.358015 23.625 0 23.183 0H13.2106Z" fill="#E56910" />
        </svg>
    )
}

export default DashboardActiveIcon