
// eslint-disable-next-line
const ChatActiveIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_235_3979)">
            <path d="M22.071 0H12.002C8.68801 0 5.68801 1.343 3.51701 3.515C1.34501 5.686 0.00201416 8.686 0.00201416 12C0.00201416 15.314 1.34501 18.314 3.51701 20.485L4.50701 19.495C2.50501 17.494 1.40201 14.832 1.40201 12C1.40201 9.168 2.50501 6.506 4.50701 4.505C6.50801 2.503 9.17001 1.4 12.002 1.4H20.622L20.068 1.954L19.497 2.525L18.507 3.515L19.497 4.505L20.487 3.515L22.636 1.366C23.14 0.862 22.783 0 22.071 0Z" fill="#E56910" />
            <path d="M7.18899 11.2C7.62999 11.2 7.98899 11.559 7.98899 12C7.98899 12.441 7.62999 12.8 7.18899 12.8C6.74799 12.8 6.38899 12.441 6.38899 12C6.38899 11.559 6.74799 11.2 7.18899 11.2ZM7.18899 10.4C6.30499 10.4 5.58899 11.116 5.58899 12C5.58899 12.884 6.30499 13.6 7.18899 13.6C8.07299 13.6 8.78899 12.884 8.78899 12C8.78899 11.116 8.07299 10.4 7.18899 10.4Z" fill="#E56910" />
            <path d="M13.134 10.868L12.567 11.435C12.712 11.58 12.802 11.78 12.802 12C12.802 12.441 12.443 12.8 12.002 12.8C11.782 12.8 11.582 12.71 11.437 12.565L10.87 13.132C11.16 13.421 11.56 13.6 12.002 13.6C12.886 13.6 13.602 12.884 13.602 12C13.602 11.558 13.423 11.158 13.134 10.868Z" fill="#0C66E4" />
            <path d="M16.815 11.2C17.256 11.2 17.615 11.559 17.615 12C17.615 12.441 17.256 12.8 16.815 12.8C16.374 12.8 16.015 12.441 16.015 12C16.015 11.559 16.374 11.2 16.815 11.2ZM16.815 10.4C15.931 10.4 15.215 11.116 15.215 12C15.215 12.884 15.931 13.6 16.815 13.6C17.699 13.6 18.415 12.884 18.415 12C18.415 11.116 17.699 10.4 16.815 10.4Z" fill="#0C66E4" />
            <path d="M24.002 12C24.002 15.314 22.659 18.314 20.487 20.485C18.316 22.657 15.316 24 12.002 24C11.907 24 11.813 23.999 11.719 23.996C11.727 23.998 11.736 23.999 11.745 24H1.93302C1.23502 24 0.879016 23.172 1.33802 22.665L3.51702 20.485L4.50702 19.495L5.49702 20.485L3.64502 22.337L11.764 22.597C11.834 22.599 11.91 22.6 12.002 22.6C14.834 22.6 17.496 21.497 19.497 19.495C21.499 17.494 22.602 14.832 22.602 12C22.602 9.168 21.499 6.506 19.497 4.505L20.487 3.515C22.659 5.686 24.002 8.686 24.002 12Z" fill="#0C66E4" />
            <path d="M13.134 10.868L12.567 11.435C12.422 11.29 12.222 11.2 12.002 11.2C11.561 11.2 11.202 11.559 11.202 12C11.202 12.22 11.292 12.42 11.437 12.565L10.87 13.132C10.581 12.842 10.402 12.442 10.402 12C10.402 11.116 11.118 10.4 12.002 10.4C12.444 10.4 12.844 10.579 13.134 10.868Z" fill="#E56910" />
        </g>
        <defs>
            <clipPath id="clip0_235_3979">
                <rect width="24" height="24" fill="white" transform="translate(0.00201416)" />
            </clipPath>
        </defs>
    </svg>
)


export default ChatActiveIcon