import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  styled,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { renderErrorMessage } from "../../../lib/toastMessage";
import { axiosPostCall } from "../../../services/apisCall";
import { handleLogout } from "../../../services/apisCall";
import { removeBulkItems } from "../../../lib/cookies";

const FormContainer = styled("div")(({ theme }) => ({
  width: "450px",
  display: "flex",
  background: "#FFFFFF",
  padding: "30px 40px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
    width: "auto",
    padding: "20px 30px",
  },
}));

const InvitationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const Domain = searchParams.get("invitationId");
  const organisationEmail = searchParams.get("organisation");
  const uniqueId = searchParams.get("uniqueId");

  const handleSubmit = async () => {
    try {
      const res = await axiosPostCall("inviteResponse", {
        email: Domain,
        parentEmail: organisationEmail,
        id: uniqueId,
      });
      if (res) {
        handleLogout();
        removeBulkItems([
          { key: "sessionToken" },
          { key: "token" },
          { key: "exp" },
        ]);

        navigate("/login");
      }
    } catch (e) {
      renderErrorMessage(e.message);
    }
  };

  return (
    <FormContainer>
      <Box
        sx={{
          textAlign: { xs: "center", sm: "start", md: "start", lg: "start" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h5" sx={{ color: "#282828" }}>
            You are wellcomed to join the team
          </Typography>
        </Box>

        <Button variant="contained" color="primary" onClick={handleSubmit}>
          join
        </Button>
      </Box>
    </FormContainer>
  );
};

export default InvitationForm;
