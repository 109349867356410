import { Typography, Box, Button, SvgIcon } from '@mui/material'
import React from 'react'
import ArrowRightLine from '../../assets/svgs/icons/arrow-right-line'
import InformationFillIcon from '../../assets/svgs/icons/fill/information-fill'
import { useNavigate } from 'react-router-dom'
import { setLocalStorageItem } from '../../lib/localStorage'
import { useBoolean } from '../../hooks/use-boolean'
import LoadingButton from '@mui/lab/LoadingButton'

const KycSectionData = ({ setSkipKyc }) => {
    const navigate = useNavigate();
    const skipLoading = useBoolean(false);
    const handleSkip = () => {
        skipLoading.onTrue();
        setSkipKyc(true);
        setLocalStorageItem("isSkipKyc", true);
        // skipLoading.onFalse();
    }


    return (
        <>
            <Box sx={{
                color: 'success.lighter',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                flexGrow: 1,
            }}>
                <SvgIcon sx={{
                    color: 'inherit',
                    width: '20px',
                    height: '20px',
                }}>
                    <InformationFillIcon color={"currentColor"} />
                </SvgIcon>

                <Typography sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    color: 'success.lighter',
                }}>

                    Complete your <b>KYC</b> today & enjoy full <strong>benefits</strong> of your <strong>Sarv Workspace 365</strong> account.
                </Typography>
            </Box>


            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px'
            }}>
                <LoadingButton
                    color='success'
                    sx={{
                        color: 'success.lighter',
                        fontWeight: 600,
                        fontSize: '12px'
                    }}
                    onClick={handleSkip}
                    loading={skipLoading.value}

                >
                    Skip for now
                </LoadingButton>
                <Button
                    color='success'
                    sx={{
                        backgroundColor: 'success.lighter',
                        color: 'success.100',
                        fontWeight: 600,
                        fontSize: '12px',
                        "&:hover": {
                            backgroundColor: 'success.lighter',
                            color: 'success.100',
                        }
                    }}
                    onClick={(e) => navigate("/settings", { state: 1 })}
                    endIcon={
                        <SvgIcon sx={{
                            color: 'success.100',
                            width: '16px',
                            height: '16px',
                        }}>
                            <ArrowRightLine color={"currentColor"} />
                        </SvgIcon>
                    }
                >
                    Processed for KYC
                </Button>
            </Box>
        </>
    )
}

export default KycSectionData
// sarv - icons / fill / system / information - 2 - fill