import React from "react";
import { Box, Typography } from "@mui/material";
import WorkspaceSteps from "./workspaceSteps/workspaceSetUpSteps";
import { ToastContainer } from "react-toastify";

const CreateDomain = () => {
  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // height: "100vh",
          // overflowY: "hidden",

        }}
      >
        {/* <Typography
        sx={{
          color: "grey.100",
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        Stand by for the big reveal – coming soon!
      </Typography> */}
        <WorkspaceSteps />
      </Box>
    </>
  );
};

export default CreateDomain;
