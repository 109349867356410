
// eslint-disable-next-line
const ListsActiveIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2311_14640)">
                <path d="M22 1.4C22.331 1.4 22.6 1.669 22.6 2V22C22.6 22.331 22.331 22.6 22 22.6H2C1.669 22.6 1.4 22.331 1.4 22V2C1.4 1.669 1.669 1.4 2 1.4H22ZM22 0H2C0.895 0 0 0.895 0 2V22C0 23.105 0.895 24 2 24H22C23.105 24 24 23.105 24 22V2C24 0.895 23.105 0 22 0Z" fill="#0C66E4" />
                <path d="M17.302 6.802H11.097C10.7115 6.802 10.399 7.11451 10.399 7.5C10.399 7.8855 10.7115 8.198 11.097 8.198H17.302C17.6875 8.198 18 7.8855 18 7.5C18 7.11451 17.6875 6.802 17.302 6.802Z" fill="#E56910" />
                <path d="M17.286 11.302H11.081C10.6955 11.302 10.383 11.6145 10.383 12C10.383 12.3855 10.6955 12.698 11.081 12.698H17.286C17.6715 12.698 17.984 12.3855 17.984 12C17.984 11.6145 17.6715 11.302 17.286 11.302Z" fill="#E56910" />
                <path d="M17.286 15.802H11.081C10.6955 15.802 10.383 16.1145 10.383 16.5C10.383 16.8855 10.6955 17.198 11.081 17.198H17.286C17.6715 17.198 17.984 16.8855 17.984 16.5C17.984 16.1145 17.6715 15.802 17.286 15.802Z" fill="#E56910" />
                <path d="M7.6 6.7C8.041 6.7 8.4 7.059 8.4 7.5C8.4 7.941 8.041 8.3 7.6 8.3C7.159 8.3 6.8 7.941 6.8 7.5C6.8 7.059 7.159 6.7 7.6 6.7ZM7.6 5.9C6.716 5.9 6 6.616 6 7.5C6 8.384 6.716 9.1 7.6 9.1C8.484 9.1 9.2 8.384 9.2 7.5C9.2 6.616 8.484 5.9 7.6 5.9Z" fill="#E56910" />
                <path d="M7.6 11.2C8.041 11.2 8.4 11.559 8.4 12C8.4 12.441 8.041 12.8 7.6 12.8C7.159 12.8 6.8 12.441 6.8 12C6.8 11.559 7.159 11.2 7.6 11.2ZM7.6 10.4C6.716 10.4 6 11.116 6 12C6 12.884 6.716 13.6 7.6 13.6C8.484 13.6 9.2 12.884 9.2 12C9.2 11.116 8.484 10.4 7.6 10.4Z" fill="#E56910" />
                <path d="M7.6 15.7C8.041 15.7 8.4 16.059 8.4 16.5C8.4 16.941 8.041 17.3 7.6 17.3C7.159 17.3 6.8 16.941 6.8 16.5C6.8 16.059 7.159 15.7 7.6 15.7ZM7.6 14.9C6.716 14.9 6 15.616 6 16.5C6 17.384 6.716 18.1 7.6 18.1C8.484 18.1 9.2 17.384 9.2 16.5C9.2 15.616 8.484 14.9 7.6 14.9Z" fill="#E56910" />
            </g>
            <defs>
                <clipPath id="clip0_2311_14640">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>




    )
}

export default ListsActiveIcon;