import React, { useEffect, useState } from "react";
import CustomModal from "../../components/modal/customModal";
import {
  Box,
  Button,
  styled,
  Typography,
  Badge,
  Chip,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
// import { nameFormater } from '../../utils/common';

import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { nameFormater } from "../../utils/common";
import { axiosGetCall } from "../../services/apisCall";
import Scrollbar from "../../components/scrollbar";
import { minHeight } from "@mui/system";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[1000],
    padding: "0px",
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(1.4)",
      opacity: 0,
    },
  },
}));

const useStyles = styled((theme) => ({
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 16,
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#ECECF4",
  },
}));

function Organization({ org, onCollapse, collapsed }) {
  const classes = useStyles();

  let backgroundColor = "white";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        variant="outlined"
        style={{ backgroundColor }}
        sx={{ maxWidth: "auto" }}
      >
        <CardHeader
          avatar={
            <Tooltip title={`${_.size(org.children)} Sub Profile`} arrow>
              {_.size(org.children) > 0 ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                      {_.size(org.children)}
                    </Typography>
                  }
                >
                  <Avatar alt={org?.name} src={org?.profileUrl} />
                </StyledBadge>
              ) : (
                <Avatar alt={org?.name} src={org?.profileUrl} />
              )}
            </Tooltip>
          }
          title={nameFormater(org?.name)}
          subheader={org?.email}
          action={
            org?.permission && (
              <Box sx={{ ml: "15px" }}>
                <Chip label={nameFormater(org?.permission)} />
              </Box>
            )
          }
        />

        {/* <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <BusinessIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Add Sub Profile" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <AccountBalanceIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Add Sub Account" />
                </MenuItem>
            </Menu> */}
        <IconButton
          size="small"
          onClick={onCollapse}
          className={clsx(classes.expand, {
            [classes.expandOpen]: !collapsed,
          })}
        >
          {_.size(org.children) ? <ExpandMoreIcon /> : ""}
        </IconButton>
      </Card>
    </Box>
  );
}

function Account({ a }) {
  const classes = useStyles();
  // const [{ isDragging }, drag] = useDrag({
  //     item: { name: a.name, type: "account" },
  //     end: (item, monitor) => {
  //         const dropResult = monitor.getDropResult();
  //         if (item && dropResult) {
  //             alert(`You moved ${item.name} to ${dropResult.name}`);
  //         }
  //     },
  //     collect: (monitor) => ({
  //         isDragging: monitor.isDragging()
  //     })
  // });
  const opacity = /* isDragging ? 0.4 : */ 1;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        variant="outlined"
        className={classes.root}
        // ref={drag}
        style={{ cursor: "pointer", opacity }}
        sx={{ padding: "10px" }}
      >
        <CardHeader
          avatar={
            <Avatar>{/* <AccountBalanceIcon color="secondary" /> */}</Avatar>
          }
          title={a.name}
          subheader={"kumawat.rakesh@mahindra.com"}
          action={
            <Box sx={{ ml: "15px" }}>
              <Chip label="L4" />
            </Box>
          }
          // title={a.name}
          // title={a.name}
        />
        {/* <Typography variant="body2" color="text.secondary">
                    kumawat.rakesh@mahindra.com
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    8503875941
                </Typography> */}
        <IconButton
          size="small"
          // onClick={onCollapse}
          // className={clsx(classes.expand, {
          //     [classes.expandOpen]: !collapsed
          // })}
        >
          {/* <ExpandMoreIcon /> */}
        </IconButton>
      </Card>
    </Box>
  );
}

function Product({ p }) {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card variant="outlined" className={classes.root}>
        <CardContent>
          <Typography variant="subtitle2">{p.name}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

function Node({ o, parent }) {
  const [collapsed, setCollapsed] = React.useState(o.collapsed);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  React.useEffect(() => {
    o.collapsed = collapsed;
  });

  const T = parent
    ? TreeNode
    : (props) => (
        <Tree
          {...props}
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
        >
          {props.children}
        </Tree>
      );

  return collapsed ? (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    />
  ) : (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    >
      {_.map(o.account, (a) => (
        <TreeNode label={<Account a={a} />}>
          <TreeNode label={<Product p={a.product} />} />
        </TreeNode>
      ))}
      {_.map(o.children, (c) => (
        <Node o={c} parent={o} />
      ))}
    </T>
  );
}

const HierarchyModal = ({
  openHierarchy,
  handleClose,
  setOpenHierarchy,
  email,
  self,
}) => {
  const [organization, setOrganization] = useState({});

  const getHierarchy = async () => {
    try {
      let query = {};
      if (self) {
        query = { self: true };
      } else {
        query = { email };
      }
      const { result } = await axiosGetCall("getHierarchy", query);
      setOrganization(result);
    } catch (error) {
      console.error("error in fetching organzation tree");
    }
  };

  useEffect(() => {
    getHierarchy();
  }, []);

  const forceActions = () => (
    <Box
      sx={{
        mt: 3,
        mb: -2,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          setOpenHierarchy(false);
          handleClose(false);
        }}
      >
        Close
      </Button>
    </Box>
  );

  const description = (
    <Scrollbar
      sx={{
        maxWidth: "80vw",
        maxHeight: "80vh",
        minWidth: "80vw",
        minHeight: "80vh",
      }}
    >
      <Box bgcolor="background" padding={4}>
        {/* <DndProvider backend={HTML5Backend}> */}
        <Node o={organization} />
        {/* </DndProvider> */}
      </Box>
    </Scrollbar>
  );

  return (
    <CustomModal
      open={openHierarchy}
      title={"Hierarchy"}
      description={description}
      close={() => setOpenHierarchy(false)}
      action={forceActions()}
      isCloseIcon={true}
    />
  );
};

export default HierarchyModal;
