import { Box, Typography, TextField, InputAdornment, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CustomModal from '../../../components/modal/customModal';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LoadingButton from '@mui/lab/LoadingButton';
import { displayRazorpay } from '../../../lib/razorpay';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import { useDispatch } from 'react-redux';
import { updateWalletBalance } from '../../../redux/action/walletActions';
import { renderSuccessMessage } from '../../../lib/toastMessage';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AddAmountModal = ({ open, setOpen, fetchTransActionsList }) => {

    const [amount, setAmount] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const order_id = searchParams.get("order_id");

    const fetchBalance = async () => {
        try {
            const { balance } = await axiosGetCall("fetchWalletBalance");
            dispatch(updateWalletBalance(parseFloat(balance)));
            fetchTransActionsList();
        } catch (error) {
            console.log(error);
        }
    }

    const handleModalClose = () => {
        setOpen(false);
        navigate(`/settings?tab=3`, { replace: true });
    }

    const verifyPayment = async (data) => {
        try {

            const { message } = await axiosPostCall("verifyPayment", data);
            renderSuccessMessage(message);
            fetchBalance();
            navigate(`/settings?tab=3`, { replace: true });

        } catch (error) {
            console.error(error);
        }
    }

    const razorpayHandler = async (id) => {
        try {

            setLoadingButton(true);

            await displayRazorpay({ order: { id } }, verifyPayment);
            setLoadingButton(false);
            setOpen(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async () => {
        setLoadingButton(true);
        const { order: { id } } = await axiosGetCall("createOrder", { amount });
        navigate(`/settings?tab=${parseInt(searchParams.get("tab"))}&order_id=${id}`)
    }

    useEffect(() => {

        if (order_id) {
            razorpayHandler(searchParams.get("order_id"))
        }

        // eslint-disable-next-line
    }, [searchParams, order_id])

    const description = (
        <Box sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '20px'
        }}>
            <Box sx={{
                display: 'flex',
                gap: '6px',
                flexDirection: 'column'
            }}>
                <Typography sx={{ fontSize: '14px', color: 'grey.400' }}>
                    Add Money to Wallet
                </Typography>
                <TextField
                    id="addAmmount"
                    placeholder='Enter amount'
                    type="Number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CurrencyRupeeIcon />
                            </InputAdornment>
                        ),
                    }}
                    size={'small'}
                    fullWidth
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '6px',
                flexDirection: 'row'
            }}>
                <Button
                    variant='outlined'
                    size='small'
                    onClick={() => setAmount((prev) => Number(prev) + 1000)}
                >
                    + 1000
                </Button>
                <Button
                    variant='outlined'
                    size='small'
                    onClick={() => setAmount((prev) => Number(prev) + 3000)}
                >
                    + 3000
                </Button>
                <Button
                    variant='outlined'
                    size='small'
                    onClick={() => setAmount((prev) => Number(prev) + 5000)}
                >
                    + 5000
                </Button>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                mt: '20px',
                justifyContent: 'center'
            }}>
                <LoadingButton
                    variant='contained'
                    sx={{
                        boxShadow: 'none',
                        minWidth: '180px'
                    }}
                    disabled={!amount}
                    loading={loadingButton}
                    onClick={() => handleSubmit()}
                >
                    Continue
                </LoadingButton>
            </Box>
        </Box>
    )
    return (
        <CustomModal
            open={open}
            title={"Add Amount"}
            description={description}
            close={() => handleModalClose()}
        // action={deleteActions()}

        />
    )
}

export default AddAmountModal;