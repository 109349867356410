import { styled } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";

import React, { useRef, useState, useEffect } from "react";
import { Avatar, Box, IconButton, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Icon } from "@iconify/react";
import Tooltip from "@mui/material/Tooltip";
import { getforceVerifyPemission } from "../../utils/common";

const ProfilePictureCircle = ({ image, setImage }) => {
  const [renderImg, setRenderImg] = useState(null);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setRenderImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  return (
    <Box
      sx={{
        width: 130,
        height: 130,
        borderRadius: "50%",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
        "&:hover .edit-icon": {
          opacity: 1,
        },
      }}
      onClick={handleClick}
    >
      {image ? (
        <>
          <Avatar src={renderImg} sx={{ width: "100%", height: "100%" }} />

          <IconButton
            className="edit-icon"
            sx={{
              position: "absolute",
              bottom: 48,
              right: 43,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              opacity: 0,
              transition: "opacity 0.3s",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
          >
            <EditIcon />
          </IconButton>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "grey.300",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <IconButton>
            <Icon icon="fluent:camera-add-24-regular" />
          </IconButton>

          <Typography sx={{ fontSize: "12px", color: "grey.600" }}>
            Upload photo
          </Typography>
        </Box>
      )}

      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Box>
  );
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: theme.palette.grey[1000],
      right: "14px",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const ProfilePicture = ({ verified, setVeried, image, setImage }) => {
  const [allowForceVerify, setAllowforceVerify] = useState(false);

  useEffect(() => {
    const allowForceVerify = getforceVerifyPemission();
    setAllowforceVerify(allowForceVerify);
  }, []);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <ProfilePictureCircle image={image} setImage={setImage} />

      <Typography
        variant="body2"
        sx={{
          color: "grey.600",
          textAlignLast: "center",
          fontSize: "12px",
        }}
      >
        Allowed *.jpeg, *.jpg, *.png, *.gif <br />
        max size of 1 Mb
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          mt: "10px",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Typography
          sx={{
            color: "grey.700",
            fontWeight: 600,
            textAlignLast: "center",
          }}
        >
          Force Verified
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "grey.600",
              fontWeight: 400,
              textAlignLast: "center",
            }}
          >
            Check here to mark this user as Force Verified.
          </Typography>

          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "grey.500",
                },
              },
            }}
            title={allowForceVerify ? "Force Verify" : "Force Verify Disabled"}
          >
            <Box>
              <AntSwitch
                defaultChecked
                checked={verified}
                disabled={!allowForceVerify}
                onChange={() => setVeried(!verified)}
                inputProps={{ "aria-label": "ant design" }}
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Container>
  );
};

export default ProfilePicture;
