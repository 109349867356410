// ExistingDomainForm.js
import React, { useState } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button
} from '@mui/material';
import { isValidDomain } from '../../../utils/common';
import { renderErrorMessage } from '../../../lib/toastMessage';
import Logger from '../../../lib/Logger';

const logger = new Logger("existingDomainForm");

const ExistingDomainForm = ({ handleAddDomain, isDomainRegistered, domainName, setDomainName }) => {

    const [domainInput, setDomainInput] = useState(domainName)

    const handleSubmit = async () => {


        if (!isValidDomain(domainInput)) {

            return renderErrorMessage("Please enter a valid domain name")

        }

        handleAddDomain(domainInput)

    }


    return (
        <Box sx={{ mt: 3, width: '50%', margin: 'auto', border: '1px solid', borderColor: 'grey.300', borderRadius: '6px', padding: '22px 25px' }}>
            <Typography variant="h5">Enter your existing domain</Typography>
            <Typography variant="body2" sx={{ mt: 1, mb: 3 }}>
                We'll use a domain that you already own to set up your custom email. We'll have to prove that you own this domain later.
            </Typography>
            <TextField
                fullWidth
                label="Domain name"
                placeholder="example: your-company.com"
                value={domainInput}
                onChange={(e) => setDomainInput(e.target.value)}
                variant="outlined"
                sx={{ mb: 2 }}
                disabled={isDomainRegistered}
            />
            <Button variant="contained" onClick={handleSubmit} sx={{ display: isDomainRegistered ? 'none' : 'block' }}>
                Continue
            </Button>
        </Box>
    );
};

export default ExistingDomainForm;
