import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, Box } from "@mui/material"
import { useEffect, useState } from "react";
import { axiosGetCall } from "../../services/apisCall";


const VoicePlan = ({ planId, setSelectedPlans, setOpen, currentSelectedPlans, setCurrentSelectedPlans, priceCalculator }) => {
    const [slabs, setSlabs] = useState([]);
    const [selectedSlab, ] = useState("");
    const fetchSlabDetails = async () => {
        const response = await axiosGetCall("getSlabs", { planId })
        console.log("🚀 ~ fetchSlabDetails ~ response:", response?.data)
        setSlabs(response?.data?.call_slabs);
    }

    // const handleSubmit = () => {
    //     // handleAddProduct();
    //     setSelectedPlans((prev) => {
    //         if (currentSelectedPlans) {
    //             const temp = [...prev, currentSelectedPlans];
    //             return temp;
    //         }
    //         return prev;
    //     });
    //     setOpen(false);
    //     setCurrentSelectedPlans(null);
    // }

    const handleAddProduct = () => {
        setSelectedPlans((prev) => {
            if (currentSelectedPlans) {
                const temp = [...prev, currentSelectedPlans];
                return temp;
            }
            return prev;
        });
        setOpen(false);
        setCurrentSelectedPlans(null);
    };




    const handleChange = (e) => {
        // const selectedId = e.target.value;
        const selectedSlab = slabs.find((slab) => slab.id === e.target.value);
        // if (users > workSpaceMaxUser[planDetails?.plan]?.max) {
        setCurrentSelectedPlans((prev) => {
            const temp = { ...prev };
            const productDetails = temp;
            productDetails.slabId = e.target.value;
            productDetails.actualPrice = priceCalculator(productDetails?.credit, selectedSlab?.price, 0);
            productDetails.priceAnnually = priceCalculator(productDetails?.credit, selectedSlab?.price, 0);
            return temp;
        });
    }

    const handleCredit = (e) => {
        // const selectedSlab = slabs.find((slab) => slab.id === e.target.value);
        setCurrentSelectedPlans((prev) => {
            const temp = { ...prev };
            const productDetails = temp;
            productDetails.credit = parseInt(e.target.value, 10)
            productDetails.users = e.target.value;
            
            const selectedPlanSlab = slabs.find(
                (slab) => e.target.value >= slab.min_calls && e.target.value <= slab.max_calls
            );
            productDetails.slabId = selectedPlanSlab?.id;
            productDetails.selectedSlab = selectedPlanSlab;
            // const selectedPlanSlab = slabs.find((slab) => slab.id === productDetails?.slabId);
            // const maxUsers = workSpaceMaxUser[planDetails?.plan]?.max;
            productDetails.actualPrice = priceCalculator(e.target.value, selectedPlanSlab?.price, 0);
            productDetails.priceAnnually = priceCalculator(productDetails?.credit, selectedSlab?.price, 0);
            return temp;
        });
        // setCredit(e.target.value)
    }

    const activeSlab = slabs.find(
        (slab) => currentSelectedPlans?.credit >= slab.min_calls && currentSelectedPlans?.credit <= slab.max_calls
    );
    useEffect(() => {
        fetchSlabDetails();

        // eslint-disable-next-line
    }, [])
    return (
        <Stack direction={"column"} gap={1}>
            {/* <Box sx={{
                maxWidth: '350px',
                padding: '10px',
                mt: '10px',
                backgroundColor: '#f8d7da',
                borderRadius: '6px',
                color: '#842029',

            }}>
                {users <= 300 ? <Typography sx={{ fontSize: '14px' }}>
                    * Please buy <b>{bestPlanRecommendation(users)}</b> plan for  <b>{users}</b> licenses.
                </Typography> : <Typography sx={{ fontSize: '14px' }}>
                    * Please Contact Support for  <b>{users}</b> licenses.
                </Typography>}
            </Box> */}
            <TextField id="outlined-basic" size="medium" type="number" value={currentSelectedPlans?.credit} onChange={(e) => handleCredit(e)} label="Enter the Credit" variant="outlined" />

            <Box sx={{ mt: 2 }}>
                {slabs.map((slab, index) => (
                    <Box
                        key={index}
                        sx={{
                            p: 2,
                            my: 1,
                            border: "1px solid",
                            borderRadius: "8px",
                            borderColor:
                                activeSlab === slab ? "primary.main" : "grey.300",
                            bgcolor: activeSlab === slab ? "primary.light" : "grey.100",
                            color: activeSlab === slab ? "primary.contrastText" : "text.primary",
                        }}
                    >
                        <Typography variant="body1">
                            {slab.min_calls} - {slab.max_calls} Credits: <strong>{slab.price}</strong>
                        </Typography>
                    </Box>
                ))}
            </Box>
            
            <Button
                variant="contained"
                onClick={() => handleAddProduct()}
            >{`Add Product ₹${currentSelectedPlans?.actualPrice}`}</Button>
        </Stack>
    )
}

export default VoicePlan;