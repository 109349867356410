// const currentEnv = () => {
//     return process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "local";
// };

// const currentProduct = () => {
//     return process.env.REACT_APP_PRODUCT_ENV || "SARV";
// }
// const env = currentEnv();
// const product = currentProduct();

// const configFiles = await import(`./${product}/config.${env}`)

// const fetchConfig = () => {
//     return configFiles;
// };

// const configFile = fetchConfig();

// export {
//     configFile,
//     currentEnv,
//     currentProduct,
// };


import * as finalConfigFile from './config.js'
import * as commonConfig from './commonConfig.js'

// const env = process.env.REACT_APP_PRODUCT_ENV;

const currentEnv = () => process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "local";

const currentProduct = () => {
  return process.env.REACT_APP_PRODUCT_ENV || "SARV";
}

// const ENVIRONMENT = currentEnv();

// const configJson = await import(`./${env}/config.json`);
// const finalConfigFile = await import(`./${env}/config.${ENVIRONMENT}`);

const fetchConfig = () => finalConfigFile;

const mainConfig = fetchConfig();
const configFile = { ...mainConfig, ...commonConfig }


export {
  currentEnv,
  configFile,
  currentProduct
};
