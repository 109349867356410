import React, { useContext, useEffect, useState } from 'react';
import { BrandingContext } from '../../context/brandingContext';
import { getItem } from '../../lib/cookies';

const LogoBlue = ({ isAuth = false, height = 48 }) => {
    const theme = getItem("theme");
    // const colorMain = theme === "dark" ? "#FFFFFF" : "#3169AF";
    const [logo, setLogo] = useState("");
    const brandingContext = useContext(BrandingContext);

    useEffect(() => {
        if (Object.keys(brandingContext).length) {
            const { logoUrlLight = "", logoUrlDark } = brandingContext;

            const checkLogo = isAuth ? logoUrlLight : theme === 'light' ? logoUrlLight || logoUrlDark : logoUrlDark || logoUrlLight;

            setLogo(checkLogo);
        }
        //eslint-disable-next-line
    }, [brandingContext]);

    return (
        logo ? <img src={logo} alt="Logo" draggable={false} onClick={() => window.location.href = "/"} height={height} width={"auto"} />
            : <></>
    )
}

export default LogoBlue;

/*  <svg version="1.1"
            width={"160px"}
            // height={"56px"}
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 545 136"
            style={{ "enableBackground": "new 0 0 545 136" }}
            xmlSpace="preserve">
            <style type="text/css">
                {`
                .st0{fill:#3169AF;}
                .st1{opacity:0.22;fill:#124582;}
                .st2{fill:#FFFFFF;}
                .st3{fill:${colorMain}}
                `}
            </style>
            <g>
                <g>
                    <path className="st0" d="M128.3,70.1c0,3.3-0.3,6.6-0.8,9.7c-4.5,28.3-28,50.2-57.1,52.1c-1.4,0.1-2.7,0.1-4.1,0.1
                    c-34.2,0-62-27.7-62-62s27.7-62,62-62S128.3,35.9,128.3,70.1z"/>
                </g>
                <path className="st1" d="M127.5,79.8c-4.5,28.3-28,50.2-57.1,52.1L40.1,99.7c0,0,13.9,4.9,22-3l16,12.3l12.1-0.2l8.2-6L98.9,90
                L83.7,76.6c0,0,9.8-11.9,6.6-27.8l12.8,6.8L127.5,79.8z"/>
                <g>
                    <path className="st2" d="M71.2,28.1L71.2,28.1c1.2,0.2,2.3,0.4,3.4,0.7c6.2,1.4,10.8,5,13.9,10.5c1.4,2.5,2.4,5.2,2.9,8.1
                    c0.1,0.6,0.4,0.9,1,1.1c3.5,1.1,6.7,2.7,9.2,5.4c2.6,2.8,3.9,6.2,4.6,9.9c0.3,1.7,0.4,3.4,0,5.1c-0.1,0.2-0.1,0.5-0.3,0.7
                    c-0.1,0.2-0.5,0.2-0.5-0.1c0-0.1,0-0.2,0-0.3c0.4-7.8-3.3-13.1-9.9-16.7c-0.9-0.5-1.9-0.9-2.9-1.3c-1.1-0.4-1.1-0.4-1.1,0.7
                    c0,3.7-0.4,7.3-1.1,10.9c-0.6,2.8-1.8,5.3-2.9,7.9c-0.5,1.1-0.9,2.3-1.6,3.3c-0.3,0.4-0.2,0.6,0.2,0.9c2.2,1.2,4.3,2.4,6.4,3.8
                    c2.7,1.8,5.2,4,7.2,6.6c1.4,1.9,2.1,4,2.4,6.3c0.4,3.7,0.3,7.3-1.4,10.7c-2,4.1-5.3,6.6-9.4,8.3c-7.6,3.1-14.8,1.9-21.5-2.4
                    c-3.4-2.2-6.2-4.9-8.6-8.1c-0.3-0.4-0.6-0.5-1.1-0.3c-3.6,1.8-7.4,3.1-11.5,3.3c-5.8,0.3-9.7-3.6-11.3-7.8
                    c-1.2-3.2-0.8-6.4,0.3-9.6c1.1-3.5,2.8-6.7,5.1-9.7c0.3-0.4,0.3-0.6,0-0.9c-3.2-3.5-6.2-7.1-9.5-10.5c-0.1-0.1-0.2-0.2-0.3-0.3
                    c-0.3-0.3-0.6-0.2-1-0.2c-0.4,0.1-0.2,0.5-0.2,0.7c0.1,0.9,0.2,1.8,0.1,2.7c-0.3,2.1-2,3.7-4,4c-1.5,0.2-3,0.4-4.5,0.5
                    c-2.6,0.3-4.2,2.1-4.3,4.8c0,0.2,0.2,0.6-0.2,0.7c-0.5,0.1-0.6-0.3-0.7-0.6c-0.1-0.4-0.2-0.6-0.3-1c-0.3-1.2,0.1-2.9,0.1-2.9
                    c0.4-1.6,1.3-2.8,2.6-3.8c0.2-0.2,0.5-0.3,0.8-0.3c1.3,0.1,2.6,0,3.9,0.1c2.2,0.2,3.9-0.7,3.8-3.4c-0.1-1.2,0-2.4,0-3.7
                    c0-0.7,0.2-1.5,1-1.8c0.7-0.3,1.4,0,1.9,0.6c0.1,0.1,0.3,0.3,0.4,0.4c4.3,3.7,8.6,7.2,12.5,11.3c0.4,0.4,0.5,0.3,0.8-0.1
                    c0.9-1.2,1.9-2.3,2.8-3.4c2.3-2.7,4.5-5.4,7-7.9c0.8-0.8,0.8-0.8,0-1.6c-2.4-2.7-4.4-5.6-5.2-9.2c-0.8-3.5-0.9-6.9,0.8-10.2
                    c2.8-5.6,7-9.8,13.2-11.5c0.8-0.2,1.5-0.4,2.3-0.5c0,0,0,0,0,0C68.1,27.9,69.6,27.9,71.2,28.1z M89,47.6c0.1,0,0.1,0,0.1,0.1
                    c0,0,0.1,0,0.1,0l0,0c-0.1,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c-2.5-4.5-5.4-8.7-9.5-12c-3.1-2.5-6.5-4.2-10.6-4.5
                    c-5.1-0.4-9.5,1.4-13.3,4.8c-5,4.6-5.8,12.4-1.9,17.7c1.2,1.7,2.7,3.1,4.2,4.4c0.3,0.3,0.5,0.3,0.8,0c1-0.9,2.1-1.8,3.2-2.6
                    c4.7-3.5,9.5-6.5,15.5-7C81.5,47.9,85.2,47.1,89,47.6z M65.8,93.7c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.3-0.2,0.4-0.3
                    c2.8-2.1,5.5-4.3,8.1-6.7c2.2-2.1,4.4-4.2,6.2-6.7c0.4-0.6,0.5-0.9-0.2-1.3c-1.1-0.7-2.2-1.4-3.3-2.2c-3.2-2.3-6.3-4.6-9.4-7
                    c-3.4-2.6-7-4.8-10.1-7.7c-0.4-0.4-0.6-0.1-0.9,0.2c-2.1,2.1-4.2,4.2-6.3,6.3c-1.5,1.6-3.1,3.1-4.4,4.8c-0.3,0.4-0.3,0.6,0.1,1
                    c6.5,6.5,12.9,12.9,19.4,19.4C65.6,93.6,65.7,93.6,65.8,93.7z M85.7,107.7c2.4,0,4.6-0.6,6.5-1.9c3-2,5-4.6,5.2-8.3
                    c0.2-2.8-1.1-5-2.8-7c-3.4-4-7.6-7.2-11.9-10.2c-0.4-0.3-0.6-0.2-0.8,0.2c-4.1,5.4-8.8,10.3-14,14.6c-0.4,0.3-0.4,0.5,0,0.9
                    c1.9,2,3.8,4,5.6,6C76.7,105.6,80.8,107.5,85.7,107.7z M90.1,53.6c0-0.8,0-1.6,0-2.3c0-0.6-0.2-0.8-0.8-0.9
                    c-5.6-0.8-11-0.2-16.3,1.6c-4.6,1.5-8.8,3.8-12.6,6.8c-0.5,0.4-0.6,0.6,0,1c0.7,0.5,1.3,1,2,1.5c2.9,1.9,6,3.5,9.1,5.3
                    c4.2,2.5,8.5,4.9,12.7,7.3c0.5,0.3,0.7,0.2,0.9-0.2c0.4-0.6,0.8-1.2,1.1-1.9C88.9,65.9,90.1,59.9,90.1,53.6z M49.6,99.8
                    c2.9,0,6.6-0.8,9.3-2.1c0.5-0.2,0.4-0.4,0.2-0.7c-2.7-3.8-5.2-7.8-7.9-11.5c-2.2-2.9-4.6-5.7-7-8.5c-0.2-0.3-0.4-0.6-0.8-0.1
                    c-2.4,3-4,6.3-4.9,10c-0.9,3.8-0.3,7.2,2.8,9.9C43.7,99,46.5,99.8,49.6,99.8z"/>
                </g>
            </g>
            <g>
                <path className="st0" d="M180.1,105.5c-9.8,0-17.2-2.2-22-6.6c-4.9-4.4-7.4-10.1-7.7-17h8.9c0.5,5.2,2.6,9,6.5,11.5
                c3.8,2.5,8.5,3.7,13.9,3.7c5.2,0,9.3-1.1,12.5-3.2c3.2-2.1,4.7-5,4.7-8.6c0-3.8-1.6-6.6-4.9-8.4c-3.3-1.7-8.9-3.5-16.8-5.2
                c-8-1.7-13.7-4-17.1-7.1s-5.2-7-5.2-12c0-5.6,2.3-10.3,6.8-13.9s10.5-5.4,18-5.4c7.9,0,14.2,1.8,19,5.5c4.8,3.6,7.4,8.8,7.6,15.4
                h-9c-0.9-8.4-6.7-12.6-17.3-12.6c-5.1,0-9.1,1-11.9,2.9c-2.8,1.9-4.2,4.5-4.2,7.7c0,2.9,1.1,5.1,3.4,6.6c2.3,1.5,6.5,2.9,12.7,4.2
                c6.2,1.4,11.1,2.7,14.7,4c3.6,1.3,6.8,3.4,9.4,6.2c2.6,2.8,3.9,6.6,3.9,11.3c0,6-2.4,11-7.1,14.9
                C193.9,103.5,187.7,105.5,180.1,105.5z"/>
                <path className="st0" d="M247,99.1c-4.6,4.2-10.4,6.4-17.6,6.4c-5,0-9.1-1.4-12.4-4.1c-3.2-2.7-4.8-6.2-4.8-10.5
                c0-11.8,10.1-17.7,30.4-17.7h4.4c0-3.6-0.2-6.2-0.6-7.9c-0.4-1.6-1.4-3-3.2-4.1c-1.7-1.1-4.5-1.6-8.3-1.6c-8,0-12.1,2.8-12.4,8.5
                h-8.9c0.7-10.4,7.9-15.5,21.8-15.5c6.5,0,11.5,1.3,15.1,3.8c3.6,2.6,5.4,7.6,5.4,15.1v32.6H247V99.1z M247,91V79.9h-6.1
                c-13,0-19.5,3.5-19.5,10.6c0,2.6,1,4.5,2.9,5.9c1.9,1.4,4.5,2.1,7.7,2.1c2.9,0,5.8-0.7,8.6-2C243.2,95.1,245.4,93.3,247,91z"/>
                <path className="st0" d="M292.9,62.1c-1.3-0.4-3.1-0.6-5.5-0.6c-4.6,0-8.5,2.4-11.7,7.2v35.6h-9V53.9h9v7c3.8-5.4,8.1-8.2,13.1-8.2
                c1.3,0,2.6,0.1,4.1,0.4V62.1z"/>
            </g>
            <g>
                <g>
                    <path className="st0" d="M128.3,70.1c0,3.3-0.3,6.6-0.8,9.7c-4.5,28.3-28,50.2-57.1,52.1c-1.4,0.1-2.7,0.1-4.1,0.1
                    c-34.2,0-62-27.7-62-62s27.7-62,62-62S128.3,35.9,128.3,70.1z"/>
                </g>
                <path className="st1" d="M127.5,79.8c-4.5,28.3-28,50.2-57.1,52.1L40.1,99.7c0,0,13.9,4.9,22-3l16,12.3l12.1-0.2l8.2-6L98.9,90
                L83.7,76.6c0,0,9.8-11.9,6.6-27.8l12.8,6.8L127.5,79.8z"/>
                <g>
                    <path className="st2" d="M71.2,28.1L71.2,28.1c1.2,0.2,2.3,0.4,3.4,0.7c6.2,1.4,10.8,5,13.9,10.5c1.4,2.5,2.4,5.2,2.9,8.1
                    c0.1,0.6,0.4,0.9,1,1.1c3.5,1.1,6.7,2.7,9.2,5.4c2.6,2.8,3.9,6.2,4.6,9.9c0.3,1.7,0.4,3.4,0,5.1c-0.1,0.2-0.1,0.5-0.3,0.7
                    c-0.1,0.2-0.5,0.2-0.5-0.1c0-0.1,0-0.2,0-0.3c0.4-7.8-3.3-13.1-9.9-16.7c-0.9-0.5-1.9-0.9-2.9-1.3c-1.1-0.4-1.1-0.4-1.1,0.7
                    c0,3.7-0.4,7.3-1.1,10.9c-0.6,2.8-1.8,5.3-2.9,7.9c-0.5,1.1-0.9,2.3-1.6,3.3c-0.3,0.4-0.2,0.6,0.2,0.9c2.2,1.2,4.3,2.4,6.4,3.8
                    c2.7,1.8,5.2,4,7.2,6.6c1.4,1.9,2.1,4,2.4,6.3c0.4,3.7,0.3,7.3-1.4,10.7c-2,4.1-5.3,6.6-9.4,8.3c-7.6,3.1-14.8,1.9-21.5-2.4
                    c-3.4-2.2-6.2-4.9-8.6-8.1c-0.3-0.4-0.6-0.5-1.1-0.3c-3.6,1.8-7.4,3.1-11.5,3.3c-5.8,0.3-9.7-3.6-11.3-7.8
                    c-1.2-3.2-0.8-6.4,0.3-9.6c1.1-3.5,2.8-6.7,5.1-9.7c0.3-0.4,0.3-0.6,0-0.9c-3.2-3.5-6.2-7.1-9.5-10.5c-0.1-0.1-0.2-0.2-0.3-0.3
                    c-0.3-0.3-0.6-0.2-1-0.2c-0.4,0.1-0.2,0.5-0.2,0.7c0.1,0.9,0.2,1.8,0.1,2.7c-0.3,2.1-2,3.7-4,4c-1.5,0.2-3,0.4-4.5,0.5
                    c-2.6,0.3-4.2,2.1-4.3,4.8c0,0.2,0.2,0.6-0.2,0.7c-0.5,0.1-0.6-0.3-0.7-0.6c-0.1-0.4-0.2-0.6-0.3-1c-0.3-1.2,0.1-2.9,0.1-2.9
                    c0.4-1.6,1.3-2.8,2.6-3.8c0.2-0.2,0.5-0.3,0.8-0.3c1.3,0.1,2.6,0,3.9,0.1c2.2,0.2,3.9-0.7,3.8-3.4c-0.1-1.2,0-2.4,0-3.7
                    c0-0.7,0.2-1.5,1-1.8c0.7-0.3,1.4,0,1.9,0.6c0.1,0.1,0.3,0.3,0.4,0.4c4.3,3.7,8.6,7.2,12.5,11.3c0.4,0.4,0.5,0.3,0.8-0.1
                    c0.9-1.2,1.9-2.3,2.8-3.4c2.3-2.7,4.5-5.4,7-7.9c0.8-0.8,0.8-0.8,0-1.6c-2.4-2.7-4.4-5.6-5.2-9.2c-0.8-3.5-0.9-6.9,0.8-10.2
                    c2.8-5.6,7-9.8,13.2-11.5c0.8-0.2,1.5-0.4,2.3-0.5c0,0,0,0,0,0C68.1,27.9,69.6,27.9,71.2,28.1z M89,47.6c0.1,0,0.1,0,0.1,0.1
                    c0,0,0.1,0,0.1,0l0,0c-0.1,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c-2.5-4.5-5.4-8.7-9.5-12c-3.1-2.5-6.5-4.2-10.6-4.5
                    c-5.1-0.4-9.5,1.4-13.3,4.8c-5,4.6-5.8,12.4-1.9,17.7c1.2,1.7,2.7,3.1,4.2,4.4c0.3,0.3,0.5,0.3,0.8,0c1-0.9,2.1-1.8,3.2-2.6
                    c4.7-3.5,9.5-6.5,15.5-7C81.5,47.9,85.2,47.1,89,47.6z M65.8,93.7c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.3-0.2,0.4-0.3
                    c2.8-2.1,5.5-4.3,8.1-6.7c2.2-2.1,4.4-4.2,6.2-6.7c0.4-0.6,0.5-0.9-0.2-1.3c-1.1-0.7-2.2-1.4-3.3-2.2c-3.2-2.3-6.3-4.6-9.4-7
                    c-3.4-2.6-7-4.8-10.1-7.7c-0.4-0.4-0.6-0.1-0.9,0.2c-2.1,2.1-4.2,4.2-6.3,6.3c-1.5,1.6-3.1,3.1-4.4,4.8c-0.3,0.4-0.3,0.6,0.1,1
                    c6.5,6.5,12.9,12.9,19.4,19.4C65.6,93.6,65.7,93.6,65.8,93.7z M85.7,107.7c2.4,0,4.6-0.6,6.5-1.9c3-2,5-4.6,5.2-8.3
                    c0.2-2.8-1.1-5-2.8-7c-3.4-4-7.6-7.2-11.9-10.2c-0.4-0.3-0.6-0.2-0.8,0.2c-4.1,5.4-8.8,10.3-14,14.6c-0.4,0.3-0.4,0.5,0,0.9
                    c1.9,2,3.8,4,5.6,6C76.7,105.6,80.8,107.5,85.7,107.7z M90.1,53.6c0-0.8,0-1.6,0-2.3c0-0.6-0.2-0.8-0.8-0.9
                    c-5.6-0.8-11-0.2-16.3,1.6c-4.6,1.5-8.8,3.8-12.6,6.8c-0.5,0.4-0.6,0.6,0,1c0.7,0.5,1.3,1,2,1.5c2.9,1.9,6,3.5,9.1,5.3
                    c4.2,2.5,8.5,4.9,12.7,7.3c0.5,0.3,0.7,0.2,0.9-0.2c0.4-0.6,0.8-1.2,1.1-1.9C88.9,65.9,90.1,59.9,90.1,53.6z M49.6,99.8
                    c2.9,0,6.6-0.8,9.3-2.1c0.5-0.2,0.4-0.4,0.2-0.7c-2.7-3.8-5.2-7.8-7.9-11.5c-2.2-2.9-4.6-5.7-7-8.5c-0.2-0.3-0.4-0.6-0.8-0.1
                    c-2.4,3-4,6.3-4.9,10c-0.9,3.8-0.3,7.2,2.8,9.9C43.7,99,46.5,99.8,49.6,99.8z"/>
                </g>
            </g>
            <g>
                <path className="st3" d="M180.1,105.5c-9.8,0-17.2-2.2-22-6.6c-4.9-4.4-7.4-10.1-7.7-17h8.9c0.5,5.2,2.6,9,6.5,11.5
                c3.8,2.5,8.5,3.7,13.9,3.7c5.2,0,9.3-1.1,12.5-3.2c3.2-2.1,4.7-5,4.7-8.6c0-3.8-1.6-6.6-4.9-8.4c-3.3-1.7-8.9-3.5-16.8-5.2
                c-8-1.7-13.7-4-17.1-7.1s-5.2-7-5.2-12c0-5.6,2.3-10.3,6.8-13.9s10.5-5.4,18-5.4c7.9,0,14.2,1.8,19,5.5c4.8,3.6,7.4,8.8,7.6,15.4
                h-9c-0.9-8.4-6.7-12.6-17.3-12.6c-5.1,0-9.1,1-11.9,2.9c-2.8,1.9-4.2,4.5-4.2,7.7c0,2.9,1.1,5.1,3.4,6.6c2.3,1.5,6.5,2.9,12.7,4.2
                c6.2,1.4,11.1,2.7,14.7,4c3.6,1.3,6.8,3.4,9.4,6.2c2.6,2.8,3.9,6.6,3.9,11.3c0,6-2.4,11-7.1,14.9
                C193.9,103.5,187.7,105.5,180.1,105.5z"/>
                <path className="st3" d="M247,99.1c-4.6,4.2-10.4,6.4-17.6,6.4c-5,0-9.1-1.4-12.4-4.1c-3.2-2.7-4.8-6.2-4.8-10.5
                c0-11.8,10.1-17.7,30.4-17.7h4.4c0-3.6-0.2-6.2-0.6-7.9c-0.4-1.6-1.4-3-3.2-4.1c-1.7-1.1-4.5-1.6-8.3-1.6c-8,0-12.1,2.8-12.4,8.5
                h-8.9c0.7-10.4,7.9-15.5,21.8-15.5c6.5,0,11.5,1.3,15.1,3.8c3.6,2.6,5.4,7.6,5.4,15.1v32.6H247V99.1z M247,91V79.9h-6.1
                c-13,0-19.5,3.5-19.5,10.6c0,2.6,1,4.5,2.9,5.9c1.9,1.4,4.5,2.1,7.7,2.1c2.9,0,5.8-0.7,8.6-2C243.2,95.1,245.4,93.3,247,91z"/>
                <path className="st3" d="M292.9,62.1c-1.3-0.4-3.1-0.6-5.5-0.6c-4.6,0-8.5,2.4-11.7,7.2v35.6h-9V53.9h9v7c3.8-5.4,8.1-8.2,13.1-8.2
                c1.3,0,2.6,0.1,4.1,0.4V62.1z"/>
            </g>
            <g>
                <path className="st3" d="M344.1,53.9l-19,50.4h-8l-19-50.4h9.7l13.3,39.4l13.3-39.4H344.1z" />
                <path className="st3" d="M361.3,104.3h-9.7v-9.7h9.7V104.3z" />
                <path className="st3" d="M394.6,105.5c-7.2,0-12.9-2.3-17.2-6.9c-4.3-4.6-6.4-11.1-6.4-19.5c0-8.4,2.1-14.9,6.4-19.5
                c4.3-4.6,10-6.9,17.2-6.9c5.5,0,10.1,1.4,13.8,4.2c3.7,2.8,5.9,6.8,6.5,12.1h-9.2c-0.1-3-1.3-5.3-3.4-6.9c-2.1-1.6-4.7-2.4-7.7-2.4
                c-4.3,0-7.7,1.6-10.4,4.9c-2.7,3.2-4,8.1-4,14.5c0,6.4,1.3,11.2,4,14.5c2.7,3.2,6.1,4.9,10.4,4.9c3,0,5.6-0.8,7.7-2.4
                c2.1-1.6,3.3-3.9,3.4-6.8h9.2c-0.7,5.3-2.8,9.3-6.5,12.1C404.7,104.1,400.1,105.5,394.6,105.5z"/>
                <path className="st3" d="M440.8,52.7c7.2,0,12.9,2.3,17.2,6.9c4.3,4.6,6.4,11.1,6.4,19.5c0,8.4-2.1,14.8-6.4,19.5
                c-4.3,4.6-10,6.9-17.2,6.9c-7.2,0-12.9-2.3-17.2-6.9c-4.3-4.6-6.4-11.1-6.4-19.5c0-8.4,2.1-14.9,6.4-19.5
                C427.9,55,433.6,52.7,440.8,52.7z M440.8,98.4c4.3,0,7.7-1.6,10.4-4.9c2.7-3.2,4-8.1,4-14.5c0-6.4-1.3-11.3-4-14.5
                c-2.7-3.2-6.1-4.9-10.4-4.9c-4.3,0-7.7,1.6-10.4,4.9c-2.7,3.2-4,8.1-4,14.5c0,6.4,1.3,11.2,4,14.5C433,96.8,436.5,98.4,440.8,98.4z
                "/>
                <path className="st3" d="M540.1,104.3h-9V71.7c0-4.2-0.6-7.2-1.8-8.9c-1.2-1.8-3.2-2.7-6-2.7c-4.3,0-8.6,2.7-12.8,8.1v36.1h-8.9V71.7
                c0-4.2-0.6-7.2-1.8-8.9c-1.2-1.8-3.2-2.7-6.1-2.7c-4.3,0-8.6,2.7-12.7,8.1v36.1h-9V53.9h9v6.4c4.2-5,8.9-7.6,14.1-7.6
                c7.4,0,12.1,3,14.1,9.1c3.4-4,6.2-6.5,8.6-7.5c2.3-1.1,4.6-1.6,6.9-1.6c10.3,0,15.5,6.2,15.5,18.6V104.3z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg> */