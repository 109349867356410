//react
import React, { useState, useRef } from 'react';

//@mui
import { Box, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

//@mui icons
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

//components
import KycFileInput from '../../components/Dashboard/KycInput/KycFileInput';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

//kycCommon validator function
import { validateUploadFile } from '../../utils/kycCommon';

import ErrorMessage from "../../utils/ErrorMessage.json"
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import PropTypes from 'prop-types';
import ShowNote from './showNote';
import Logger from '../../lib/Logger';
import { axiosPostCall } from '../../services/apisCall';
const logger = new Logger("address");


const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const FormGroupBox = styled(FormGroup)(({ theme }) => ({
    flexDirection: 'row',
    gap: '60px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '10px',
    },
}));

const Address = ({ current, next, formData }) => {

    const electricityBillref = useRef();
    const rentAgreementref = useRef()
    const nocRef = useRef()
    const [checked, setChecked] = useState([true, false, false])
    const [electricityBillFile, setElectricityBillFile] = useState()
    const [rentAgreementFile, setRentAgreementFile] = useState()
    const [nocFile, setNocFile] = useState();
    const [loader, setLoader] = useState(false);

    const InActiveNextBtn = {
        backgroundColor: '#727374',
        color: "#B8B9BA",
        '&:hover': {
            backgroundColor: '#727374',
            color: "#B8B9BA",
        }
    }

    const handleSubmit = async () => {
        try {
            const files = [electricityBillFile, rentAgreementFile, nocFile]

            const form = new FormData();

            let fileError = false
            files.filter((file) => file).forEach(file => {
                if (!validateUploadFile(file)) {
                    fileError = true
                }
            });
            if (fileError) return renderErrorMessage(ErrorMessage?.[4013])

            if (checked[0]) {
                form.append("addressProofType", "self")
            }
            else if (checked[1]) {
                form.append("addressProofType", "rent")
            }
            else {
                form.append("addressProofType", "noc")
            }
            form.append("electricityBill", electricityBillFile)
            checked[1] && form.append("rentAgreement", rentAgreementFile)
            checked[2] && form.append("noc", nocFile)
            form.append("currentStep", "address");
            form.append("nextStep", "finish");
            setLoader(true);
            const { message } = await axiosPostCall("uploadKyc", form);
            setLoader(false);
            renderSuccessMessage(message)
            next()
        } catch (error) {
            setLoader(false);
            logger.error(`API Error -  ${JSON.stringify(error)}`);
        }
    }


    const handleSelect = (key) => {
        switch (key) {
            case 0:
                setRentAgreementFile()
                setNocFile()
                setChecked([true, false, false])
                break;

            case 1:
                setNocFile()
                setChecked([false, true, false])
                break;

            case 2:
                setRentAgreementFile()
                setChecked([false, false, true])
                break;

            default:
                break
        }
    }

    return (
        <MainSection>
            <Typography sx={{
                color: 'grey.600',
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '40px'
            }}>
                Address Verification
            </Typography>

            <Box sx={{
                display: 'flex',
                gap: '6px',
                flexDirection: 'column',
                width: '100%'
            }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: "grey.600" }}>Choose address type</Typography>

                <FormGroupBox>
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={checked[0]}
                                onChange={() => handleSelect(0)}
                            />}
                        label="Self"
                        sx={{
                            color: "grey.600",
                            fontSize: '14px',
                            fontWeight: 500,
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={checked[1]}
                                onChange={() => handleSelect(1)}
                            />}
                        label="Rented"
                        sx={{
                            color: "grey.600",
                            fontSize: '14px',
                            fontWeight: 500,
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={checked[2]}
                                onChange={() => handleSelect(2)}
                            />}
                        label="NOC"
                        sx={{
                            color: "grey.600",
                            fontSize: '14px',
                            fontWeight: 500,
                        }}
                    />
                </FormGroupBox>

            </Box>

            <KycFileInput InputName="Electricity bill" inputId="electrucityBill" inputRef={electricityBillref} setFile={setElectricityBillFile} />

            {checked[1] && <KycFileInput InputName="Rent agreement" inputId="Rentagreement" inputRef={rentAgreementref} setFile={setRentAgreementFile} />}

            {checked[2] && <KycFileInput InputName="NOC" inputId="NOC" inputRef={nocRef} setFile={setNocFile} />}

            <ShowNote />

            <LoadingButton
                variant='contained'
                // endIcon={<ArrowForwardIcon />}
                sx={{
                    "&.Mui-disabled": InActiveNextBtn,
                    boxShadow: 'none'
                }}
                loading={loader}
                loadingPosition="center"
                onClick={handleSubmit}
                disabled={!electricityBillFile || (checked[1] && !rentAgreementFile) || (checked[2] && !nocFile)}
            >
                Save
            </LoadingButton>
        </MainSection>
    )
}

Address.propTypes = {
    current: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    // loadingBtn: PropTypes.bool.isRequired
}

export default Address;
