import React from 'react';
import { Grid, Box, Typography, SvgIcon, Button } from '@mui/material';
import SmsIcon from '../../assets/svgs/productIcons/sms';
// import StarLineIcon from '../../assets/svgs/icons/star-line';
// import MailIcon from '../../assets/svgs/productIcons/mail';
// import Upload2LineIcon from '../../assets/svgs/icons/upload-2-line';
// import ListCheckLineIcon from '../../assets/svgs/icons/list-check-line';
// import FunctionLineIcon from '../../assets/svgs/icons/function-line';
import { Stack } from '@mui/system';
// import { configFile } from '../../config';
import CalendarEventFillIcon from '../../assets/svgs/icons/fill/calendar-event-fill';

// const { imagesUrl } = configFile;

/* const CardsMainSection = styled(Grid)(({ theme }) => ({
    // backgroundColor: theme.palette.background.default,
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    // flexDirection: 'row',
    // gap: '14px',
    // padding: '62px 12px 12px 72px',
    // height: window.innerHeight,
    // overflowY: 'scroll',
    // [theme.breakpoints.down('sm')]: {
    //     padding: '70px 14px 14px 14px',
    //     height: 'auto',
    // },
    height: '377px',
    width: '100%'
})); 

const ListItem = styled(Item)(({ theme }) => ({
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[300],
    height: '60px'
}))*/

const SmsCampSection = () => {
    return (
        <Grid container spacing={2} sx={{
            minHeight: '282px',
            height: '377px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }}>
            <Grid item xs={12} sx={{
                borderRadius: '8px'
            }}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden',
                    backgroundColor: 'card.main',
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <SvgIcon
                                sx={{ height: '30px', width: '30px', color: 'primary.dark' }}>
                                <SmsIcon color="currentColor" />
                            </SvgIcon>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    SMS Campaigns
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Track and manage your next schedule
                                </Typography>
                            </Box>

                        </Box>

                    </Box>

                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: 400,
                                color: 'grey.500',
                                maxWidth: '460px',
                                textAlign: 'center'
                            }}>
                            No SMS campaigns launched yet. Engage your audience by creating a new SMS campaign to reach your users effectively.
                        </Typography>
                        <Button
                            sx={{
                                fontSize: '12px',
                                color: 'primary.light',
                                fontWeight: 600,
                                backgroundColor: 'primary.200',
                                mt: '8px',
                                padding: '8px !important'
                            }}
                            startIcon={
                                <SvgIcon sx={{
                                    width: '16px',
                                    height: '16px',
                                    ml: '2px'
                                }}>
                                    <CalendarEventFillIcon color={"currentColor"} />
                                </SvgIcon>
                            }
                        >

                            Create SMS campaign
                        </Button>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    )
}

export default SmsCampSection