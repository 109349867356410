
// eslint-disable-next-line
const StarLineIcon = ({ color }) => (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00037 10.6517L2.88586 12.9549L3.8048 8.33002L0.342957 5.12863L5.0254 4.57345L7.00037 0.291748L8.9753 4.57345L13.6577 5.12863L10.1959 8.33002L11.1149 12.9549L7.00037 10.6517ZM7.00037 9.31475L9.47761 10.7014L8.92432 7.91691L11.0086 5.9894L8.18944 5.65511L7.00037 3.07718L5.81127 5.65511L2.99206 5.9894L5.07637 7.91691L4.52309 10.7014L7.00037 9.31475Z" fill={color} />
    </svg>


)

export default StarLineIcon