/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Chart, { useChart } from '../../components/chart';
// import DateRangePicker from '../../components/date-range';

// ----------------------------------------------------------------------


/* const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: '18px',
  fontWeight: 400,
})) */

const CampaignHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  color: theme.palette.grey[100]
}));

export default function TotalCampaignsLineChart({ title, range, setRange, subheader, chart, ...other }) {
  const { labels, colors, series, options } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: '16%',
      },
    },
    fill: {
      type: series.map((i) => i.fill),
    },
    labels,
    xaxis: {
      type: 'text',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => {
          if (typeof value !== 'undefined') {
            return `${value.toFixed(0)} `;
          }
          return value;
        },
      },
    },
    ...options,
  });

  return (
    // <Card {...other}>
    <>

      {/* <CampaignHeading>{title}</CampaignHeading> */}

      <Box sx={{ p: 3, pb: 1 }}>
        <Chart
          dir="ltr"
          type="line"
          series={series}
          options={chartOptions}
          width="100%"
          height={230}
        />
      </Box>
    </>
    // </Card>
  );
}

TotalCampaignsLineChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
