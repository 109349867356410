const WhatsappApiIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_569_13106)">
                <path d="M12.0388 1.4C17.8838 1.4 22.6388 6.155 22.6388 12C22.6388 17.845 17.8838 22.6 12.0388 22.6C10.3158 22.6 8.67382 22.2 7.15682 21.412L6.71982 21.185L6.23682 21.281L1.89382 22.146L2.84982 18.037L2.97082 17.519L2.71682 17.052C1.88082 15.512 1.43782 13.765 1.43782 12C1.43882 6.155 6.19382 1.4 12.0388 1.4ZM12.0388 0C5.41182 0 0.0388184 5.373 0.0388184 12C0.0388184 14.071 0.563818 16.02 1.48682 17.72L0.0388184 23.944L6.51082 22.654C8.16482 23.514 10.0448 24 12.0388 24C18.6658 24 24.0388 18.627 24.0388 12C24.0388 5.373 18.6658 0 12.0388 0Z" fill="#626F86" />
                <path d="M7.93582 9.254L6.03882 14.746H7.28382L7.69082 13.494H9.67382L10.0808 14.746H11.3258L9.43182 9.254H7.93482H7.93582ZM7.98582 12.588L8.66282 10.508H8.70482L9.38082 12.588H7.98682H7.98582Z" fill="#626F86" />
                <path d="M18.0388 9.254H16.8778V14.746H18.0388V9.254Z" fill="#626F86" />
                <path d="M15.2168 9.492C14.9248 9.334 14.5688 9.254 14.1528 9.254H11.9868V14.746H13.1478V12.966H14.1238C14.5458 12.966 14.9058 12.888 15.2038 12.732C15.5018 12.577 15.7278 12.36 15.8868 12.08C16.0438 11.802 16.1228 11.48 16.1228 11.115C16.1228 10.75 16.0448 10.428 15.8908 10.147C15.7358 9.867 15.5118 9.649 15.2178 9.491L15.2168 9.492ZM14.8168 11.594C14.7438 11.732 14.6348 11.842 14.4898 11.918C14.3448 11.996 14.1598 12.035 13.9358 12.035H13.1468V10.203H13.9298C14.1548 10.203 14.3408 10.241 14.4878 10.316C14.6348 10.393 14.7438 10.499 14.8168 10.635C14.8898 10.772 14.9248 10.932 14.9248 11.115C14.9248 11.298 14.8898 11.455 14.8168 11.593V11.594Z" fill="#626F86" />
            </g>
            <defs>
                <clipPath id="clip0_569_13106">
                    <rect width="24" height="24" fill="white" transform="translate(0.0388184)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default WhatsappApiIcon;