
// eslint-disable-next-line
const AppLauncherIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3.5" y="3.5" width="3" height="3" fill="#44546F" />
            <rect x="10.5" y="3.5" width="3" height="3" fill="#44546F" />
            <rect x="17.5" y="3.5" width="3" height="3" fill="#44546F" />
            <rect x="3.5" y="10.5" width="3" height="3" fill="#44546F" />
            <rect x="10.5" y="10.5" width="3" height="3" fill="#44546F" />
            <rect x="17.5" y="10.5" width="3" height="3" fill="#44546F" />
            <rect x="3.5" y="17.5" width="3" height="3" fill="#44546F" />
            <rect x="10.5" y="17.5" width="3" height="3" fill="#44546F" />
            <rect x="17.5" y="17.5" width="3" height="3" fill="#44546F" />
        </svg>

    )
}

export default AppLauncherIcon