import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Link,
    styled
} from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from "react-router-dom";

import ErrorMessage from '../../../utils/ErrorMessage.json';
import { axiosPostCall } from "../../../services/apisCall";
import { validatePassword } from "../../../utils/common";
import Logger from "../../../lib/Logger";
import AuthFooter from "../../../layouts/AuthFooter";
import { renderErrorMessage } from "../../../lib/toastMessage";
import { ToastContainer } from "react-toastify";
import LogoBlue from "../../../assets/svgs/logo";

const HeadingSection = styled('div')(({ theme }) => ({
    width: '450px',
    display: 'flex',
    background: '#FFFFFF',
    padding: '30px 40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
        borderRadius: '0px',
        width: 'auto',
        padding: '20px 30px',
    }
}));

const ResetPasswordForm = ({ setOpenResetPassword, userId }) => {
    const navigate = useNavigate();

    const logger = new Logger('Reset-Password');

    const [otp, setOtp] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [pwdLength, setPwdLength] = useState(true);
    const [pwdNumber, setPwdNumber] = useState(true);
    const [pwdUppercase, setPwdUppercase] = useState(true);
    const [pwdLowercase, setPwdLowercase] = useState(true);
    const [pwdSymbol, setPwdSymbol] = useState(true);
    const [timer, setTimer] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [resetLoading, setResetLoading] = useState(false);
    const [showPasswordSection, setShowPasswordSection] = useState(false)
    const inputRef = useRef();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleResetPassword = async (e) => {
        try {
            e.preventDefault();
            if (!otp || otp.length !== 6 || otp.includes(" ")) return renderErrorMessage(ErrorMessage?.[4008]);
            if (!newPassword) return setNewPasswordError("blank");

            (newPassword.length >= 8 && newPassword.length <= 20) ? setPwdLength(true) : setPwdLength(false);
            (/[0-9]/.test(newPassword)) ? setPwdNumber(true) : setPwdNumber(false);
            (/[A-Z]/.test(newPassword)) ? setPwdUppercase(true) : setPwdUppercase(false);
            (/[a-z]/.test(newPassword)) ? setPwdLowercase(true) : setPwdLowercase(false);
            // eslint-disable-next-line
            (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/).test(newPassword) ? setPwdSymbol(true) : setPwdSymbol(false);

            if (!validatePassword(newPassword)) return renderErrorMessage(ErrorMessage?.[4005]);
            if (newPassword !== confirmPassword) return setConfirmPasswordError("invalid");

            setResetLoading(true);

            const payload = { otp, newPassword, confirmPassword, userId };
            const { message } = await axiosPostCall("resetPassword", payload);

            return navigate('/login', { state: { message, time: +new Date() + 10 * 1000 } });
        } catch (error) {
            logger.error(error.message)
            renderErrorMessage(error.message)
            setResetLoading(false)
        }
    }

    const handleResendOtp = async (e) => {
        try {
            e.preventDefault();

            await axiosPostCall("forgotPassword", { userId });

            setTimer(60);
            setIsResendDisabled(true);

        } catch (error) {
            logger.error(error.message)
            renderErrorMessage(error.message)
        }
    }

    const handleOtpChange = (newValue) => setOtp(newValue)
    const handleChange = (e) => e.preventDefault();

    // const changeUserId = () => setOpenResetPassword(false)

    useEffect(() => {
        let interval;
        if (timer > 0 && isResendDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            setIsResendDisabled(false);
        }

        return () => clearInterval(interval);
    }, [timer, isResendDisabled]);

    const handleFormSubmit = (e) => e.preventDefault()

    const onKeyDown = (e) => {
        console.log(e.key)
        if (e.key === "Tab") {
            e.preventDefault();
            inputRef.current.focus();
        }
    };

    return (
        <HeadingSection>
            <ToastContainer />
            <Box className='FormContent' sx={{
                textAlign: { xs: "center", sm: "start", md: "start", lg: 'start' },
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
                width: '100%'
            }}
                onSubmit={handleFormSubmit}
            >

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Box sx={{
                        width: '210px',
                        height: '60px',
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <LogoBlue isAuth={true} height={60} />
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    gap: '24px'
                }}>
                    <Box className='FormHeading' sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography variant="custom24600" sx={{ color: '#282828' }}>
                            Reset your password?
                        </Typography>
                    </Box>

                    <Box className="FormGroup" sx={{
                        display: 'flex',
                        gap: '16px',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                        {
                            <>

                                <Box sx={{
                                    display: 'flex',
                                    gap: '6px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}>

                                    <Typography variant="body2" sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        color: '#282828',
                                    }}>Enter OTP</Typography>

                                    <MuiOtpInput
                                        value={otp}
                                        onChange={(e) => handleOtpChange(e)}
                                        length={6}
                                        onComplete={() => setShowPasswordSection(true)}
                                        validateChar={(val) => !isNaN(val)}
                                        TextFieldsProps={{ size: 'small', placeholder: '-' }}
                                        sx={{
                                            width: '100%',
                                            '& .MuiInputBase-input': {
                                                color: 'grey.0',
                                            }
                                        }}
                                    />

                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    gap: '6px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}>

                                    <Typography variant="body2" sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        color: '#282828',
                                    }}><Link underline="none" onClick={handleResendOtp} style={{ pointerEvents: isResendDisabled ? 'none' : '' }} color={isResendDisabled ? 'textSecondary' : 'primary'} href="#">Resend OTP</Link> {isResendDisabled ? `(${timer}s)` : ''}</Typography>
                                </Box>

                                {showPasswordSection && <>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '10px',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}>
                                        <Typography variant="body2" sx={{
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            color: '#282828',
                                        }}>New Password</Typography>

                                        <form style={{ width: '100%' }}>
                                            <TextField
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                error={Boolean(newPasswordError || !pwdLength || !pwdNumber || !pwdUppercase || !pwdLowercase || !pwdSymbol)}
                                                onClick={() => {
                                                    setNewPasswordError("")
                                                    setPwdLength(true)
                                                    setPwdNumber(true)
                                                    setPwdUppercase(true)
                                                    setPwdLowercase(true)
                                                    setPwdSymbol(true)
                                                }}
                                                placeholder={"New Password"}
                                                fullWidth
                                                helperText={newPasswordError === "blank" ? ErrorMessage?.[4002] :
                                                    !pwdLength ? "pasword length should be 8 to 20." :
                                                        !pwdNumber ? "At least 1 Number required." :
                                                            !pwdUppercase ? "At least 1 Uppercase character required" :
                                                                !pwdLowercase ? "At least 1 Lowercase character required" :
                                                                    !pwdSymbol ? "At least 1 special character required" : ""
                                                }
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                inputProps={{
                                                    sx: {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        color: '#282828'
                                                    },
                                                }}
                                                autoComplete="on"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end" >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={(e) => handleClickShowPassword(e)}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onKeyDown={onKeyDown}
                                            />
                                        </form>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        gap: '6px',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}>
                                        <Typography variant="custom13500" sx={{
                                            fontSize: '13px',
                                            fontWeight: '500',
                                            color: '#282828',
                                        }}>Confirm Password</Typography>
                                        <form style={{ width: '100%' }}>
                                            <TextField
                                                id="outlined-adornment-confirm-password"
                                                type={"password"}
                                                error={Boolean(confirmPasswordError)}
                                                onClick={() => confirmPasswordError ? setConfirmPasswordError("") : ""}
                                                onCut={(e) => handleChange(e)}
                                                onCopy={(e) => handleChange(e)}
                                                onPaste={(e) => handleChange(e)}
                                                placeholder={"Confirm Password"}
                                                fullWidth
                                                helperText={confirmPasswordError === "invalid" ? ErrorMessage?.[4010] : ""}
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                inputProps={{
                                                    sx: {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        color: '#282828'
                                                    },
                                                }}
                                                autoComplete="on"
                                                variant="outlined"
                                                size="small"
                                                inputRef={inputRef}

                                            />
                                        </form>
                                    </Box>

                                    <AuthFooter
                                        btnText={"Reset Password"}
                                        navigationMessage={"Back to"}
                                        navigateText={"Login"}
                                        btnCheckHandler={handleResetPassword}
                                        navigationCheckHandler={() => navigate('/login')}
                                        loadingBtn={resetLoading}
                                    />
                                </>}
                            </>
                        }
                    </Box>
                </Box>


            </Box>
        </HeadingSection>
    )
}

export default ResetPasswordForm;
