import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { checkProductAccess, handleRedirect } from "../utils/common";
import { getBulkItems } from "./cookies";
import { useTheme } from "@emotion/react";


export const ProtectedRoute = ({ children }) => {
    const valid = useAuth();

    const { palette: { mode } } = useTheme();
    const products = checkProductAccess() || []

    if (!valid) {
        window.location.href = "/login";
        return;
    }
    // const path = whiteListPlatFormChecker('')
    let path = "";

    if (products?.length && !products?.includes('Console')) path = products?.[0]?.toLowerCase()

    if (path) {
        const [{ sessionToken }, { token }, { exp }] = getBulkItems(["sessionToken", "token", "exp"]);
        handleRedirect(path, "", { sessionToken, exp, token }, true, mode)
        return ""
    }
    return children;
};

export const PublicRoute = ({ children }) => {
    const valid = useAuth();
    if (valid) {
        return <Navigate to="/" />;
    }
    return children;
};