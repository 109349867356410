//react
import React, { useState, useRef } from 'react';

//@mui
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

//component
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KycInput from '../../components/Dashboard/KycInput/KycInput';
import KycFileInput from '../../components/Dashboard/KycInput/KycFileInput';

//kycCommon validator function
import { validateAadharNumber, validatePanNumber, validateUploadFile } from '../../utils/kycCommon';

import ErrorMessage from "../../utils/ErrorMessage.json"
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import PropTypes from 'prop-types';
import ShowNote from './showNote';
import VerifyOtpModal from './verifyOtpModal';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { axiosPostCall } from '../../services/apisCall';
import Logger from '../../lib/Logger';

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const FormRowSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const AuthorizedPersonProfile = ({ current, next, formData }) => {

    const logger = new Logger("AuthorizedPersonProfile")
    const signatureRef = useRef();
    const authPanRef = useRef()
    const aadharRef = useRef();

    const [AuthorizedPersonName, setAuthorizedPersonName] = useState("")
    const [signatureFile, setSignatureFile] = useState()
    const [authPanNumber, setAuthPanNumber] = useState("")
    const [authPanFile, setAuthPanFile] = useState()
    const [aadharNumber, setAadharNumber] = useState("")
    const [aadharFile, setAadharFile] = useState()
    const [verifyOtp, setVerifyOtp] = useState(false);
    const [loader, setLoader] = useState(false);
    const [otp, setOtp] = useState("");
    const [clientId, setClientId] = useState("");

    const [nameError, setNameError] = useState(false)
    const [panError, setPanError] = useState(false)
    const [aadharError, setAadharError] = useState(false)

    // const InActiveNextBtn = {
    //     backgroundColor: '#727374',
    //     color: "#B8B9BA",
    //     '&:hover': {
    //         backgroundColor: '#727374',
    //         color: "#B8B9BA",
    //     }
    // }

    const handleSubmit = async (e) => {
        try {
            setLoader(true);
            await handleSendOtp();
            setLoader(false);
        } catch (error) {
            setLoader(false);
            logger.error(`API Error -  ${JSON.stringify(error)}`);
        }

    }

    const handleSendOtp = async (resend = false, handleTimer, handleResend) => {
        try {
            if (!aadharNumber) {
                return renderErrorMessage("Please enter the aadhaar Number");
            }

            setLoader(true)
            const { clientId } = await axiosPostCall("generateOtp", { aadhaarNumber: aadharNumber });
            setClientId(clientId);
            setLoader(false);

            if (resend) {
                handleTimer(60);
                handleResend(true);
                return;
            }
            renderSuccessMessage("OTP has been sent to the mobile number registered with your Aadhaar.")
            setVerifyOtp(true);
        } catch (error) {
            logger.error(error.message)
            setLoader(false);
            renderErrorMessage(error.message)
        }
    }

    const handleVerifyOtp = async (handleLoader) => {
        try {
            if (!clientId) {
                return renderErrorMessage(ErrorMessage?.[4001]);
            }

            const files = [signatureFile, authPanFile, aadharFile]
            const form = new FormData();

            let fileError = false
            files.filter((file) => file !== undefined).forEach(file => {
                if (!validateUploadFile(file)) {
                    fileError = true
                }
            });


            if (fileError) return renderErrorMessage(ErrorMessage?.[4013])

            if (!validatePanNumber(authPanNumber)) {
                setPanError(true)
                return renderErrorMessage(ErrorMessage?.[4014])
            }
            if (!validateAadharNumber(aadharNumber)) {
                setAadharError(true)
                return renderErrorMessage(ErrorMessage?.[4015])
            }

            form.set("authorizedPersonName", AuthorizedPersonName)
            form.set("signature", signatureFile)
            form.set("authPanNumber", authPanNumber)
            form.set("authPancard", authPanFile)
            form.set("aadharNumber", aadharNumber)
            form.set("aadhar", aadharFile)
            form.append("currentStep", "authorizedPersonProfile");
            form.append("nextStep", "certification");

            const payload = {
                clientId,
                otp,
            }

            handleLoader(true);
            const { message } = await axiosPostCall("verifyOtp", payload);
            await axiosPostCall("uploadKyc", form);
            handleLoader(false);
            setVerifyOtp(false);
            current(false);
            next(true);
            return renderSuccessMessage(message);
        } catch (error) {
            logger.error(error.message)
            handleLoader(false);
        }
    }

    return (
        <>
            {verifyOtp && <VerifyOtpModal
                open={verifyOtp}
                handleClose={setVerifyOtp}
                otp={otp}
                handleOtpChange={setOtp}
                handleSendOtp={handleSendOtp}
                handleVerifyOtp={handleVerifyOtp}
            />}
            <MainSection>
                <Typography sx={{
                    color: 'grey.600',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '40px'
                }}>
                    Authorised person documents
                </Typography>

                <FormRowSection>

                    <KycInput inputName="Name of authorised" inputId="AuthorizedName" inputPlaceholder="Enter Name" value={AuthorizedPersonName} setValue={setAuthorizedPersonName} error={nameError} setError={setNameError} />

                    <KycFileInput InputName="Upload signature" inputId="authorizedPersonSign" inputRef={signatureRef} setFile={setSignatureFile} />

                </FormRowSection>

                <FormRowSection>

                    <KycInput inputName="PAN number" inputId="companyGstNumber" inputPlaceholder="Enter pan number" value={authPanNumber} setValue={setAuthPanNumber} error={panError} setError={setPanError} />

                    <KycFileInput InputName="Upload PAN card" inputId="authorizedPersonPancard" inputRef={authPanRef} setFile={setAuthPanFile} />

                </FormRowSection>

                <FormRowSection>

                    <KycInput type='number' inputName="Aadhar number" inputId="aadharCardNumber" inputPlaceholder="Enter Aadhaar number" value={aadharNumber} setValue={setAadharNumber} error={aadharError} setError={setAadharError} />

                    <KycFileInput InputName="Upload aadhaar card" inputId="authorizedPersonAadharCard" inputRef={aadharRef} setFile={setAadharFile} />

                </FormRowSection>

                <ShowNote />

                <LoadingButton
                    variant='contained'
                    loading={loader}
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                        // "&.Mui-disabled": InActiveNextBtn,
                        boxShadow: 'none'
                    }}
                    onClick={handleSubmit}
                    disabled={aadharFile === undefined || authPanFile === undefined || signatureFile === undefined || AuthorizedPersonName === "" || aadharNumber === "" || authPanNumber === ""}
                >
                    Save & Next
                </LoadingButton>
            </MainSection>
        </>

    )
}

AuthorizedPersonProfile.propTypes = {
    current: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
}

export default AuthorizedPersonProfile;
