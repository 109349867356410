import { razorpayConfig } from "../config/commonConfig";
import { getItem } from "./cookies";
import jwtDecode from "jwt-decode";

const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const displayRazorpay = async (data, verifyPayment) => {

    try {
        const res = await loadScript(razorpayConfig?.checkoutUrl);

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const { order } = data;

        // creating a new order
        const token = getItem("token");
        const { email, name, mobile } = jwtDecode(token);

        // eslint-disable-next-line 
        const { id, amount, currency, notes = {} } = order;

        const options = {
            key: razorpayConfig?.key,
            // amount: amount.toString(),
            // currency: currency,
            name,
            description: "Console Wallet",
            // image: { logo },
            order_id: id,
            handler: async function (response) {

                const data = {
                    orderCreationId: id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };

                await verifyPayment(data);

            },
            prefill: { name, email, contact: mobile, },
            // notes,
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    } catch (error) {
        console.error(error);
    }

}

export { displayRazorpay }