import React from 'react';
import { Box, Typography, Button, Paper, Divider, Container } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessPage = () => {
  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 8 }}>
      {/* Top Bar */}
      <Box sx={{ bgcolor: 'primary.main', color: 'white', p: 2, mb: 3 }}>
        <Typography variant="h6">Activate Gmail</Typography>
      </Box>

      {/* Main Content */}
      <Paper elevation={3} sx={{ p: 4, mb: 2 }}>
        {/* Success Message */}
        <CheckCircleIcon color="success" sx={{ fontSize: 40, mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          Well done! MX records are updated
        </Typography>
        <Typography color="textSecondary">
          You can now start using Google Workspace. We recommend moving your contacts, calendars, and files to your new business account!
        </Typography>

        {/* Illustration */}
        <Box sx={{ my: 4 }}>
          <img 
            src="https://www.gstatic.com/images/branding/product/2x/gmail_64dp.png" 
            alt="Gmail Illustration" 
            style={{ width: '80px', marginBottom: '10px' }} 
          />
        </Box>

        {/* Divider */}
        <Divider sx={{ my: 2 }} />

        {/* Next Steps */}
        <Typography variant="h6" gutterBottom>
          What to expect next
        </Typography>
        <Typography color="textSecondary" sx={{ mb: 2 }}>
          Typically, you can send and receive messages from/to your new Google Workspace email address in less than 6 hours. However, it may take up to 48 hours before you receive email at your new address. It’s no fun to wait, but the time for MX records to take effect depends on your domain host. In the meantime, you will still get your email messages at your old email client.
        </Typography>

        {/* Finish Button */}
        <Button variant="contained" color="primary">
          Finish
        </Button>
      </Paper>
    </Container>
  );
};

export default SuccessPage;
