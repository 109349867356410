import React, { useState, useContext } from "react";
import { Typography, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { imagesUrl } from "../../config/commonConfig";
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayjs from 'dayjs';
import { ColorModeContext, TimeOutContext } from "../../context/ThemeContext";
import { getItem, setItem, removeItem } from "../../lib/cookies"

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: "column",
    gap: '8px'
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.grey[700]
}));

const SubHeading = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.grey[500]
}));

const ImageSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    paddingTop: '12px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    }
}));

const ImageBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
}));

const ImageSrc = styled('div')(({ theme }) => ({
    width: '250px',
    height: '150px',
    overflow: 'hidden',
    borderRadius: '6px',
    border: '1px solid',
    borderColor: theme.palette.grey[900],
    cursor: 'pointer'
}));

const ThemeName = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.grey[700]
}));

const ThemeAutoSwitchSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingTop: '25px',
    width: '320px'
}));

const ChangeTimeHeading = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.grey[500],
}));

const CheckIconBox = styled('div')(({ theme }) => ({
    marginTop: '-60px',
    display: 'flex',
    justifyContent: 'flex-end',
}));

const AppearanceTabPanel = () => {
    const [activeTheme, setActiveTheme] = useState(getItem('scheduleTime') ? 'auto' : getItem('theme') || 'light');
    const [scheduleTime, setScheduleTime] = useState(getItem('scheduleTime') || getInitialDate())
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const setTimeOut = useContext(TimeOutContext);

    function getInitialDate() {
        // Set the starting time to 18:00:00 for today
        const today = new Date();
        today.setHours(18, 0, 0, 0);
        return today.toISOString();
    }

    const handleDayTheme = () => {
        setActiveTheme("light");
        if (theme.palette.mode !== 'light') {
            colorMode.toggleColorMode();
        }
        if (getItem("scheduleTime")) {
            removeItem("scheduleTime")
        }
    }

    const handleNightTheme = () => {
        setActiveTheme("dark");
        if (theme.palette.mode !== 'dark') {
            colorMode.toggleColorMode();
        }
        if (getItem("scheduleTime")) {
            removeItem("scheduleTime")
        }
    }

    const handleAutoTheme = () => {
        setActiveTheme("auto");
        setItem("scheduleTime", scheduleTime);
        setTimeOut.setContextTimeout(scheduleTime);
    }

    const handleTimeChange = (isoString) => {
        isoString = isoString.toISOString()
        setScheduleTime(isoString)
        setItem("scheduleTime", isoString);
        setTimeOut.setContextTimeout(isoString);
    }

    return (
        <MainSection >
            <Heading>
                Theme
            </Heading>

            <SubHeading>
                Select your application theme
            </SubHeading>

            <ImageSection>
                <ImageBox
                    onClick={() => handleDayTheme()}>
                    <ImageSrc sx={{
                        border: '2px solid',
                        borderColor: activeTheme === 'light' ? "primary.main" : "none",
                    }}>

                        <img src={imagesUrl?.light} alt="light" style={{ maxWidth: '100%' }} />

                        {activeTheme === "light" && <CheckIconBox>
                            <IconButton sx={{ color: 'primary.main' }} disableFocusRipple disableRipple disableTouchRipple>
                                <CheckCircleIcon />
                            </IconButton>
                        </CheckIconBox>}
                    </ImageSrc>

                    <ThemeName sx={{ color: activeTheme === "light" ? 'grey.800' : 'none' }}>
                        Day time
                    </ThemeName>

                </ImageBox>
                <ImageBox
                    onClick={() => handleNightTheme()}>
                    <ImageSrc
                        sx={{
                            border: '2px solid',
                            borderColor: activeTheme === 'dark' ? "primary.main" : "none",
                        }}
                    >
                        <img src={imagesUrl?.dark} alt="dark" style={{ maxWidth: '100%' }} />

                        {activeTheme === "dark" && <CheckIconBox>
                            <IconButton sx={{ color: 'primary.main' }} disableFocusRipple disableRipple disableTouchRipple>
                                <CheckCircleIcon />
                            </IconButton>
                        </CheckIconBox>}
                    </ImageSrc>

                    <ThemeName sx={{ color: activeTheme === "dark" ? 'grey.300' : 'none' }}>
                        Night time
                    </ThemeName>

                </ImageBox>

                <ImageBox
                    onClick={() => handleAutoTheme()}>
                    <ImageSrc
                        sx={{
                            border: '2px solid',
                            borderColor: activeTheme === 'auto' ? "primary.main" : "none",
                        }}
                    >
                        <img src={imagesUrl?.system} alt="system" style={{ maxWidth: '100%' }} />

                        {activeTheme === "auto" && <CheckIconBox>
                            <IconButton sx={{ color: 'primary.main' }} disableFocusRipple disableRipple disableTouchRipple>
                                <CheckCircleIcon />
                            </IconButton>
                        </CheckIconBox>}
                    </ImageSrc>


                    <ThemeName sx={{ color: activeTheme === "auto" ? 'grey.300' : 'none' }}>
                        Automatic switch
                    </ThemeName>

                </ImageBox>

            </ImageSection>

            {activeTheme === "auto" && <ThemeAutoSwitchSection>
                <ChangeTimeHeading>
                    Change auto theme switch time
                </ChangeTimeHeading>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        size="small"
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                        slotProps={{
                            popper: {
                                sx: {
                                    "& .MuiPaper-root": {
                                        backgroundColor: "grey.1000",
                                        boxShadow: 'none'
                                    },
                                    "& .MuiList-root": {
                                        backgroundColor: "grey.1000",
                                    },
                                    "& .MuiClockNumber-root": {
                                        color: "grey.500",
                                    },
                                    "& .MuiDialogActions-root": {
                                        backgroundColor: "grey.1000",
                                    },
                                },
                            },
                            textField: {
                                sx: {
                                    '& .MuiInputBase-root': {
                                        color: 'grey.400'
                                    }
                                }
                            }
                        }}
                        value={dayjs(scheduleTime)}
                        onChange={(newValue) => handleTimeChange(dayjs(newValue))}
                        inputProps={{ sx: { color: "grey.300" } }}
                    />
                </LocalizationProvider>
            </ThemeAutoSwitchSection>}
        </MainSection >
    )
}

export default AppearanceTabPanel;