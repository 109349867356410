import { alpha } from '@mui/material/styles';

export const grey = {
  0: '#ffffff',
  // 70: '#242628',
  100: '#f7f8f9',
  200: '#f1f2f4',
  300: '#dcdfe4',
  400: '#b3b9c4',
  500: '#8590a2',
  600: '#758195',
  700: '#626f86',
  800: '#44546f',
  900: '#2c3e5d',
  1000: '#172b4d',
  1100: '#091e42',
  "-100_Dark": '#101214',
  "200_Dark": '#282e33',
  "300_Dark": '#38414a',
};
export const greyDark = {
  0: '#161a1d',
  // 70: '#242628',
  100: '#1d2125',
  200: '#22272b',
  300: '#2c333a',
  400: '#454f59',
  500: '#596773',
  600: '#738496',
  700: '#8c9bab',
  800: '#9fadbc',
  900: '#b6c2cf',
  1000: '#c7d1db',
  1100: '#dee4ea',
  "-100_Dark": '#101214',
  "200_Dark": '#282e33',
  "300_Dark": '#38414a',
};

export const primary = {
  100: "#e9f2ff",
  200: "#cce0ff",
  300: "#85b8ff",
  400: "#579dff",
  lighter: "#388bff", //500
  light: "#1d7afc", //600
  main: "#0c66e4", //700
  dark: "#0055cc", //800
  darker: "#09326c", //900
  1000: "#1c2b41",
  contrastText: "#FFFFFF"
}

export const primaryDark = {
  100: "#1c2b41",
  200: "#09326c",
  300: "#0055cc",
  400: "#0c66e4",
  lighter: "#1d7afc", //500
  light: "#388bff", //600
  main: "#579dff", //700
  dark: "#85b8ff", //800
  darker: "#cce0ff", //900
  1000: "#e9f2ff",
  contrastText: "#FFFFFF"
}

export const secondary = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

export const success = {
  100: "#dcfff1",
  200: "#baf3db",
  300: "#7ee2b8",
  400: "#4bce97",
  lighter: "#2abb7f", //500
  light: "#22a06b", //600
  main: "#1f845a", //700
  dark: "#216e4e", //800
  darker: "#164b35", //900
  1000: "#1c3329",
  contrastText: "#FFFFFF"
};
export const successDark = {
  100: "#1c3329",
  200: "#164b35",
  300: "#216e4e",
  400: "#1f845a",
  lighter: "#22a06b", //500
  light: "#2abb7f", //600
  main: "#4bce97", //700
  dark: "#7ee2b8", //800
  darker: "#baf3db", //900
  1000: "#dcfff1",
  contrastText: "#FFFFFF"
};

export const warning = {
  100: "#fff7d6",
  200: "#f8e6a0",
  300: "#f5cd47",
  400: "#e2b203",
  lighter: "#cf9f02", //500
  light: "#b38600", //600
  main: "#946f00", //700
  dark: "#7f5f01", //800
  darker: "#533f04", //900
  1000: "#332e1b",
  contrastText: "#FFFFFF"
};

export const warningDark = {
  100: "#332e1b",
  200: "#533f04",
  300: "#7f5f01",
  400: "#946f00",
  lighter: "#b38600", //500
  light: "#cf9f02", //600
  main: "#e2b203", //700
  dark: "#f5cd47", //800
  darker: "#f8e6a0", //900
  1000: "#fff7d6",
  contrastText: "#FFFFFF"
};

export const error = {
  100: "#ffeceb",
  200: "#ffd5d2",
  300: "#fd9891",
  400: "#f87168",
  lighter: "#f15b50", //500
  light: "#e2483d", //600
  main: "#c9372c", //700
  dark: "#ae2e24", //800
  darker: "#5d1f1a", //900
  1000: "#42221f",
  contrastText: "#FFFFFF"
};

export const errorDark = {
  100: "#42221f",
  200: "#5d1f1a",
  300: "#ae2e24",
  400: "#c9372c",
  lighter: "#e2483d", //500
  light: "#f15b50", //600
  main: "#f87168", //700
  dark: "#fd9891", //800
  darker: "#ffd5d2", //900
  1000: "#ffeceb",
  contrastText: "#FFFFFF"
};

export const teal = {
  100: "#e7f9ff",
  200: "#c6edfb",
  300: "#9dd9ee",
  400: "#6cc3e0",
  lighter: "#42b2d7", //500
  light: "#2898bd", //600
  main: "#227d9b", //700
  dark: "#206a83", //800
  darker: "#164555", //900
  1000: "#1e3137",
  contrastText: "#FFFFFF"
};

export const tealDark = {
  100: "#1e3137",
  200: "#164555",
  300: "#206a83",
  400: "#227d9b",
  lighter: "#2898bd", //500
  light: "#42b2d7", //600
  main: "#6cc3e0", //700
  dark: "#9dd9ee", //800
  darker: "#c6edfb", //900
  1000: "#e7f9ff",
  contrastText: "#FFFFFF"
};

export const purple = {
  100: "#f3f0ff",
  200: "#dfd8fd",
  300: "#b8acf6",
  400: "#9f8fef",
  lighter: "#8f7ee7", //500
  light: "#8270db", //600
  main: "#6e5dc6", //700
  dark: "#5e4db2", //800
  darker: "#352c63", //900
  1000: "#2b273f",
  contrastText: "#FFFFFF"
};

export const purpleDark = {
  100: "#2b273f",
  200: "#352c63",
  300: "#5e4db2",
  400: "#6e5dc6",
  lighter: "#8270db", //500
  light: "#8f7ee7", //600
  main: "#9f8fef", //700
  dark: "#b8acf6", //800
  darker: "#dfd8fd", //900
  1000: "#f3f0ff",
  contrastText: "#FFFFFF"
};

export const orange = {
  100: "#fff3eb",
  200: "#fedec8",
  300: "#fec195",
  400: "#fea362",
  lighter: "#f38a3f", //500
  light: "#e56910", //600
  main: "#c25100", //700
  dark: "#a54800", //800
  darker: "#702e00", //900
  1000: "#38291e",
  contrastText: "#FFFFFF"
};

export const orangeDark = {
  100: "#38291e",
  200: "#702e00",
  300: "#a54800",
  400: "#c25100",
  lighter: "#e56910", //500
  light: "#f38a3f", //600
  main: "#fea362", //700
  dark: "#fec195", //800
  darker: "#fedec8", //900
  1000: "#fff3eb",
  contrastText: "#FFFFFF"
};

export const magenta = {
  100: "#ffecf8",
  200: "#fdd0ec",
  300: "#f797d2",
  400: "#e774bb",
  lighter: "#da62ac", //500
  light: "#cd519d", //600
  main: "#ae4787", //700
  dark: "#943d73", //800
  darker: "#50253f", //900
  1000: "#3d2232",
  contrastText: "#FFFFFF"
};

export const magentaDark = {
  100: "#3d2232",
  200: "#50253f",
  300: "#943d73",
  400: "#ae4787",
  lighter: "#cd519d", //500
  light: "#da62ac", //600
  main: "#e774bb", //700
  dark: "#f797d2", //800
  darker: "#fdd0ec", //900
  1000: "#ffecf8",
  contrastText: "#FFFFFF"
};

export const lime = {
  100: "#efffd6",
  200: "#d3f1a7",
  300: "#b3df72",
  400: "#94c748",
  lighter: "#82b536", //500
  light: "#6a9a23", //600
  main: "#5b7f24", //700
  dark: "#4c6b1f", //800
  darker: "#37471f", //900
  1000: "#28311b",
  contrastText: "#FFFFFF"
};

export const limeDark = {
  100: "#28311b",
  200: "#37471f",
  300: "#4c6b1f",
  400: "#5b7f24",
  lighter: "#6a9a23", //500
  light: "#82b536", //600
  main: "#94c748", //700
  dark: "#b3df72", //800
  darker: "#d3f1a7", //900
  1000: "#efffd6",
  contrastText: "#FFFFFF"
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[400], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};


const CUSTOM = {
  yellow: {
    main: '#E2D23F'
  },
  blue: {
    light: '#5D9BE4'
  }
}

const CARD = {
  main: "#f7f8f9"
}
const CARD_Dark = {
  main: "#161a1d"
}
const BUTTON = {
  dark: '#282828'
}


export const customGrey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};
// ----------------------------------------------------------------------

export function palette(mode) {
  const light = {
    // ...base,
    mode: 'light',

    primary,
    secondary,
    info,
    success,
    warning,
    error,
    grey,
    teal,
    purple,
    orange,
    magenta,
    lime,
    common,
    divider: alpha(grey[500], 0.2),

    text: {
      primary: grey[600],
      secondary: grey[500],
      disabled: grey[400],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200],
    },
    action: {
      ...action,
      active: grey[600],
    },
    custom: CUSTOM,
    card: CARD,
    customGrey: { ...customGrey },
    button: BUTTON
  };

  const dark = {
    mode: 'dark',

    primary: primaryDark,
    secondary,
    info,
    success: successDark,
    warning: warningDark,
    error: errorDark,
    grey: greyDark,
    teal: tealDark,
    purple: purpleDark,
    orange: orangeDark,
    magenta: magentaDark,
    lime: limeDark,
    common,
    divider: alpha(greyDark[500], 0.2),

    text: {
      primary: greyDark[600],
      secondary: greyDark[500],
      disabled: greyDark[400],
    },
    background: {
      paper: CARD_Dark.main,
      default: greyDark[100],
      neutral: alpha(greyDark[500], 0.12),
    },
    action: {
      ...action,
      active: greyDark[500],
    },
    custom: CUSTOM,
    card: CARD_Dark,
    customGrey: { ...customGrey },
    button: BUTTON
  };

  return mode === 'light' ? light : dark;
}

/* 
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#000000',
  70: '#242628',
  100: '#2C2D2F',
  200: '#434546',
  300: '#5B5C5D',
  400: '#727374',
  500: '#898A8B',
  600: '#A1A2A3',
  700: '#B8B9BA',
  800: '#D0D0D1',
  900: '#E7E8E8',
  1000: '#F0F0F0',
};

export const customGrey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const CARD = {
  main: "#FFF"
}

const PRIMARY = {
  lighter: '#C2DAFE', light: '#76B0F1',
  main: '#014B9C', //
  dark: '#103996',
  darker: '#061B64',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FEC2C2',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};
const CUSTOM = {
  yellow: {
    main: '#E2D23F'
  },
  blue: {
    light: '#5D9BE4'
  }
}

const BUTTON = {
  dark: '#282828'
}

const palette = {
  mode: 'light',
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[100],
    secondary: GREY[300],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: "#F7F8FA",
    neutral: GREY[200],
  },
  customGrey: { ...customGrey },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  custom: CUSTOM,
  button: BUTTON,
  card: CARD,
};

export default palette; */

