import Iconify from '../../components/iconify';

import {
  ArrowDownIcon,
  CheckboxIcon,
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  RadioIcon,
  RadioCheckedIcon,
  RatingIcon,
  TreeViewCollapseIcon,
  TreeViewExpandIcon,
  TreeViewEndIcon
} from './icons'

// ----------------------------------------------------------------------

export function defaultProps(theme) {
  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <Iconify icon="solar:danger-bold" width={24} />,
          info: <Iconify icon="eva:info-fill" width={24} />,
          success: <Iconify icon="eva:checkmark-circle-2-fill" width={24} />,
          warning: <Iconify icon="eva:alert-triangle-fill" width={24} />,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
      },
    },
    MuiAvatarGroup: {
      defaultProps: {
        max: 4,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
        disableElevation: true,
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: {
          variant: 'body2',
          marginTop: theme.spacing(0.5),
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <Iconify icon="solar:close-circle-bold" />,
      },
    },
    MuiDialogActions: {
      defaultProps: {
        disableSpacing: true,
      },
    },
    MuiFab: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          typography: 'subtitle2',
        },
        secondaryTypographyProps: {
          component: 'span',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
        variant: 'rounded',
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        component: 'div',
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        iconPosition: 'start',
      },
    },
    MuiTabs: {
      defaultProps: {
        textColor: 'inherit',
        variant: 'scrollable',
        allowScrollButtonsMobile: true,
      },
    },
    MuiTablePagination: {
      defaultProps: {
        backIconButtonProps: {
          size: 'small',
        },
        nextIconButtonProps: {
          size: 'small',
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ArrowDownIcon />,
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ArrowDownIcon,
      },
    },
    MuiNativeSelect: {
      defaultProps: {
        IconComponent: ArrowDownIcon,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxIndeterminateIcon />,
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
        icon: <RadioIcon />,
        checkedIcon: <RadioCheckedIcon />,
      },
    },
    MuiRating: {
      defaultProps: {
        emptyIcon: <RatingIcon />,
        icon: <RatingIcon />,
      },
    },
    MuiTreeView: {
      defaultProps: {
        defaultCollapseIcon: <TreeViewCollapseIcon />,
        defaultExpandIcon: <TreeViewExpandIcon />,
        defaultEndIcon: <TreeViewEndIcon />,
      },
    },
    MuiDataGrid: {
      defaultProps: {
        slots: {
          // column
          columnSortedAscendingIcon: () => (
            <Iconify
              width={20}
              icon="solar:alt-arrow-up-bold-duotone"
              sx={{ color: 'text.primary' }}
            />
          ),
          columnSortedDescendingIcon: () => (
            <Iconify
              width={20}
              icon="solar:alt-arrow-down-bold-duotone"
              sx={{ color: 'text.primary' }}
            />
          ),
          columnUnsortedIcon: () => (
            <Iconify
              width={20}
              icon="solar:alt-arrow-up-bold-duotone"
              sx={{ color: 'text.disabled' }}
            />
          ),
          columnMenuIcon: () => <Iconify width={20} icon="mingcute:more-1-fill" />,
          columnMenuSortAscendingIcon: () => (
            <Iconify width={20} icon="solar:alt-arrow-up-bold-duotone" />
          ),
          columnMenuSortDescendingIcon: () => (
            <Iconify width={20} icon="solar:alt-arrow-down-bold-duotone" />
          ),
          columnMenuFilterIcon: () => <Iconify width={20} icon="solar:filter-bold" />,
          columnMenuHideIcon: () => <Iconify width={20} icon="solar:eye-closed-bold" />,
          columnMenuManageColumnsIcon: () => <Iconify width={20} icon="solar:eye-bold" />,
          columnSelectorIcon: () => <Iconify width={20} icon="solar:eye-bold" />,
          // filter
          filterPanelDeleteIcon: () => <Iconify width={20} icon="eva:close-fill" />,
          openFilterButtonIcon: () => <Iconify width={20} icon="solar:filter-bold" />,
          columnFilteredIcon: () => (
            <Iconify width={16} icon="solar:filter-bold" sx={{ color: 'text.primary' }} />
          ),
          // density
          densityCompactIcon: () => (
            <Iconify width={20} icon="material-symbols:table-rows-narrow-rounded" />
          ),
          densityStandardIcon: () => <Iconify width={20} icon="mingcute:rows-4-fill" />,
          densityComfortableIcon: () => <Iconify width={20} icon="mingcute:rows-2-fill" />,
          // export
          exportIcon: () => <Iconify width={20} icon="solar:export-bold" />,
          // quick filter
          quickFilterIcon: () => (
            <Iconify width={24} icon="eva:search-fill" sx={{ color: 'text.secondary' }} />
          ),
          quickFilterClearIcon: () => <Iconify width={20} icon="eva:close-fill" />,
        },
        slotProps: {
          basePopper: {
            placement: 'bottom-end',
          },
          baseTextField: {
            variant: 'outlined',
            InputLabelProps: { shrink: true },
          },
          baseFormControl: {
            variant: 'outlined',
          },
          baseSelect: {
            variant: 'outlined',
          },
        },
      },
    },
  };
}
