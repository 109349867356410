// ChooseDomainType.js
import React from 'react';
import {
    Box,
    Card,
    CardContent,
    CardActions,
    Button,
    Typography
} from '@mui/material';

const ChooseDomainType = ({ onSelect }) => {
    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <Card sx={{ flex: 1, border: '1px solid', borderColor: 'grey.300' }}>
                <CardContent>
                    <Typography variant="h6">Existing Domain</Typography>
                    <Typography variant="body2">
                        Use a domain you already own. Make sure the DNS records are properly set up.
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button size="small" variant='outlined' onClick={() => onSelect('existing')}>Use Existing Domain</Button>
                </CardActions>
            </Card>
            <Card sx={{ flex: 1, border: '1px solid', borderColor: 'grey.300' }}>
                <CardContent>
                    <Typography variant="h6">New Domain</Typography>
                    <Typography variant="body2">
                        Register a new domain for your workspace and configure the necessary DNS records.
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button size="small" variant='outlined' onClick={() => {
                        onSelect('new')
                        window.open("https://www.google.com/search?q=buy+domain", '_blank')
                        }}>Register New Domain</Button>
                </CardActions>
            </Card>
        </Box>
    );
};

export default ChooseDomainType;
