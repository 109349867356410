
// eslint-disable-next-line
const DriveIcon = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_569_13025)">
                <path d="M6.05001 15.324C6.05001 15.7368 5.67561 16.0464 5.26761 15.9768C4.79001 15.8928 4.31961 15.756 3.88281 15.5736C3.87561 15.5712 3.86841 15.5688 3.86361 15.5664C3.40761 15.372 2.97561 15.1272 2.57481 14.832C1.68441 14.1744 0.978814 13.2936 0.534814 12.2856C0.467614 12.1344 0.405214 11.9784 0.352414 11.82C0.119614 11.1528 0.00201416 10.452 0.00201416 9.7392C0.00201416 9.5616 0.00921416 9.384 0.0236142 9.2112C0.0548142 8.8296 0.122014 8.4528 0.218014 8.0904C0.431614 7.2984 0.791614 6.5616 1.29321 5.9064C1.65801 5.4288 2.08761 5.004 2.57241 4.644C2.88681 4.4112 3.22521 4.2072 3.57561 4.0392C4.30041 3.6864 5.08281 3.48 5.89401 3.4248C6.25881 3.3984 6.57321 3.6744 6.59721 4.0392C6.62121 4.404 6.34761 4.7184 5.98281 4.7424C5.34201 4.7856 4.72521 4.9512 4.15161 5.2296C3.87321 5.364 3.60921 5.5248 3.35961 5.7096C2.97561 5.9928 2.63481 6.3288 2.34681 6.708C1.95081 7.2264 1.66521 7.8072 1.49721 8.4336C1.42041 8.7216 1.36761 9.0192 1.34361 9.3192C1.33161 9.4584 1.32681 9.5976 1.32681 9.7368C1.32681 10.3032 1.42041 10.8576 1.60521 11.3856C1.64841 11.5104 1.69641 11.6328 1.74921 11.7528C2.09961 12.552 2.65881 13.2504 3.36201 13.7712C3.67401 13.9992 4.00761 14.1936 4.36281 14.3448C4.37001 14.3472 4.37721 14.3496 4.38201 14.3544C4.74201 14.508 5.11641 14.616 5.50281 14.6808C5.81961 14.7336 6.05001 15.012 6.05001 15.3312V15.324Z" fill={color} />
                <path d="M24.002 10.6776C24.002 11.1984 23.9276 11.7096 23.7812 12.204C23.7212 12.4104 23.6468 12.6168 23.558 12.8184C23.2892 13.44 22.91 13.9992 22.4276 14.4816C22.3172 14.5944 22.1972 14.7048 22.0724 14.8056C21.2324 15.5112 20.21 15.9384 19.118 16.0416C18.9572 16.0584 18.7868 16.0656 18.6116 16.0656C18.602 16.0656 18.5924 16.0656 18.5828 16.0656C18.2324 16.0656 17.9516 15.7752 17.9516 15.4224V15.384C17.9516 15.024 18.2468 14.7408 18.6068 14.7432C18.6068 14.7432 18.6116 14.7432 18.614 14.7432C18.746 14.7432 18.8732 14.7384 18.9932 14.7264C19.8188 14.6496 20.5892 14.3256 21.2252 13.7928C21.3188 13.716 21.4076 13.6344 21.4892 13.5504C21.854 13.1856 22.142 12.7632 22.3436 12.2928C22.4084 12.1416 22.4636 11.988 22.5092 11.832C22.6196 11.46 22.6748 11.0712 22.6748 10.68C22.6748 10.404 22.6484 10.1328 22.5932 9.8688C22.5644 9.7296 22.5284 9.5928 22.4876 9.4632C22.3148 8.9112 22.0172 8.3928 21.6284 7.9608C21.2204 7.5072 20.7068 7.1472 20.1428 6.9168C19.7204 6.7464 19.2764 6.6456 18.8228 6.624C18.458 6.6072 18.1772 6.2952 18.1964 5.9304C18.2132 5.5656 18.5276 5.2848 18.89 5.304C19.4948 5.3352 20.0828 5.4648 20.642 5.6928C21.3884 5.9976 22.07 6.4752 22.6124 7.0752C23.1284 7.6488 23.522 8.3376 23.75 9.0672C23.8052 9.2376 23.8532 9.42 23.8892 9.6048C23.9612 9.9552 23.9972 10.3176 23.9972 10.68L24.002 10.6776Z" fill={color} />
                <path d="M18.8612 6.6216C18.566 6.6216 18.2996 6.4248 18.2204 6.1248C18.158 5.8872 18.0812 5.6472 17.9876 5.412C17.918 5.2344 17.8436 5.064 17.7644 4.908C17.6108 4.596 17.4308 4.2936 17.2244 4.008C16.5956 3.132 15.7508 2.4216 14.7812 1.956C13.9844 1.572 13.1276 1.3608 12.2372 1.3272C12.1604 1.3248 12.0836 1.3272 12.0092 1.3224H11.9996C10.5692 1.3224 9.218 1.7832 8.0876 2.652C7.6244 3.0072 7.2116 3.4248 6.8612 3.8904C6.734 4.0608 6.614 4.2384 6.5036 4.4184C6.314 4.7304 5.9084 4.8312 5.5964 4.6416C5.2844 4.452 5.1836 4.0464 5.3732 3.7344C5.5052 3.516 5.6516 3.3 5.8052 3.096C6.2276 2.5344 6.7244 2.0328 7.2812 1.6032C8.6468 0.5544 10.2788 0 12.002 0C12.0236 0 12.0428 0 12.0644 0C12.1388 0 12.2156 0 12.29 0.0048C13.3628 0.0432 14.3948 0.3 15.3572 0.7632C16.526 1.3248 17.5436 2.1792 18.2996 3.2376C18.5468 3.5832 18.7652 3.948 18.95 4.32C19.0436 4.5072 19.1348 4.7136 19.2188 4.9272C19.3292 5.2128 19.4252 5.5032 19.4996 5.7936C19.5908 6.1464 19.3796 6.5088 19.0244 6.6C18.9692 6.6144 18.914 6.6216 18.8588 6.6216H18.8612Z" fill={color} />
                <path d="M15.7916 19.5744C15.5852 19.7664 15.2636 19.7544 15.0716 19.548L12.5108 16.7976V23.4912C12.5108 23.772 12.2828 24 12.002 24C11.7212 24 11.4932 23.772 11.4932 23.4912V16.8L8.93242 19.548C8.74042 19.7544 8.41882 19.7664 8.21242 19.5744C8.10442 19.4736 8.04922 19.3368 8.04922 19.2024C8.04922 19.0776 8.09482 18.9552 8.18602 18.8568L11.6276 15.1632C11.8292 14.9472 12.1724 14.9472 12.374 15.1632L15.8156 18.8568C16.0076 19.0632 15.9956 19.3824 15.7892 19.5744H15.7916Z" fill={color} />
                <path d="M7.70362 9.2304H7.70122C7.09415 9.2304 6.60202 9.72253 6.60202 10.3296V10.332C6.60202 10.9391 7.09415 11.4312 7.70122 11.4312H7.70362C8.31069 11.4312 8.80282 10.9391 8.80282 10.332V10.3296C8.80282 9.72253 8.31069 9.2304 7.70362 9.2304Z" fill={color} />
                <path d="M12.0044 9.2304H12.002C11.395 9.2304 10.9028 9.72253 10.9028 10.3296V10.332C10.9028 10.9391 11.395 11.4312 12.002 11.4312H12.0044C12.6115 11.4312 13.1036 10.9391 13.1036 10.332V10.3296C13.1036 9.72253 12.6115 9.2304 12.0044 9.2304Z" fill={color} />
                <path d="M16.3028 9.2304H16.3004C15.6934 9.2304 15.2012 9.72253 15.2012 10.3296V10.332C15.2012 10.9391 15.6934 11.4312 16.3004 11.4312H16.3028C16.9099 11.4312 17.402 10.9391 17.402 10.332V10.3296C17.402 9.72253 16.9099 9.2304 16.3028 9.2304Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_569_13025">
                    <rect width="24" height="24" fill="white" transform="translate(0.00201416)" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default DriveIcon