import React, { useContext, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { configFile } from "../../../config";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
  FormControl,
  Link,
  FormHelperText,
  useTheme,
} from "@mui/material";

import { PLATFORM_MAPPER } from "../../../config/commonConfig";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import { axiosGetCall, axiosPostCall } from "../../../services/apisCall";
import AuthFooter from "../../../layouts/AuthFooter";
import ErrorMessage from "../../../utils/ErrorMessage.json";
import {
  setLoginSession,
  validateDomain,
  validateEmail,
  validatePassword,
} from "../../../utils/common";
import Logger from "../../../lib/Logger";
import styled from "@emotion/styled";
import { renderErrorMessage } from "../../../lib/toastMessage";
import { handleRedirect } from "../../../utils/common";
import LogoBlue from "../../../assets/svgs/logo";
import { BrandingContext } from "../../../context/brandingContext";

// import { termsAndCondition } from "../../../config/SARV/termsAndConditions";
// import { termsAndConditionUrl } from "../../../config/SARV/branding";

const { GOOGLE_AUTH_CLIENT_ID } = configFile;
// const product = currentProduct();
// const { allowedFeatures: { googleSignUp }, privacyPolicyUrl, termsAndConditionUrl } = await import(`../../../config/${product}/branding`);

// const { privacyPolicy } = await import(`../../../config/${product}/privacy`);
// const { termsAndCondition } = await import(`../../../config/${product}/termsAndCondition`);

const HeadingSection = styled("div")(({ theme }) => ({
  width: "450px",
  display: "flex",
  background: "#FFFFFF",
  padding: "30px 40px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
    width: "auto",
    padding: "20px 30px",
  },
}));

function SignUpForm() {
  const {
    palette: { mode },
    breakpoints,
  } = useTheme();
  const greaterThanMid = useMediaQuery(breakpoints.up("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const { googleAuth = false } = useContext(BrandingContext) || {};

  const logger = new Logger("Signup");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contact, setContact] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectCountryCode /* setSelectCountryCode */] = useState(91);
  const [checkBox, setCheckBox] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [pwdLength, setPwdLength] = useState(true);
  const [pwdNumber, setPwdNumber] = useState(true);
  const [pwdUppercase, setPwdUppercase] = useState(true);
  const [pwdLowercase, setPwdLowercase] = useState(true);
  const [pwdSymbol, setPwdSymbol] = useState(true);
  const [signupLoading, setSignupLoading] = useState(false);
  const { k = "" } = location?.state || {};

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Manage signup submit call.
  const handleSignUp = async (e) => {
    try {
      e.preventDefault();
      if (!name) return setNameError(true);
      if (!email) return setEmailError("blank");
      if (!password) return setPasswordError("blank");
      if (!validateEmail(email)) return setEmailError("invalid");
      if (!validateDomain(email))
        return renderErrorMessage(ErrorMessage?.[4019]);

      password.length >= 8 && password.length <= 20
        ? setPwdLength(true)
        : setPwdLength(false);
      /[0-9]/.test(password) ? setPwdNumber(true) : setPwdNumber(false);
      /[A-Z]/.test(password) ? setPwdUppercase(true) : setPwdUppercase(false);
      /[a-z]/.test(password) ? setPwdLowercase(true) : setPwdLowercase(false);
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
        ? setPwdSymbol(true)
        : setPwdSymbol(false); //eslint-disable-line

      if (!validatePassword(password)) return;

      if (!checkBox) return setCheckError("blank");

      setSignupLoading(true);

      const payload = {
        name,
        email,
        password,
        type: "1",
        mobile: contact,
        countryCode: selectCountryCode,
        origin: window.location.hostname,
      };
      if (k && PLATFORM_MAPPER[k]) payload.key = PLATFORM_MAPPER[k];
      const { message } = await axiosPostCall("register", payload);

      return navigate("/login", {
        state: { message, time: Date.now() + 10 * 1000, register: !!k },
      });
    } catch (error) {
      logger.error(error.message);
      setSignupLoading(false);
      renderErrorMessage(error?.message);
    }
  };

  const handleGoogleLogin = async (googleToken) => {
    try {
      const payload = {
        method: "googleLogin",
        token: googleToken,
        origin: window.location.hostname,
      };
      if (k && PLATFORM_MAPPER[k]) payload.key = PLATFORM_MAPPER[k];
      const { exp, sessionToken, token } = await axiosPostCall(
        "login",
        payload
      );
      if (!exp || !sessionToken || !token) {
        return renderErrorMessage(ErrorMessage?.[4001]);
      }
      setLoginSession(exp, sessionToken, token);
      return k && PLATFORM_MAPPER[k]
        ? handleRedirect(
            PLATFORM_MAPPER[k],
            "",
            { sessionToken, exp, token },
            true,
            mode
          )
        : (window.location.href = "/");
    } catch (error) {
      logger.error(error);
      return renderErrorMessage(error.message);
    }
  };

  // const handlePrivacyClick = () => {
  //   if (privacyPolicy) return navigate("/privacy");
  //   if (privacyPolicyUrl) return window.location.href = privacyPolicyUrl;
  //   return;
  // }

  // const handleTermsAndConditionClick = () => {
  //   if (termsAndCondition) return navigate("/privacy");
  //   if (termsAndConditionUrl) window.location.href = termsAndConditionUrl;
  //   return;
  // }

  const fetchBranding = async (isPrivacy) => {
    let path = "#";
    try {
      const { privacy = "#", termsLink = "#" } = await axiosGetCall(
        "fetchBranding",
        { privacy: true }
      );
      if (isPrivacy) {
        path = privacy;
      } else {
        path = termsLink;
      }
    } catch (error) {
      console.log(error);
    } finally {
      return path && path === "#"
        ? navigate(path)
        : (window.location.href = path);
    }
  };

  const handleSubmitForm = (e) => e.preventDefault();
  const handleCheckbox = () => {
    setCheckBox((prev) => !prev);
    checkError && setCheckError("");
  };

  return (
    <HeadingSection>
      <ToastContainer />
      <Box
        className="FormContent"
        sx={{
          // textAlign: { xs: "center", sm: "start", md: "start", lg: 'start' },
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          width: "100%",
        }}
        onSubmit={handleSubmitForm}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "210px",
              height: "60px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LogoBlue isAuth={true} height={60} />
          </Box>
        </Box>

        <Box
          className="FormGroup"
          sx={{
            display: "flex",
            gap: "15px",
            flexDirection: "column",
          }}
        >
          <Box
            className="FormHeading"
            sx={{
              display: "flex",
              flexDirection: "column",
              p: "0px",
            }}
          >
            <Typography
              variant="custom24600"
              sx={{
                color: "#282828",
                display: "flex",
                justifyContent: "center",
                fontSize: "1.5rem",
              }}
            >
              Create account
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "16px",
            }}
          >
            {googleAuth && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        const token = credentialResponse.credential;
                        handleGoogleLogin(token);
                      }}
                      onError={() => {
                        return renderErrorMessage(ErrorMessage?.[4006]);
                      }}
                      context="signup"
                      text="signup_with"
                      size="large"
                      width={greaterThanMid ? "372px" : "300px"}
                      // width={'370px'}
                    />
                  </GoogleOAuthProvider>
                </Box>

                <Divider>
                  <Typography
                    sx={{
                      display: "flex",
                      fontWeight: "500",
                      alignItems: "center",
                      fontSize: "13px",
                      color: "#D0D0D1",
                      mr: "15px",
                      ml: "15px",
                    }}
                  >
                    Or
                  </Typography>
                </Divider>
              </>
            )}

            <Box
              sx={{
                display: "flex",
                gap: "6px",
                flexDirection: "column",
                alignItems: "flex-start",
                p: "0px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#282828",
                }}
              >
                Your name
              </Typography>

              <TextField
                id="Name"
                placeholder="Enter your name"
                variant="outlined"
                fullWidth
                size="small"
                type={"text"}
                error={nameError}
                onClick={() => (nameError ? setNameError(false) : "")}
                helperText={nameError ? ErrorMessage?.[4003] : ""}
                onChange={(e) => setName(e.target.value)}
                value={name}
                inputProps={{
                  sx: {
                    // height: "30px",
                    fontSize: "14px",
                    color: "#282828",
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "5px",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "13px",
                  fontWeight: 500,
                  color: "#282828",
                }}
              >
                Email address
              </Typography>

              <TextField
                id="email"
                placeholder="Enter your email"
                variant="outlined"
                fullWidth
                size="small"
                type={"email"}
                error={Boolean(emailError)}
                onClick={() => (emailError ? setEmailError("") : "")}
                helperText={
                  emailError === "blank"
                    ? ErrorMessage?.[4003]
                    : emailError === "invalid"
                    ? ErrorMessage?.[4004]
                    : ""
                }
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                inputProps={{
                  sx: {
                    // height: "30px",
                    fontSize: "14px",
                    color: "#282828",
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                },
                gap: "25px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#282828",
                  }}
                >
                  Phone number
                </Typography>
                <TextField
                  id="ContactNo"
                  placeholder="Enter phone number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type={"number"}
                  error={contactError}
                  onChange={(e) => setContact(e.target.value)}
                  onClick={() => (contactError ? setContactError(false) : "")}
                  helperText={contactError ? ErrorMessage?.[4003] : ""}
                  value={contact}
                  sx={{
                    "& .MuiInput-input": {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          WebkitAppearance: "none",
                        },
                    },
                  }}
                  inputProps={{
                    sx: {
                      // height: "30px",
                      fontSize: "14px",
                      color: "#282828",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        {`+ ${selectCountryCode} `}{" "}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#282828",
                  }}
                >
                  Password
                </Typography>

                <form style={{ width: "100%" }}>
                  <TextField
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    placeholder={"Enter password"}
                    fullWidth
                    size="small"
                    autoComplete="on"
                    error={Boolean(
                      passwordError ||
                        !pwdLength ||
                        !pwdNumber ||
                        !pwdUppercase ||
                        !pwdLowercase ||
                        !pwdSymbol
                    )}
                    onClick={() => {
                      setPasswordError("");
                      setPwdLength(true);
                      setPwdNumber(true);
                      setPwdUppercase(true);
                      setPwdLowercase(true);
                      setPwdSymbol(true);
                    }}
                    helperText={
                      passwordError === "blank"
                        ? ErrorMessage?.[4002]
                        : !pwdLength
                        ? "pasword length should be 8 to 20."
                        : !pwdNumber
                        ? "At least 1 Number required."
                        : !pwdUppercase
                        ? "At least 1 Uppercase character required"
                        : !pwdLowercase
                        ? "At least 1 Lowercase character required"
                        : !pwdSymbol
                        ? "At least 1 special character required"
                        : ""
                    }
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    inputProps={{
                      sx: {
                        // height: "30px",
                        fontSize: "14px",
                        color: "#282828",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </form>
              </Box>
            </Box>

            <FormControl sx={{ mt: "-14px", mb: "-14px" }}>
              <FormControlLabel
                helperText={checkError === "blank" ? ErrorMessage?.[4003] : ""}
                control={
                  <Checkbox
                    onClick={handleCheckbox}
                    sx={{ color: "#D0D0D1" }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#8D8D8D",
                    }}
                  >
                    I agree with{" "}
                    <Link
                      // href={privacyPath || privacyPolicy || "#"}
                      onClick={() => fetchBranding(true)}
                      underline="none"
                    >
                      Privacy policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      // href={termsPath || termsAndCondition || "#"}
                      onClick={() => fetchBranding()}
                      underline="none"
                    >
                      Terms of services
                    </Link>
                  </Typography>
                }
              />

              <FormHelperText>
                {checkError === "blank" ? ErrorMessage?.[4003] : ""}
              </FormHelperText>
            </FormControl>

            <AuthFooter
              btnText={"Create account"}
              navigationMessage={"Already have an account?"}
              navigateText={"Log in"}
              btnCheckHandler={handleSignUp}
              navigationCheckHandler={() => navigate("/login")}
              loadingBtn={signupLoading}
            />
          </Box>
        </Box>
      </Box>
    </HeadingSection>
  );
}

export default SignUpForm;
