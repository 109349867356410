
// eslint-disable-next-line
const SmsIcon = ({ color }) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1914_6649)">
                <path d="M22.0388 1.396C22.3698 1.396 22.6388 1.664 22.6388 1.994V18.453C22.6388 18.784 22.3698 19.051 22.0388 19.051H7.95382C7.35182 19.051 6.76082 19.21 6.24082 19.513L2.03982 21.956C1.77282 22.111 1.43882 21.919 1.43882 21.611V1.995C1.43882 1.664 1.70782 1.397 2.03882 1.397H22.0388M2.03882 0C0.933818 0 0.0388184 0.893 0.0388184 1.995V23.001C0.0388184 23.584 0.517818 24 1.04082 24C1.20882 24 1.38082 23.957 1.54282 23.863L6.94682 20.72C7.25282 20.542 7.59982 20.448 7.95382 20.448H22.0388C23.1438 20.448 24.0388 19.555 24.0388 18.453V1.995C24.0388 0.893 23.1438 0 22.0388 0H2.03882Z" fill={color} />
                <path d="M17.3408 5.73199H6.73682C6.35132 5.73199 6.03882 6.0445 6.03882 6.43C6.03882 6.81549 6.35132 7.12799 6.73682 7.12799H17.3408C17.7263 7.12799 18.0388 6.81549 18.0388 6.43C18.0388 6.0445 17.7263 5.73199 17.3408 5.73199Z" fill={color} />
                <path d="M17.3158 9.522H6.71179C6.3263 9.522 6.01379 9.83451 6.01379 10.22C6.01379 10.6055 6.3263 10.918 6.71179 10.918H17.3158C17.7013 10.918 18.0138 10.6055 18.0138 10.22C18.0138 9.83451 17.7013 9.522 17.3158 9.522Z" fill={color} />
                <path d="M17.3158 13.312H6.71179C6.3263 13.312 6.01379 13.6245 6.01379 14.01C6.01379 14.3955 6.3263 14.708 6.71179 14.708H17.3158C17.7013 14.708 18.0138 14.3955 18.0138 14.01C18.0138 13.6245 17.7013 13.312 17.3158 13.312Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_1914_6649">
                    <rect width="24" height="24" fill="white" transform="translate(0.0388184)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SmsIcon