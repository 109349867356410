import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { BrandingContext } from "../context/brandingContext";
import { getItem } from "../lib/cookies";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const pages = ['Home'];
const settings = ["Profile", "Dashboard"];

function PublicNavbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [profile, setProfile] = React.useState("");
  const navigate = useNavigate();

  const { logoUrlLight = "" } = React.useContext(BrandingContext) || {};

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (id) => {
    console.log(id);
    setAnchorElUser(null);
    if (id === "Dashboard") {
      window.location.href = "/";
    }
    if (id === "Profile") {
      window.location.href = "/settings";
    }
  };
  const handleCheckPorducts = (id) => {
    setAnchorElUser(null);
    if (id === "Products") {
      window.location.href = "/plans";
    }
    if (id === "Pricing") {
      window.location.href = "/plans";
    }
    if (id === 'Home') {
      window.location.href = "/";
    }
  };

  React.useEffect(() => {
    const token = getItem("token");
    if (token) {
      const { profileUrl } = jwtDecode(token);
      setProfile(profileUrl);
    }
  }, []);

  return (
    <AppBar position="static" sx={{ backgroundColor: 'card.main', borderBottom: '1px solid', borderColor: 'grey.300' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ height: '40px' }}>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, paddingRight: "20px" }}
          >
            <img src={logoUrlLight} width="170px" height="43px" alt="logo" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                paddingLeft: "20px",
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              transform: "translateX(-50%)",
              ml: "10px",
            }}
          >
            <img src={logoUrlLight} width="200px" height="30px" alt="logo" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleCheckPorducts(page)}
                sx={{ my: 2, color: "grey.800", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {profile ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleCloseUserMenu(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  color: "#fff",
                  borderColor: "#fff",
                  "&:hover": {
                    borderColor: "#F0F0F0",
                    color: "#F0F0F0",
                  },
                }}
                variant="outlined"
                onClick={() => (window.location.href = "/register")}
              >
                Register
              </Button>

              <Button
                sx={{
                  color: "#fff",
                  borderColor: "#fff",
                  "&:hover": {
                    borderColor: "#F0F0F0",
                    color: "#F0F0F0",
                  },
                }}
                variant="outlined"
                onClick={() => (window.location.href = "/login")}
              >
                Login
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default PublicNavbar;
