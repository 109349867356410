import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EmailSetUp = ({ data }) => {

    const navigate = useNavigate();
    const workspaceDetails = useSelector(
        (state) => state.workspace.workspaceDetails
    );

    useEffect(() => {
        if (data?.stepName === "Set Up Email Accounts") {
            workspaceDetails?.status !== "completed" && navigate("/employee/createUser", { state: { stepName: "Set Up Email Accounts" } })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, workspaceDetails])



    return (
        <>
        </>
    )
}

export default EmailSetUp;