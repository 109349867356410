import React, { useState, useEffect } from "react";
import "./spinnerStyle.css";
// import MicNoneIcon from "@mui/icons-material/MicNone";
import SpinnerLogo from "../../assets/svgs/spinnerLogo";
import jwt from "jwt-decode";
import { getItem } from "../../lib/cookies";
import { styled } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
  /*   Typography,
    IconButton,
    Divider, */
  useTheme,
  Link,
  IconButton,
  Typography,
  SvgIcon
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { checkProductAccess, getLoginSession, handleRedirect } from "../../utils/common";
import parse from "url-parse";
import { ProfileMenu } from "../../sections/profileMenu";
import { removeBulkItems } from "../../lib/cookies";
import { PLATFORM_MAPPER } from "../../config/commonConfig";
import { configFile } from "../../config";
import { handleLogout } from "../../services/apisCall";
import Logger from '../../lib/Logger';
// Icons
// import LogoutIcon from '@mui/icons-material/Logout';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
// import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
// import CallIcon from '@mui/icons-material/Call';

// import { ColorModeContext } from "../../context/ThemeContext";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreMenu from "../moreMenu";
import MicSvg from "../../assets/svgs/micSvg";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { currentProduct } from "../../config";
import DashboardActiveIcon from "../../assets/svgs/productIcons/dashboardActive";
import SmsActiveIcon from "../../assets/svgs/productIcons/smsActive";
import SmsIcon from "../../assets/svgs/productIcons/sms";
import MeetActiveIcon from "../../assets/svgs/productIcons/meetActive";
import MeetIcon from "../../assets/svgs/productIcons/meet";
import WebinarActiveIcon from "../../assets/svgs/productIcons/webinarActive";
import WebinarIcon from "../../assets/svgs/productIcons/webinar";
import VoiceActiveIcon from "../../assets/svgs/productIcons/voiceActive";
import VoiceIcon from "../../assets/svgs/productIcons/voice";
import MoreIcon from "../../assets/svgs/productIcons/more";
import MailActiveIcon from "../../assets/svgs/productIcons/mailActive";
import MailIcon from "../../assets/svgs/productIcons/mail";
import ChatActiveIcon from "../../assets/svgs/productIcons/chatActive";
import ChatIcon from "../../assets/svgs/productIcons/chat";
import DriveActiveIcon from "../../assets/svgs/productIcons/driveActive";
import DriveIcon from "../../assets/svgs/productIcons/drive";
import AppLauncherIcon from "../../assets/svgs/productIcons/appLauncher";
import Iconify from "../../components/iconify";
import WhatsappApiActiveIcon from "../../assets/svgs/productIcons/whatsappApiActive";
import WhatsappApiIcon from "../../assets/svgs/productIcons/whatsappApi";
import ListsActiveIcon from "../../assets/svgs/productIcons/listsActive";
import ListIcon from "../../assets/svgs/productIcons/lists";
const product = currentProduct();
const { allowedOrigin } = await import(`../../config/${product}/branding`)
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';

// Drawer header style hadler
/* const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 2.9),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
})); */

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1.8),
  position: "absolute",
  bottom: "0px",
  flexDirection: 'column',
  paddingBottom: '88px',
  gap: ' 20px',
  width: '100%',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// Drawer style handler
const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7.5)} + 1px)`,
  borderRight: '1px solid'
}));

export const SpinnerSection = () => {
  const { palette: { mode = "light" } } = useTheme();
  const [open, setOpen] = useState(false);
  const [activeBar, setActiveBar] = useState("/");
  const [anchorEl, setAnchorEl] = useState(null);
  const [tokenInfo, setTokenInfo] = useState({ name: "User", profileUrl: "" });
  const [view, setView] = useState("desktop")
  const [anchorElMore, setAnchorElMore] = useState(null);
  const openMore = Boolean(anchorElMore);

  const handleClickMore = (event) => {
    setAnchorElMore(event.currentTarget);
  };
  const handleCloseMore = () => {
    setAnchorElMore(null);
  };

  const { URL_MAPPER } = configFile;
  const logger = new Logger("SpinnerSection");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconSx = { width: "22px", height: "22px", };

  const activeBgColor = mode === "light" ? "grey.900" : "#4F4F50";

  const defaultList = [
    {
      title: "Overview",
      icon: activeBar === "/" ? <SvgIcon sx={iconSx}>
        <DashboardActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <DashboardIcon />
        </SvgIcon>,
      active: activeBar === "/",
      path: "/",
      disabled: false,
      keys: "Console"
    },
    {
      title: "Mail",
      icon: activeBar === "mail" ? <SvgIcon sx={iconSx}>
        <MailActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <MailIcon color={"currentColor"} />
        </SvgIcon>,
      active: activeBar === "mail",
      path: "email",
      disabled: false,
      keys: "Email"
    },
    {
      title: "Chat",
      icon: activeBar === "chat" ? <SvgIcon sx={iconSx}>
        <ChatActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <ChatIcon color={"currentColor"} />
        </SvgIcon>,
      active: activeBar === "chat",
      path: "chat",
      disabled: false,
      keys: "Chat"
    },

    {
      title: "Meet",
      icon: activeBar === "meet" ? <SvgIcon sx={iconSx}>
        <MeetActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <MeetIcon color={"currentColor"} />
        </SvgIcon>,
      active: activeBar === "meet",
      path: "meet",
      disabled: false,
      keys: "Meet"

    },

    {
      title: "Webinar",
      icon: activeBar === "webinar" ? <SvgIcon sx={iconSx}>
        <WebinarActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <WebinarIcon color={"currentColor"} />
        </SvgIcon>,
      active: activeBar === "webinar",
      path: "webinar",
      disabled: false,
      keys: "Webinar"

    },
    {
      title: "SMS",
      icon: activeBar === "sms" ? <SvgIcon sx={iconSx}>
        <SmsActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <SmsIcon color={"currentColor"} />
        </SvgIcon>,
      active: activeBar === "sms",
      path: "sms",
      disabled: false,
      keys: "SMS"
    },



    // {
    //   title: "DeepCall",
    //   icon: activeBar === "deepCall" ? <CallIcon sx={iconSx} /> : <CallOutlinedIcon sx={iconSx} />,
    //   active: activeBar === "deepCall",
    //   path: "deepCall",
    //   disabled: false,
    // },
    {
      title: "Broadcast",
      icon: activeBar === "voice" ? <SvgIcon sx={iconSx}>
        <VoiceActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <VoiceIcon color={"currentColor"} />
        </SvgIcon>,
      active: activeBar === "voice",
      path: "voice",
      disabled: false,
      keys: "Broadcast"

    },
    {
      title: "Drive",
      icon: activeBar === "drive" ? <SvgIcon sx={iconSx}>
        <DriveActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <DriveIcon color={"currentColor"} />
        </SvgIcon>,
      active: activeBar === "drive",
      path: "drive",
      disabled: false,
      keys: "Drive"

    },
    {
      title: "List",
      icon: activeBar === "lists" ? <SvgIcon sx={iconSx}>
        <ListsActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <ListIcon color={"currentColor"} />
        </SvgIcon>,
      active: activeBar === "lists",
      path: "lists",
      disabled: false,
      keys: "Lists"

    },
    {
      title: "Whatsapp",
      icon: activeBar === "/wa" ? <SvgIcon sx={iconSx}><WhatsappApiActiveIcon /></SvgIcon> :
        <SvgIcon><WhatsappApiIcon /></SvgIcon>,
      active: activeBar === "/wa" ? true : false,
      path: "wa",
      disabled: false,
      keys: "WaApi"
    },
  ];

  const products = checkProductAccess() || [];
  console.log("🚀 ~ products:", products)

  const homeSideBar = defaultList.filter(item => products.includes(item?.keys));
  const moreMenuShow = products.filter(item => ["Lists"].includes(item))?.length;

  const logout = async () => {
    try {
      await handleLogout();
      removeBulkItems([{ key: "sessionToken" }, { key: "token" }, { key: "exp" }]);
      Object.keys(PLATFORM_MAPPER).forEach((key) => {
        if (PLATFORM_MAPPER[key] === activeBar) {
          window.parent.postMessage({ session: true, redirectPath: `${URL_MAPPER["console"]}/login?k=${key}` }, '*');
        }
      })
    } catch (error) {
      logger.error("SpinnerSection.jsx [logout] catchError", error);
    }
  }

  // const handleTheme = (theme) => {
  //   colorMode.toggleColorMode();
  //   window.parent.postMessage({ theme }, '*');
  // }

  useEffect(() => {

    try {
      const { name = "User", profileUrl = "" } = jwt(getItem("token"));
      const scheduleTime = getItem("scheduleTime");
      const theme = getItem("theme");

      setTokenInfo({ name, profileUrl });
      if (process.env.REACT_APP_SINGLE_DOMAIN === "true") {
        console.log("not sharing theme by postmessage in single domain.")
      }
      else {
        window.parent.postMessage({ theme, scheduleTime }, '*');
      }

    } catch (error) {
      console.log(error);
    }

    const url = parse(window.location.href, true);
    const { k, t = "desktop", s } = url.query;
    setView(t)
    if (s) setOpen(true)

    if (["meet", "webinar", "deepcall", "sms", "voice", "chat", "mail", "drive", "lists"].includes(k)) {
      setActiveBar(k);

    }
    const [{ exp }, { sessionToken }] = getLoginSession();
    if (exp < + new Date() || !sessionToken) {
      Object.keys(PLATFORM_MAPPER).forEach((key) => {
        if (PLATFORM_MAPPER[key] === k) {
          window.parent.postMessage({ redirectPath: `${URL_MAPPER["console"]}/login?k=${key}` }, '*');
        }
      })
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {view === "desktop" ?
        <div className="container">
          {/* <div
            className={`menu-toggle ${open ? "open" : ""}`} //changeColor
            onClick={() => setOpen(!open)}
          >
            <span className="fa">
              <SpinnerLogo />
            </span>
          </div> */}

          <div className={`menu-line ${open ? "open" : ""}`}>
            {open && (

              <Box className="sidebarNav" sx={{
                width: '60px',
                position: 'fixed',
                left: '0',
                top: '0',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                textAlign: 'center',
                backgroundColor: "grey.0",
                // pt: '70px',
                zIndex: 2,
                borderRight: "1px solid",
                borderColor: 'grey.300'
              }}
              >

                <Link
                  key={"app"}
                  underline="none"
                  sx={{
                    borderLeft: "2px solid",
                    borderColor: 'transparent',
                    pt: '10px',
                    pb: '10px',
                    marginBottom: '-7px'
                  }}
                >
                  <Box>
                    <IconButton
                      sx={{
                        color: "grey.300",
                        '&:hover': {
                          backgroundColor: 'transparent !important'
                        },
                        mb: '-3px'
                      }}>
                      <SvgIcon sx={iconSx}>
                        <AppLauncherIcon />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </Link>
                {/* <List > */}
                {homeSideBar.map(({ title, icon, active, path }, index) => (
                  <Link key={index} onClick={() => handleRedirect(path, "", {}, false, mode)} underline="none" sx={{
                    // py: "4px"
                    borderLeft: active ? "2px solid" : "none",
                    borderColor: 'primary.light'
                  }}>
                    {/* <img src={icon} alt="" style={{width: '24px'}} /> */}
                    <Box >

                      <IconButton
                        // style={{
                        //   filter: active ? "invert(26%) sepia(34%) saturate(5285%) hue-rotate(220deg) brightness(86%) contrast(95%)" : "none"
                        // }}
                        sx={{
                          // color: active ? 'rgb(63, 80, 181)' : "grey.300",
                          '&:hover': {
                            backgroundColor: 'transparent !important'
                          },
                          mb: '-3px'
                        }}
                      >
                        {icon}
                      </IconButton>
                      <Typography
                        sx={{
                          fontSize: '10px',
                          fontWeight: 500,
                          color: active ? "grey.1000" : 'grey.700'
                        }}
                      >{title}</Typography>
                    </Box>
                  </Link>
                ))}

                {/* {!!(allowedOrigin.includes("more") && moreMenuShow) && <ListItem
                  key={"More"}
                  disablePadding
                  sx={{
                    display: "block",
                    borderRadius: "10px",
                  }}
                  // onClick={() => handleRedirect("/", "", {}, true, mode)}
                  onClick={(e) => handleClickMore(e)}
                  aria-controls={openMore ? "more-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMore ? "true" : undefined}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "center",
                      flexDirection: "column",
                      px: 1,
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        // color: "grey.100",
                        backgroundColor: "transparent",
                        width: "44px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "15px",
                        '&:hover': {
                          backgroundColor: 'transparent'
                        }
                      }}
                    >
                      {/* <MoreVertIcon sx={iconSx} /> */}
                {/* <SvgIcon sx={iconSx}>
                        <MoreIcon />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      primary={"More"}
                      primaryTypographyProps={{
                        fontSize: "10px",
                        fontWeight: 500,
                        color: "grey.0",
                      }}
                    />
                  </ListItemButton>
                </ListItem>} */}
                {/* </List> */}
                {/* <DrawerFooter >
                  <Box
                    onClick={(e) => handleClick(e)}
                    aria-controls={Boolean(anchorEl) ? "profile-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                  >

                    <Avatar
                      alt={tokenInfo?.name}
                      src={tokenInfo?.profileUrl || "/static/images/avatar/1.jpg"}
                      sx={{
                        color: "#F0F0F0",
                        width: '38px',
                        height: '38px',
                        bgcolor: '#2065D1',
                      }}
                    />

                  </Box>

                </DrawerFooter> */}

                {/* <ProfileMenu anchorEl={anchorEl} handleClose={handleClose} logout={logout} /> */}
                <MoreMenu
                  anchorEl={anchorElMore}
                  handleClose={handleCloseMore}
                  self={false}
                  products={products}
                // logout={logout}
                // handleTheme={() => colorMode.toggleColorMode()}
                />
              </Box>
            )}
          </div>
        </div >
        :
        <>
          <div className="container">
            <div
              className={`menu-toggle ${open ? "open" : ""}`} //changeColor
              onClick={() => view === "desktop" ? setOpen(!open) : ""}
              style={{ display: (view !== "desktop" && !open) ? 'none' : "" }}
            >
              <span className="fa" /* style={{ maxWidth: '40px' }} */>
                <SpinnerLogo />
              </span>
            </div>

            {/* {(view !== "desktop" && !open) && <Box
              sx={{
                position: 'fixed',
                top: '17px',
                left: '22px',
              }}
              className="menuBox"
            >
              <MenuIcon sx={{ color: 'grey.100' }} onClick={() => setOpen(!open)} />
            </Box>
            } */}

            <div className={`menu-line ${open ? "open" : ""}`}>
              {open && (
                <Drawer
                  variant="permanent"
                  PaperProps={{
                    sx: {
                      width: "68px",
                      backgroundColor: "card.main",
                      pt: '60px'
                    },
                  }}
                >
                  {/*  <DrawerHeader>
                    <CloseIcon sx={{ color: 'grey.100' }} onClick={() => setOpen(!open)} />
                  </DrawerHeader> */}

                  <List /* sx={{ mt: 1 }} */>
                    {homeSideBar.map(({ title, icon, active, path }) => (
                      <ListItem
                        key={title}
                        disablePadding
                        sx={{
                          display: "block",
                          borderRadius: "10px",
                          // height: "75px",
                        }}
                        onClick={() => handleRedirect(path, "", {}, false, mode)}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            height: "72px",
                            justifyContent: "center",
                            flexDirection: "column",
                            px: 1,
                            '&:hover': {
                              backgroundColor: 'transparent'
                            }
                          }}
                          disableTouchRipple
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              justifyContent: "center",
                              color: active ? "primary.main" : "grey.400",
                              backgroundColor: active ? activeBgColor : "transparent",
                              width: "44px",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "15px",
                              '&:hover': {
                                color: "primary.main",
                                backgroundColor: activeBgColor,
                                transition: 'all 0.6s ease-in-out',
                              }
                            }}
                          >
                            {icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={title}
                            primaryTypographyProps={{
                              fontSize: "12px",
                              fontWeight: 600,
                              lineHeight: "18px",
                              color: active ? "grey.100" : "grey.400",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}

                    {/* {!!(allowedOrigin.includes("more") && moreMenuShow) && <ListItem
                      key={"More"}
                      disablePadding
                      sx={{
                        display: "block",
                        borderRadius: "10px",
                      }}
                      // onClick={() => handleRedirect("/", "", {}, true, mode)}
                      onClick={(e) => handleClickMore(e)}
                      aria-controls={openMore ? "more-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMore ? "true" : undefined}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "center",
                          flexDirection: "column",
                          px: 1,
                          '&:hover': {
                            backgroundColor: 'transparent'
                          }
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: "center",
                            color: false ? "primary.main" : "grey.400",
                            backgroundColor: false ? activeBgColor : "transparent",
                            width: "44px",
                            height: "28px",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "15px",
                            '&:hover': {
                              color: "primary.main",
                              backgroundColor: activeBgColor,
                              transition: 'all 0.6s ease-in-out',
                            }
                          }}
                        >
                          <MoreHorizIcon sx={iconSx} />
                        </ListItemIcon>
                        <ListItemText
                          primary={"More"}
                          primaryTypographyProps={{
                            fontSize: "12px",
                            fontWeight: 600,
                            lineHeight: "18px",
                            color: false ? "grey.100" : "grey.400",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>} */}
                  </List>
                  {/* <DrawerFooter >
                    <Box
                      onClick={(e) => handleClick(e)}
                      aria-controls={Boolean(anchorEl) ? "profile-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                    >

                      <Avatar
                        alt={tokenInfo?.name}
                        src={tokenInfo?.profileUrl || "/static/images/avatar/1.jpg"}
                        sx={{
                          color: "#F0F0F0",
                          width: '38px',
                          height: '38px',
                          bgcolor: '#2065D1',
                        }}
                      />

                    </Box>

                  </DrawerFooter> */}

                  <ProfileMenu anchorEl={anchorEl} handleClose={handleClose} logout={logout} />
                  <MoreMenu
                    anchorEl={anchorElMore}
                    handleClose={handleCloseMore}
                    self={false}
                  // logout={logout}
                  // handleTheme={() => colorMode.toggleColorMode()}
                  />
                </Drawer>
              )}
            </div>
          </div>

          {/*  <Box sx={{
            width: '230px',
            backgroundColor: '#E7E8E8',
            height: 'calc(100vh)',
            gap: '30px',
            borderRadius: '0px 10px 10px 0px'
          }}>
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              pt: '15px',
              ml: '-20px',
            }}>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              pt: '50px',
              gap: '25px'
            }}>
              <Avatar
                alt={tokenInfo?.name}
                src={tokenInfo?.profileUrl || "/static/images/avatar/1.jpg"}
                sx={{
                  color: "grey.100",
                  width: '80px',
                  height: '80px',
                  fontSize: '40px',
                }}
              />

              <Typography sx={{
                fontSize: '20px',
                fontWeight: 600,
                color: 'grey.700'
              }}>
                {tokenInfo?.name}
              </Typography>
            </Box>

            {showSideBar && <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              p: '30px 10px 20px 0px',
              gap: '25px',
              width: '100%'
            }}>
              <List sx={{
                width: '100%'
              }}>
                {homeSideBar.map(({ title, icon, active, path }) => (
                  <ListItem
                    key={title}
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: "10px",
                      backgroundColor: active ? "grey.400" : "transparent",
                      // "&:hover": {
                      //   backgroundColor: "grey.600",
                      // }
                    }}
                    onClick={() => handleRedirect(path, "", {}, false, mode)}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "center",
                        flexDirection: "row",
                        px: 1,
                        gap: '15px'
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          justifyContent: "center",
                          color: active ? "#fff" : "#727374",
                          backgroundColor: "transparent",
                          width: "44px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "15px",
                        }}
                      >
                        {icon}
                      </ListItemIcon>

                      <ListItemText
                        primary={title}
                        primaryTypographyProps={{
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          color: active ? "#fff" : "#727374",
                        }}
                      />
                    </ListItemButton>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Divider sx={{ width: '90%' }} />
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>}

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%',
              position: 'absolute',
              bottom: '20px',
            }} onClick={() => logout()}>
              <IconButton sx={{ pl: '20px' }} disableFocusRipple disableRipple>
                <LogoutIcon />
              </IconButton>

              <Typography sx={{
                fontSize: '16px',
                fontWeight: 600,
                color: 'grey.600'
              }}>
                Log out
              </Typography>
            </Box>

          </Box> */}
        </>
      }
    </>
  );
};
