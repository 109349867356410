import React, { useState } from "react";
import { imagesUrl } from "../../../config/commonConfig";
import Grid from "@mui/material/Unstable_Grid2";
import SignUpForm from "../../../components/Auth/Signup";
import GsuiteRegistrationForm from "../../../components/Auth/Gsuite/GsuiteRegistration";
import TypeAccountForm from "../../../components/Auth/Gsuite/Type";
import LoadingModal from "../../../components/Loader/loading";

function SignUp() {
  const [currentStep, setCurrentStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [accountType, setAccountType] = useState(null);

  const nextStep = (selectedAccountType) => {
    setAccountType(selectedAccountType);
    setCurrentStep(2);
  };

  const prevStep = () => {
    setCurrentStep(1);
  };

  return (
    <>
      {loader && <LoadingModal open={true} />}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          background: {
            xs: "#fff",
            sm: `#0F478D 0px -64.059px / 100% 115.26% url(${imagesUrl?.authBackground})`,
          },
          backgroundBlendMode: "multiply",
          backgroundRepeat: "repeat",
        }}
      >
        {currentStep === 1 && (
          <TypeAccountForm handleLoader={setLoader} nextStep={nextStep} />
        )}
        {currentStep === 2 && accountType === "personal" && (
          <SignUpForm prevStep={prevStep} />
        )}
        {currentStep === 2 && accountType === "business" && (
          <GsuiteRegistrationForm prevStep={prevStep} />
        )}
      </Grid>
    </>
  );
}

export default SignUp;
