
// eslint-disable-next-line
const VoiceActiveIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0011 6.3H16.6011C16.6011 3.598 14.4031 1.4 11.7011 1.4C8.99906 1.4 6.80106 3.598 6.80106 6.3H5.40106C5.40106 2.826 8.22706 0 11.7011 0C15.1751 0 18.0011 2.826 18.0011 6.3Z" fill="#E56910" />
            <path d="M11.701 3C9.77105 3 8.20105 4.57 8.20105 6.5V9.8C8.20105 11.73 9.77105 13.3 11.701 13.3C13.631 13.3 15.201 11.73 15.201 9.8V6.5C15.201 4.57 13.631 3 11.701 3ZM13.801 9.8C13.801 10.958 12.859 11.9 11.701 11.9C10.543 11.9 9.60105 10.958 9.60105 9.8V6.5C9.60105 5.342 10.543 4.4 11.701 4.4C12.859 4.4 13.801 5.342 13.801 6.5V9.8Z" fill="#E56910" />
            <path d="M5.40103 10H6.80103C6.80103 12.702 8.99903 14.9 11.701 14.9C14.403 14.9 16.601 12.702 16.601 10H18.001C18.001 13.474 15.175 16.3 11.701 16.3C8.22703 16.3 5.40103 13.474 5.40103 10Z" fill="#0C66E4" />
            <path d="M12.401 20.827V22.154C13.635 22.344 14.707 23.029 15.408 24H13.408C13.104 23.805 12.764 23.66 12.401 23.578C12.176 23.527 11.941 23.5 11.701 23.5C11.461 23.5 11.226 23.527 11.001 23.578C10.638 23.66 10.298 23.805 9.99404 24H7.99404C8.69504 23.029 9.76704 22.344 11.001 22.154V20.827C5.42604 20.466 1.00104 15.815 1.00104 10.15H2.40104C2.40104 15.278 6.57304 19.45 11.701 19.45C16.829 19.45 21.001 15.278 21.001 10.15H22.401C22.401 15.815 17.976 20.466 12.401 20.827Z" fill="#0C66E4" />
        </svg>

    )
}

export default VoiceActiveIcon