import { getItem } from "../../lib/cookies";
import { axiosGetCall } from "../../services/apisCall";




const initialState = {
    workspace: getItem('workspace') || "",
    workspaceDetails: {}
};

const workspaceReducer = (state = initialState, action) => {
    switch (action.type) {

        case "UPDATE_WORKSPACE":
            const workspace = action.payload?.workspace;
            return {workspace,  workspaceDetails: action.payload?.workspaceDetails || {}, isDomainRegistered: action.payload?.workspaceDetails?.isDomainRegistered };

        default:
            return state;
    }
};

export default workspaceReducer;

