import React from 'react';
import { Modal, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const MainSection = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    backgroundColor: theme.palette.grey[900],
    padding: '30px 30px 40px 30px',
    '&:focus-visible': {
        outline: 'none'
    },
    borderRadius: "12px",
    zIndex: 9999,
}));

const CustomModal = ({ open, title, description = "", close, action, isCloseIcon = true, backgroundColor = 'grey.100' }) => {

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(20, 22, 24, 0.90)',
                    },
                },
            }}
            sx={{ zIndex: 1300 }}
        >
            <MainSection sx={{ backgroundColor }}>
                {isCloseIcon && <IconButton
                    id="modal-close-icon"
                    onClick={close}
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        justifyContent: 'right',
                        alignItems: 'right',
                        right: 15,
                        top: 10,
                        color: 'grey.700'
                    }}>
                    <CloseIcon />
                </IconButton>
                }
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: 'grey.700' }}>
                    {title}
                </Typography>

                {description}

                {action}
            </MainSection>
        </Modal>
    )
}

export default CustomModal;
