import { combineReducers } from '@reduxjs/toolkit';
import dashboardReducer from "./dashboardReducer";
import walletReducer from "./walletReducer";
import workspaceReducer from './workspaceReducer';

const rootReducer = combineReducers({
    dashboard: dashboardReducer,
    wallet: walletReducer,
    workspace: workspaceReducer
});

export default rootReducer;