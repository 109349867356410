import { Icon } from "@iconify/react";
import { Button, styled, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicSearch from "../../components/search/basicSearch";
import FilterMenu from "./filterMenu";
import HierarchyModal from "./hierarchy";
import { checkPermission } from "../../utils/common";

const MainSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    gap: "10px",
  },
}));

const UserFilters = ({
  findForSearchQuery,
  showFilterLabels,
  type,
  workspaceDetails,
  apply,
  reset,
  search: newSearch,
  isFilterApplied,
  filterData,
  setFilterData,
}) => {
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const navigate = useNavigate();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      findForSearchQuery(search);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openHierarchyModal, setOpenHierarchyModal] = useState(false);

  useEffect(() => {
    setSearch(newSearch);
  }, [newSearch]);

  return (
    <MainSection>
      <BasicSearch
        text="Search users"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={handleKeyPress}
      />

      <Button
        variant="contained"
        size="small"
        sx={{
          boxShadow: "none",
        }}
        onClick={() => findForSearchQuery(search)}
      >
        Search
      </Button>

      <Button
        variant="outlined"
        size="small"
        sx={{
          boxShadow: "none",
        }}
        id="filter-button"
        aria-controls={open ? "filter-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        filters
      </Button>

      {workspaceDetails?.isDomainRegistered == false && type === "employee" ? (
        ""
      ) : (
        <Button
          variant="outlined"
          size="small"
          startIcon={<Icon icon="ph:user-plus-light" />}
          sx={{
            boxShadow: "none",
          }}
          onClick={() =>
            navigate(
              type === "employee" ? "/employee/createUser" : "/user/createUser"
            )
          }
        >
          Create new User
        </Button>
      )}

      {checkPermission() === "l4" && (
        <Button
          variant="outlined"
          size="small"
          startIcon={
            <Icon
              icon="tabler:hierarchy-3"
              width={"15px"}
              style={{ color: theme.palette.primary.main }}
            />
          }
          sx={{
            boxShadow: "none",
          }}
          onClick={() => setOpenHierarchyModal(true)}
        >
          View Hierarchy
        </Button>
      )}
      <HierarchyModal
        openHierarchy={openHierarchyModal}
        handleClose={() => setOpenHierarchyModal(false)}
        setOpenHierarchy={setOpenHierarchyModal}
        self={true}
      />

      <FilterMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        reset={reset}
        apply={apply}
        isFilterApplied={isFilterApplied}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </MainSection>
  );
};

export default UserFilters;
