import React, { useState, useRef } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KycFileInput from '../../components/Dashboard/KycInput/KycFileInput';
import { validateUploadFile } from '../../utils/kycCommon';
import ErrorMessage from "../../utils/ErrorMessage.json"
import { renderErrorMessage } from '../../lib/toastMessage';
import ShowNote from './showNote';
import { axiosPostCall } from '../../services/apisCall';
import Logger from '../../lib/Logger';
import { LoadingButton } from '@mui/lab';
const logger = new Logger("certification");

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const Certification = ({ current, next, formData }) => {

    const boardresolutionRef = useRef();
    const incorporationRef = useRef();
    const [boardresolutionFile, setBoardResolutionFile] = useState()
    const [incorporationFile, setIncorporationFile] = useState()
    const [loader, setLoader] = useState(false);

    const InActiveNextBtn = {
        backgroundColor: '#727374',
        color: "#B8B9BA",
        '&:hover': {
            backgroundColor: '#727374',
            color: "#B8B9BA",
        }
    }

    const handleSubmit = async () => {
        try {
            const files = [incorporationFile, boardresolutionFile]
            const form = new FormData();

            let fileError = false
            files.filter((file) => file !== undefined).forEach(file => {
                if (!validateUploadFile(file)) {
                    fileError = true
                }
            });
            if (fileError) return renderErrorMessage(ErrorMessage?.[4013])

            form.append("incorporationCertificate", incorporationFile)
            form.append("boardResolution", boardresolutionFile)
            form.append("currentStep", "certification");
            form.append("nextStep", "address");
            setLoader(true);
            await axiosPostCall("uploadKyc", form);
            setLoader(false);
            current(false)
            next(true)
        } catch (error) {
            setLoader(false);
            logger.error(`API Error -  ${JSON.stringify(error)}`);
        }
    }

    return (
        <MainSection>
            <Typography sx={{
                color: 'grey.600',
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '40px'
            }}>
                Upload Certifications
            </Typography>

            <KycFileInput InputName="Board resolution" inputId="Boardresolution" inputRef={boardresolutionRef} setFile={setBoardResolutionFile} />

            <KycFileInput InputName="Company incorporation certification" inputId="Company incorporation" inputRef={incorporationRef} setFile={setIncorporationFile} />

            <ShowNote />

            <LoadingButton
                variant='contained'
                loading={loader}
                endIcon={<ArrowForwardIcon />}
                sx={{
                    "&.Mui-disabled": InActiveNextBtn,
                    boxShadow: 'none'
                }}
                onClick={handleSubmit}
                disabled={boardresolutionFile === undefined || incorporationFile === undefined}
            >
                Save & Next
            </LoadingButton>
        </MainSection>
    )
}

Certification.propTypes = {
    current: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
}
export default Certification;
