// DomainVerification.js
import React, { useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    List,
    ListItem,
    InputAdornment,
    IconButton,
    OutlinedInput
} from '@mui/material';
import CustomModal from '../../../components/modal/customModal';
import Logger from '../../../lib/Logger';
import { copyToClipBoard } from '../../../utils/common';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { renderSuccessMessage } from '../../../lib/toastMessage';

const logger = new Logger("DomainVerification")

const DomainVerification = ({ onProtect, onAddUsers, data, generateVerificationTxt, verifyTxtRecord }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleVerification = async () => {
        try {

            const { currentStep } = data;
            if (currentStep === "Generate TXT Record" && !data?.innerSteps[0]?.completed) {
                await generateVerificationTxt();
                handleOpenModal();
            } else {
                handleOpenModal();
            }

        } catch (error) {
            logger.error("DomainVerification fn: [generateVerificationTxt] error:", error)
        }
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleProtectDomain = async () => {
        // onProtect(); // Proceed to the next step
        // handleCloseModal(); // Close the modal

        try {
            await verifyTxtRecord();

        } catch (error) {
            logger.error("DomainVerification fn: [handleProtectDomain] error:", error)
        }
    };

    const copyDetails = (data) => {
        copyToClipBoard(data);
        return renderSuccessMessage("Copied to clipboard")
    }

    const description = (

        <>
            <Typography sx={{ mt: 2, }}>
                TXT verification (recommended)
            </Typography>

            <Box sx={{ mt: 2 }}>
                <OutlinedInput
                    fullWidth
                    label="Host record"
                    id="outlined-adornment-password"
                    value={data?.details?.hostRecord}
                    disabled
                    sx={{
                        mb: 2,
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: (theme) => theme.palette.grey[700],
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="copy"
                                onClick={() => copyDetails(data?.details?.hostRecord)}
                                edge="end"
                            >
                                {/* <Iconify Icon="mingcute:copy-fill" /> */}
                                <ContentCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <OutlinedInput
                    fullWidth
                    label="TXT Value"
                    value={data?.details?.key}
                    disabled
                    sx={{
                        mb: 2,
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: (theme) => theme.palette.grey[700],
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="copy"
                                onClick={() => copyDetails(data?.details?.key)}
                                edge="end"
                            >
                                {/* <Iconify Icon="mingcute:copy-fill" /> */}
                                <ContentCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <OutlinedInput
                    fullWidth
                    label="TTL"
                    value={data?.details?.ttl}
                    disabled
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: (theme) => theme.palette.grey[700],
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="copy"
                                onClick={() => copyDetails(data?.details?.ttl)}
                                edge="end"
                            >
                                {/* <Iconify Icon="mingcute:copy-fill" /> */}
                                <ContentCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <Typography sx={{ mt: 2 }}>
                    Please add this information to your DNS settings and click "Protect Domain" once done.
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={handleProtectDomain}
                        disabled={data?.completed}
                    >
                        Verify Domain
                    </Button>
                </Box>

            </Box>
        </>

    )

    return (
        <Box sx={{ mt: 4, width: '75%', margin: 'auto', border: '1px solid', borderColor: 'grey.300', borderRadius: '6px', padding: '22px 25px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Welcome, let's set up your workspace
                </Typography>
                {/* <Box sx={{ width: '20%' }}>
                    <LinearProgress variant="determinate" value={0} />
                </Box> 
                <Typography variant="body2" sx={{ ml: 2 }}>0%</Typography>
                */}
            </Box>

            <Typography variant="body2" sx={{ mb: 3 }}>
                In just a few steps, you'll be set up with email and other workspace services.
            </Typography>

            <List>
                <ListItem sx={{ mb: 2 }}>
                    <Card sx={{ width: '100%', border: '1px solid', borderColor: 'grey.300', }}>
                        <CardContent>
                            <Typography variant="subtitle1">
                                1. Protect your domain
                            </Typography>
                            <Typography variant="body2">
                                Show that you own this domain name. Estimated time: 5 minutes.
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <Button variant='outlined' color="primary" onClick={handleVerification}>
                                Verify
                            </Button>
                        </CardActions>
                    </Card>
                </ListItem>
                {/* <ListItem>
                    <Card sx={{ width: '100%', border: '1px solid', borderColor: 'grey.300', }}>
                        <CardContent>
                            <Typography variant="subtitle1">
                                2. Create new users
                            </Typography>
                            <Typography variant="body2">
                                Add new users for your team. Estimated time: 5 minutes.
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <Button variant='outlined' color="primary" onClick={onAddUsers}>
                                Add Users
                            </Button>
                        </CardActions>
                    </Card>
                </ListItem> */}
            </List>

            {/* Modal for TXT Record Verification */}
            <CustomModal
                open={isModalOpen}
                close={handleCloseModal}
                description={description}
                title="Add Verification Record"
            // aria-labelledby="modal-title"
            // aria-describedby="modal-description"
            />


        </Box>
    );
};

export default DomainVerification;
