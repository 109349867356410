
import React, { useEffect, useState } from 'react'
import { Button, styled } from '@mui/material'

import FilterMenu from './filterMenu';
import BasicSearch from '../../components/search/basicSearch';

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        gap: "10px"
    },
}));

const InvoiceFilter = ({ findForSearchQuery, showFilterLabels, apply, reset, search: newSearch, isFilterApplied, filterData, setFilterData, fetchTransActionsList }) => {
    const [search, setSearch] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            findForSearchQuery(search)
        }
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setSearch(newSearch)
    }, [newSearch])

    return (
        <MainSection>

            <BasicSearch
                text="Search Transactions..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyPress}
            />

            <Button
                variant='contained'
                size='small'
                sx={{
                    boxShadow: 'none'
                }}
                onClick={() => findForSearchQuery(search)}
            >
                Search
            </Button>

            <Button
                variant='outlined'
                size='small'
                sx={{
                    boxShadow: 'none'
                }}
                id="filter-button"
                aria-controls={open ? 'filter-button' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                filters
            </Button>

            <FilterMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                reset={reset}
                apply={apply}
                isFilterApplied={isFilterApplied}
                filterData={filterData}
                setFilterData={setFilterData}
            />


        </MainSection>
    )
}

export default InvoiceFilter;