import React from 'react';

const AmSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 87 87" fill="none">
        <g clipPath="url(#clip0_2655_18466)">
            <path d="M87 43.5465C87.0199 54.5779 82.8281 65.2009 75.2806 73.2463C75.1873 73.3582 75.0939 73.4609 74.9913 73.5635L74.5901 73.9741C70.5424 78.1167 65.7061 81.4069 60.3664 83.6504C55.0267 85.894 49.2919 87.0455 43.5 87.0371C41.6285 87.0365 39.7589 86.9212 37.9015 86.6919C33.5027 86.1036 29.22 84.8449 25.2024 82.9596L24.2693 82.493C17.8293 79.3133 12.2771 74.5881 8.10843 68.7395C7.75386 68.2356 7.39929 67.7318 7.06338 67.2093C6.22488 65.9409 5.45854 64.6263 4.76801 63.2717C1.77197 57.4112 0.144872 50.9473 0.00929195 44.3668C-0.126288 37.7863 1.23318 31.2608 3.9853 25.2819C6.73742 19.303 10.8104 14.0265 15.8975 9.85005C20.9846 5.67356 26.9531 2.70591 33.3534 1.17064C39.7538 -0.364631 46.4191 -0.427486 52.8472 0.986812C59.2754 2.40111 65.2988 5.25568 70.4637 9.3355C75.6286 13.4153 79.8004 18.614 82.6648 24.5399C85.5292 30.4659 87.0115 36.9646 87 43.5465Z" fill="#E1E7EF" />
            <path d="M75.2806 73.1996C75.1873 73.3116 75.094 73.4143 74.9913 73.5169L74.5901 73.9274C70.5424 78.0701 65.7061 81.3603 60.3664 83.6038C55.0268 85.8474 49.2919 86.9989 43.5 86.9905C41.6286 86.9899 39.7589 86.8746 37.9016 86.6453C33.5027 86.057 29.22 84.7983 25.2024 82.913C25.5756 67.1533 25.417 53.3624 25.417 53.1012C25.5663 52.7746 32.8816 50.8991 42.5763 50.5819C57.8321 50.1433 70.7458 58.765 75.2806 73.1996Z" fill="#68B6D8" />
            <path d="M37.9202 86.6547C33.5214 86.0664 29.2386 84.8077 25.221 82.9224L24.2879 82.4559C17.8454 79.2892 12.2869 74.577 8.1084 68.7397C16.5061 57.2535 23.3362 53.9038 25.389 53.1107C25.5961 53.0243 25.808 52.9495 26.0235 52.8867L37.9202 86.6547Z" fill="#68B6D8" />
            <path d="M44.4984 25.3051C44.4984 25.3051 54.361 26.0236 55.1635 16.5622C56.0125 6.49432 46.7005 5.29065 42.6322 9.26555C38.564 13.2405 39.1985 17.4206 39.1985 17.4206C39.1985 17.4206 36.5019 14.864 33.4041 17.8499C31.1367 20.0333 32.1725 23.7282 32.1725 23.7282C32.1725 23.7282 27.0779 23.4017 27.4418 27.8618C27.5445 29.17 27.9059 30.4449 28.5049 31.6126C29.1039 32.7802 29.9287 33.8173 30.9315 34.6639L44.4984 25.3051Z" fill="black" />
            <path d="M35.0276 42.5298L35.7928 53.0549C35.7928 53.0549 37.9109 55.9381 44.0971 55.9941C48.7625 55.9941 50.2461 54.8744 50.2461 54.8744L49.2197 49.5932L48.212 44.4799L36.9218 42.8004L35.0276 42.5298Z" fill="#F48462" />
            <path d="M36.9218 42.8003C36.9944 43.1516 37.1236 43.4888 37.3044 43.7987C40.0383 48.8839 46.5605 49.5464 49.2198 49.5931L48.212 44.4798L36.9218 42.8003Z" fill="black" />
            <path d="M48.8185 20.2851C48.8185 20.2851 55.3501 33.0775 53.6612 40.5701C51.9723 48.0627 45.2355 47.2789 41.13 46.0846C38.8128 45.4709 36.7044 44.2426 35.0277 42.5296C33.9014 42.9304 32.6775 42.9658 31.5299 42.6307C30.3824 42.2957 29.3698 41.6073 28.6361 40.6634C25.9209 37.323 27.2365 33.1988 29.3359 32.1818C31.4353 31.1647 34.0573 32.5457 34.0573 32.5457L31.8459 28.8134C31.8459 28.8134 39.1425 9.02283 48.8185 20.2851Z" fill="#F48462" />
            <path d="M34.0386 38.3028C33.9535 38.3018 33.8701 38.2793 33.796 38.2374L29.7091 35.7088C29.6565 35.6781 29.6106 35.6371 29.574 35.5884C29.5375 35.5396 29.511 35.4841 29.4963 35.4249C29.4815 35.3658 29.4787 35.3044 29.488 35.2441C29.4973 35.1839 29.5186 35.1262 29.5505 35.0743C29.6161 34.9693 29.72 34.894 29.8402 34.8644C29.9604 34.8348 30.0875 34.8532 30.1943 34.9157L34.2999 37.435C34.3889 37.4877 34.4579 37.5685 34.496 37.6648C34.534 37.761 34.539 37.8671 34.5102 37.9665C34.4813 38.0659 34.4203 38.1528 34.3366 38.2137C34.2529 38.2746 34.1514 38.3059 34.0479 38.3028H34.0386Z" fill="black" />
            <path d="M30.6422 38.5825C30.5552 38.5806 30.4704 38.5554 30.3965 38.5094C30.3226 38.4634 30.2625 38.3985 30.2223 38.3213C30.1953 38.2661 30.1793 38.2062 30.1755 38.1449C30.1716 38.0837 30.1799 38.0222 30.1998 37.9641C30.2198 37.906 30.251 37.8525 30.2917 37.8065C30.3324 37.7605 30.3817 37.723 30.4369 37.6961L32.583 36.6417C32.6382 36.6148 32.6981 36.5989 32.7593 36.5951C32.8206 36.5913 32.882 36.5996 32.94 36.6196C32.9981 36.6395 33.0517 36.6707 33.0977 36.7113C33.1437 36.7519 33.1812 36.8012 33.2082 36.8563C33.2351 36.9115 33.251 36.9714 33.2548 37.0326C33.2586 37.0939 33.2503 37.1553 33.2304 37.2134C33.2104 37.2714 33.1793 37.325 33.1386 37.371C33.098 37.417 33.0487 37.4545 32.9936 37.4815L30.8475 38.5359C30.7831 38.5657 30.7132 38.5816 30.6422 38.5825Z" fill="black" />
            <path d="M50.5541 25.2863C50.4574 25.2871 50.3628 25.2577 50.2835 25.2024C49.9576 24.9692 49.5696 24.8384 49.1691 24.8268C48.7685 24.8151 48.3736 24.9232 48.0348 25.137C47.9305 25.2051 47.8036 25.2295 47.6815 25.2051C47.5594 25.1806 47.4518 25.1093 47.3816 25.0064C47.3132 24.9047 47.2876 24.7801 47.3103 24.6596C47.333 24.5391 47.4022 24.4324 47.5029 24.3626C48.0048 24.0341 48.5944 23.8653 49.194 23.8787C49.7937 23.892 50.3752 24.0867 50.862 24.4372C50.9437 24.4942 51.0049 24.5759 51.0369 24.6702C51.0688 24.7645 51.0697 24.8666 51.0395 24.9615C51.0093 25.0564 50.9495 25.1391 50.8688 25.1975C50.7882 25.256 50.691 25.2871 50.5914 25.2863H50.5541Z" fill="black" />
            <path d="M48.8372 37.2019H48.7439C48.6837 37.1887 48.6267 37.1637 48.5763 37.1283C48.5258 37.0929 48.4829 37.0479 48.4499 36.9959C48.417 36.9438 48.3946 36.8858 48.3842 36.825C48.3738 36.7643 48.3755 36.7021 48.3893 36.6421C48.6477 35.5184 48.658 34.352 48.4195 33.2239C48.181 32.0959 47.6995 31.0334 47.0084 30.1105C46.9267 30.0165 46.8857 29.8939 46.8945 29.7696C46.9032 29.6454 46.961 29.5297 47.055 29.448C47.149 29.3664 47.2717 29.3254 47.3959 29.3342C47.5201 29.3429 47.6358 29.4007 47.7175 29.4947C48.5088 30.525 49.0609 31.7185 49.3337 32.9886C49.6064 34.2588 49.593 35.5737 49.2944 36.838C49.271 36.9417 49.213 37.0342 49.1298 37.1004C49.0467 37.1666 48.9434 37.2024 48.8372 37.2019Z" fill="black" />
            <path d="M50.4048 31.4821C50.344 31.4834 50.2835 31.4724 50.2269 31.45C50.1704 31.4276 50.1189 31.3941 50.0754 31.3515C50.032 31.309 49.9975 31.2581 49.9739 31.2021C49.9504 31.146 49.9382 31.0857 49.9382 31.0249L49.9009 28.5149C49.8997 28.4537 49.9105 28.3927 49.9328 28.3357C49.9551 28.2786 49.9885 28.2265 50.0309 28.1823C50.0734 28.1381 50.1241 28.1027 50.1803 28.0781C50.2364 28.0535 50.2968 28.0403 50.3581 28.0391C50.4798 28.0433 50.5957 28.0923 50.6835 28.1767C50.7713 28.261 50.8249 28.3748 50.834 28.4963V31.0062C50.8341 31.1293 50.7865 31.2476 50.7013 31.3363C50.616 31.425 50.4997 31.4773 50.3768 31.4821H50.4048Z" fill="black" />
            <path d="M43.4907 31.967C43.3693 31.9647 43.2533 31.9161 43.1666 31.8311C43.0798 31.7461 43.0289 31.6311 43.0241 31.5098V28.9998C43.0229 28.9385 43.0338 28.8776 43.0561 28.8205C43.0784 28.7635 43.1117 28.7113 43.1542 28.6671C43.1966 28.623 43.2474 28.5876 43.3035 28.563C43.3597 28.5384 43.4201 28.5251 43.4814 28.5239C43.6044 28.5238 43.7227 28.5714 43.8114 28.6566C43.9001 28.7419 43.9524 28.8582 43.9572 28.9811V31.4911C43.9597 31.5527 43.9498 31.6142 43.9279 31.6718C43.9061 31.7295 43.8728 31.7821 43.8301 31.8265C43.7874 31.871 43.7361 31.9063 43.6794 31.9305C43.6227 31.9546 43.5617 31.967 43.5 31.967H43.4907Z" fill="black" />
            <path d="M50.1435 23.1776C49.7033 22.5586 49.1122 22.0623 48.4264 21.7357C47.7405 21.4091 46.9826 21.2631 46.2246 21.3115C45.4419 21.3715 44.68 21.5922 43.9865 21.9598C43.2929 22.3275 42.6826 22.8342 42.1937 23.4482C42.1937 23.4482 43.6679 27.787 40.2715 30.3903C36.8752 32.9936 34.0386 32.5457 34.0386 32.5457L31.3887 31.8086L29.2146 28.1603L36.1194 19.548L45.4501 13.7256L50.7127 17.8498L50.1435 23.1776Z" fill="black" />
            <path d="M46.7005 39.8143C45.9895 39.8367 45.2859 39.6643 44.6659 39.3156C44.0458 38.9669 43.533 38.4554 43.1828 37.8362C43.1221 37.7273 43.1072 37.5988 43.1414 37.4789C43.1755 37.3591 43.2558 37.2577 43.3647 37.197C43.4736 37.1364 43.6021 37.1215 43.722 37.1557C43.8418 37.1898 43.9432 37.2701 44.0039 37.379C44.3146 37.9069 44.7745 38.3311 45.3257 38.5984C45.8769 38.8657 46.4948 38.9641 47.1017 38.8813C47.2239 38.8622 47.3488 38.8914 47.4498 38.9628C47.5509 39.0342 47.6201 39.1422 47.6429 39.2638C47.6618 39.385 47.6322 39.5087 47.5607 39.6082C47.4891 39.7077 47.3812 39.7751 47.2603 39.7957C47.0747 39.82 46.8872 39.8263 46.7005 39.8143Z" fill="black" />
            <path d="M53.1667 35.3357H53.0547C52.4593 35.3214 51.8729 35.1867 51.3309 34.9398C50.7889 34.6928 50.3024 34.3387 49.9009 33.8988C49.0799 33.0021 48.6413 31.8203 48.6786 30.605C48.6933 30.0168 48.8237 29.4373 49.0624 28.8996C49.301 28.3618 49.6433 27.8763 50.0696 27.4708C50.4959 27.0653 50.9978 26.7478 51.5468 26.5363C52.0959 26.3248 52.6812 26.2235 53.2693 26.2382C53.8575 26.2529 54.437 26.3833 54.9747 26.622C55.5125 26.8607 55.998 27.2029 56.4035 27.6292C56.809 28.0555 57.1265 28.5575 57.338 29.1065C57.5495 29.6555 57.6508 30.2408 57.6361 30.829C57.6113 32.0452 57.1125 33.2037 56.2458 34.0574C55.4266 34.8705 54.3209 35.3296 53.1667 35.3357ZM53.1667 27.0313C52.2243 27.0507 51.3267 27.4369 50.6646 28.1077C50.0025 28.7785 49.6281 29.6811 49.621 30.6237C49.5878 31.6057 49.9432 32.5613 50.6101 33.2829C50.9239 33.6299 51.3048 33.9097 51.7298 34.1054C52.1548 34.301 52.615 34.4085 53.0827 34.4213C53.5515 34.435 54.0181 34.3518 54.4532 34.1767C54.8883 34.0017 55.2826 33.7387 55.6113 33.4042C56.3044 32.7205 56.7028 31.793 56.7217 30.8196C56.7497 29.8693 56.4037 28.946 55.7581 28.248C55.1126 27.5501 54.2189 27.1333 53.2693 27.0873L53.1667 27.0313Z" fill="white" />
            <path d="M42.5203 36.0819H42.4363C41.2039 36.0671 40.0274 35.5652 39.1638 34.6859C38.3002 33.8066 37.8196 32.6213 37.827 31.3889C37.8344 30.1564 38.3292 28.9769 39.2033 28.108C40.0774 27.2392 41.2598 26.7515 42.4923 26.7515C43.7248 26.7515 44.9072 27.2392 45.7813 28.108C46.6554 28.9769 47.1502 30.1564 47.1576 31.3889C47.165 32.6213 46.6844 33.8066 45.8208 34.6859C44.9571 35.5652 43.7807 36.0671 42.5483 36.0819H42.5203ZM42.5203 27.7868C41.5593 27.7992 40.6422 28.1912 39.9692 28.8772C39.2961 29.5633 38.9219 30.4878 38.928 31.4489C38.9342 32.4099 39.3203 33.3295 40.002 34.0069C40.6838 34.6843 41.6058 35.0645 42.5669 35.0645C43.528 35.0645 44.4501 34.6843 45.1318 34.0069C45.8136 33.3295 46.1997 32.4099 46.2059 31.4489C46.212 30.4878 45.8377 29.5633 45.1647 28.8772C44.4917 28.1912 43.5746 27.7992 42.6136 27.7868H42.5203Z" fill="white" />
            <path d="M46.2339 31.5566C46.1102 31.5566 45.9915 31.5075 45.904 31.42C45.8165 31.3325 45.7674 31.2138 45.7674 31.0901C45.7674 30.9663 45.8165 30.8477 45.904 30.7602C45.9915 30.6727 46.1102 30.6235 46.2339 30.6235L49.481 30.4369C49.5423 30.4331 49.6038 30.4415 49.6618 30.4615C49.7199 30.4815 49.7734 30.5128 49.8194 30.5535C49.8653 30.5943 49.9028 30.6437 49.9296 30.6989C49.9564 30.7542 49.972 30.8142 49.9756 30.8755C49.9808 30.9987 49.938 31.1191 49.8562 31.2113C49.7744 31.3035 49.6599 31.3604 49.537 31.37L46.2806 31.5566H46.2339Z" fill="white" />
            <path d="M38.3587 32.4522C38.2806 32.4526 38.2036 32.4333 38.1348 32.3962C38.1348 32.3962 33.7773 30.0728 31.7339 32.1349C31.6905 32.1787 31.6389 32.2134 31.5821 32.2371C31.5252 32.2607 31.4642 32.2729 31.4026 32.2729C31.3411 32.2729 31.2801 32.2607 31.2232 32.2371C31.1664 32.2134 31.1148 32.1787 31.0714 32.1349C30.9853 32.0461 30.9372 31.9274 30.9372 31.8037C30.9372 31.68 30.9853 31.5612 31.0714 31.4724C33.6094 28.9065 38.3774 31.4725 38.536 31.5658C38.59 31.5951 38.6377 31.6347 38.6764 31.6825C38.715 31.7303 38.7438 31.7853 38.7612 31.8442C38.7785 31.9032 38.784 31.965 38.7774 32.0261C38.7708 32.0872 38.7522 32.1464 38.7226 32.2002C38.6863 32.2681 38.6344 32.3263 38.5711 32.3701C38.5078 32.4139 38.435 32.4421 38.3587 32.4522Z" fill="white" />
            <path d="M44.5077 56.675C41.0054 56.6118 37.6062 55.4787 34.7664 53.4279C34.6635 53.3578 34.5922 53.2501 34.5678 53.128C34.5434 53.0059 34.5678 52.8791 34.6358 52.7748C34.7088 52.6753 34.817 52.6074 34.9384 52.5848C35.0597 52.5623 35.1851 52.5868 35.2889 52.6535C43.7986 58.4385 50.2182 54.4636 50.4701 54.2864C50.5741 54.2197 50.7002 54.197 50.8209 54.2233C50.9416 54.2495 51.047 54.3225 51.1139 54.4263C51.1482 54.4774 51.1717 54.5349 51.1833 54.5953C51.1948 54.6558 51.194 54.7179 51.1809 54.778C51.1678 54.8381 51.1428 54.895 51.1072 54.9452C51.0716 54.9954 51.0263 55.0379 50.9739 55.0701C48.9967 56.1631 46.7666 56.7166 44.5077 56.675Z" fill="white" />
            <path d="M40.3742 26.518C40.2844 26.5207 40.1961 26.4946 40.1223 26.4434C40.0706 26.4103 40.0262 26.3673 39.9915 26.3167C39.9569 26.2662 39.9328 26.2092 39.9206 26.1491C39.9084 26.0891 39.9084 26.0272 39.9206 25.9671C39.9328 25.9071 39.957 25.8501 39.9916 25.7996C40.3936 25.1096 41.0319 24.5886 41.7884 24.333C42.5449 24.0774 43.3684 24.1045 44.1065 24.4093C44.1661 24.4318 44.2204 24.4663 44.2661 24.5106C44.3119 24.5549 44.348 24.6082 44.3724 24.667C44.3968 24.7258 44.4089 24.789 44.4079 24.8527C44.407 24.9164 44.393 24.9792 44.3668 25.0373C44.3406 25.0953 44.3029 25.1474 44.2558 25.1904C44.2088 25.2333 44.1534 25.2661 44.0932 25.2868C44.033 25.3075 43.9691 25.3157 43.9056 25.3108C43.8421 25.3059 43.7803 25.2881 43.7239 25.2584C43.1867 25.047 42.5912 25.0374 42.0474 25.2312C41.5037 25.4251 41.0485 25.8092 40.7661 26.3127C40.7223 26.3761 40.6637 26.4279 40.5955 26.4637C40.5272 26.4994 40.4513 26.5181 40.3742 26.518Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_2655_18466">
                <rect width="87" height="87" fill="white" />
            </clipPath>
        </defs>
    </svg>
)


export default AmSvg