import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MailVolume from '../../assets/svgs/mailVolume';
import { Typography, Box, LinearProgress, Divider } from '@mui/material';
import { axiosGetCall } from '../../services/apisCall';
import moment from 'moment'
import Logger from '../../lib/Logger';
import NetworkError from '../../components/Error';
import commonMessage from '../../utils/commonMessage.json';
import { handleRedirect } from '../../utils/common';
import { useTheme } from '@mui/material';

const logger = new Logger("smsSection")

const CardsMainSection = styled('div')(({ theme }) => ({
    height: '100%',
    padding: '8px 15px',
    width: 'calc(100% / 2 - 7px)',
    borderRadius: "6px",
    border: '1px solid',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[1000],
    display: 'flex',
    flexDirection: 'column',
    gap: '11px',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        minHeight: '220px'
    },
}));

const HeadingSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '7px',
    alignItems: 'center'
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.grey[0]
}));

const CampaignSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '7px',
    alignItems: 'center',
    justifyContent: 'space-between'
}));

const CampaignHeading = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.grey[100]
}));

const ViewAll = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.primary.main
}));

const ListItemSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px 0px'

}));

const ListTitleSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '7px',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const CampaignId = styled('div')(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.grey[600]
}));

const Date = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.grey[300]
}));

const Message = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.grey[300]
}));


const SmsSection = () => {
    const [campaignList, setCampaignList] = useState([]);
    const theme = useTheme();

    const campaignDate = (exp) => {
        const dateDiffToString = {
            0: 'Today',
            1: 'Yesterday',
        };
        const date = moment(exp);
        const dateDiff = moment().diff(date, 'days');
        const formattedDate = dateDiffToString[dateDiff] || date.format('D MMMM, YYYY');
        return formattedDate;
    };

    const smsDetails = async () => {
        try {
            const data = JSON.stringify(["sms"])
            const { dashboardDeatils: { sms: { campaignList = [] } } } = await axiosGetCall("consoleDetails", { 'methods': data })
            setCampaignList(campaignList)

        } catch (error) {
            logger.error("smsSection fn: [smsDetails] api:[consoleDetails] get catchError", error)
        }
    }

    const viewAllCampaignList = async () => {
        try {
            await handleRedirect("sms", "", {}, true, theme.palette.mode, "list", "");
        }
        catch (error) {
            logger.error("upcomingMeetSection fn: [viewAllMeetings] api:[shareSession] post catchError", error);
        }
    }

    useEffect(() => {
        smsDetails();
    }, [])
    return (
        <CardsMainSection>
            <HeadingSection>
                <MailVolume />
                <Heading>
                    SMS
                </Heading>
            </HeadingSection>

            <CampaignSection>
                <CampaignHeading>
                    Recent Campaigns
                </CampaignHeading>

                <ViewAll onClick={() => viewAllCampaignList()}>
                    View all
                </ViewAll>
            </CampaignSection>

            <ListItemSection
                sx={{
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    height: 'calc(100% - 21px)',
                }}>
                {campaignList?.length > 0 ? campaignList.map(({ campaignId, startTime, status, statusPercentage, template }) => {
                    return (
                        <>
                            <ListTitleSection>
                                <CampaignId>
                                    Camp Id : <Typography variant='inherit' component={'span'} sx={{ color: 'grey.300' }} >{campaignId}</Typography>
                                </CampaignId>

                                <Date>
                                    {campaignDate(startTime) || '-- / -- / --'}
                                </Date>
                            </ListTitleSection>

                            <Message>
                                {template?.length > 58 ? template.slice(0, 80) + '...' : template}
                            </Message>

                            {status === 'Sending' && (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                    <Box sx={{ width: '100%', mr: 1 }}>
                                        <LinearProgress variant="determinate" value={statusPercentage ?? 0} />
                                    </Box>

                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography variant="body2" color="text.secondary">{`${statusPercentage ?? 0}%`}</Typography>
                                    </Box>
                                </Box>
                            )}
                            <Divider />
                        </>
                    )
                })
                    :
                    // <Box sx={{
                    //     display: 'flex',
                    //     justifyContent: 'center',
                    //     alignItems: 'center',
                    //     height: '100%'
                    // }}>
                    <NetworkError message={commonMessage?.noDataFound} />

                    // </Box>
                }

            </ListItemSection>
        </CardsMainSection>
    )
}

export default SmsSection
