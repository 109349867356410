
// eslint-disable-next-line
const MailActiveIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_235_3940)">
            <path d="M23.198 5.03999C23.0156 5.03999 22.8308 5.10239 22.6724 5.24399L20.0348 7.57679V9.44639L22.6028 7.17359V22.5984H1.40121V7.20959L3.96921 9.52079V7.63679L1.33641 5.26799C1.17801 5.12399 0.990814 5.06159 0.806014 5.06159C0.393214 5.06159 0.00201416 5.38319 0.00201416 5.86319V23.2008C0.00201416 23.6424 0.359614 24 0.801214 24H23.2004C23.642 24 23.9996 23.6424 23.9996 23.2008V5.84399C23.9996 5.36399 23.6084 5.04239 23.1956 5.04239L23.198 5.03999Z" fill="#0C66E4" />
            <path d="M20.0348 0.7992V7.8984L18.6356 9.1392V1.3992H5.36844V9.2184L3.96924 7.9584V0.7992C3.96924 0.3576 4.32684 0 4.76844 0H19.2356C19.6772 0 20.0348 0.3576 20.0348 0.7992Z" fill="#E56910" />
            <path d="M20.0348 7.5768V17.8488C20.0348 18.2352 19.7228 18.5496 19.334 18.5496C18.9452 18.5496 18.6332 18.2376 18.6332 17.8488V10.6848L13.3628 15.3504C12.9596 15.7056 12.4436 15.9024 11.906 15.9024C11.3684 15.9024 10.838 15.7008 10.4348 15.3384L5.3684 10.7784V17.9496C5.3684 18.336 5.0564 18.6504 4.6676 18.6504C4.2788 18.6504 3.9668 18.3384 3.9668 17.9496V7.6368L5.366 8.8968L11.3684 14.2992C11.5196 14.436 11.7116 14.5056 11.9036 14.5056C12.0956 14.5056 12.2828 14.4384 12.434 14.304L18.6332 8.8176L20.0324 7.5768H20.0348Z" fill="#0C66E4" />
            <path d="M16.1012 5.2992H7.90039C7.51398 5.2992 7.19958 4.9848 7.19958 4.5984C7.19958 4.212 7.51398 3.8976 7.90039 3.8976H16.1012C16.4876 3.8976 16.802 4.212 16.802 4.5984C16.802 4.9848 16.4876 5.2992 16.1012 5.2992Z" fill="#E56910" />
            <path d="M14.9012 7.9392H9.1004C8.714 7.9392 8.3996 7.6248 8.3996 7.2384C8.3996 6.852 8.714 6.5376 9.1004 6.5376H14.9012C15.2876 6.5376 15.602 6.852 15.602 7.2384C15.602 7.6248 15.2876 7.9392 14.9012 7.9392Z" fill="#E56910" />
        </g>
        <defs>
            <clipPath id="clip0_235_3940">
                <rect width="24" height="24" fill="white" transform="translate(0.00201416)" />
            </clipPath>
        </defs>
    </svg>
)


export default MailActiveIcon