
import { styled } from '@mui/material/styles';
import { Typography, Box, Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import BrandingForm from '../sections/branding/form';
import { checkPermission } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import AdvancedSettingsMenu from '../sections/branding/advancedSettingsMenu';

const MainSection = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '14px',
    // padding: '76px 12px 12px 70px',
    padding: '62px 12px 12px 72px',
    height: '100%',
    overflowY: 'scroll !important',
    overflow: 'hidden',
    // maxWidth: '1400px',
    [theme.breakpoints.down('sm')]: {
        padding: '62px 12px 12px 12px',
        height: 'auto',
    },
}));

const Card = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "6px",
    border: '1px solid',
    height: '100%',
    overflow: 'hidden',
    // overflowY: 'scroll',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[300],
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: '20px',
    fontWeight: 600,

}));

const Branding = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [advanceSetting, setAdvanceSetting] = useState({
        "twoFa": { "self": false, "downline": false, "hierarchy": false },
        "mothlyVerification": false,
        "signUp": true,
        "googleAuth": true,
        "meetMedia": false,
        "restrictedOrigin": "",
        "blockedOrigin": "",

    });

    const handleClickAdvancedSettings = (event) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const permission = checkPermission()
        if (permission !== 'l4') return navigate('/');

        //eslint-disable-next-line
    }, [])

    return (
        <MainSection>
            <ToastContainer />

            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                mt: '-14px'
            }}>
                <HeadingTypography>
                    Create Custom Branding
                </HeadingTypography>

                <Button
                    variant='outlined'
                    startIcon={<Icon icon={"fluent:mail-settings-32-light"} />}
                    id="Advanced-Settings"
                    onClick={handleClickAdvancedSettings}
                    color='primary'
                >
                    Advanced Settings
                </Button>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: '10px'
            }}>
                <Card >
                    <BrandingForm advanceSetting={advanceSetting} setAdvanceSetting={setAdvanceSetting} setSelectedProducts={setSelectedProducts} />
                </Card>
            </Box>

            <AdvancedSettingsMenu
                open={open}
                handleClose={handleClose}
                handleAdvanceSetting={setAdvanceSetting}
                products={selectedProducts}
                advanceSetting={advanceSetting}
            />

        </MainSection>
    )
}

export default Branding