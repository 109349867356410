import React, { useState, useRef } from "react"
import { styled } from '@mui/material/styles';
import { Typography, Box } from "@mui/material";

/*Icons */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import Logger from "../../lib/Logger";
import { axiosPostCall } from "../../services/apisCall";
import UploadButton from "../../components/Dashboard/kycUploadButton";
import ErrorMessage from "../../utils/ErrorMessage.json"
import { renderErrorMessage } from "../../lib/toastMessage";
import UpdateKycModal from "./updateKycModal";

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '19px',
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '22px',
    fontWeight: 400,
}));

const StatusBanner = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '36px',
    width: '100%',
    alignItems: 'center',
    gap: '10px',
    padding: '10px',
    borderRadius: '5px',
}))

const KycStatusTable = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))

const TableRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '20px',
    borderBottom: '1px solid #D0D0D1',
    height: 'auto',
}))

const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: '400',
}))


const Cell = styled('div')(({ theme }) => ({
    width: '20%'
}))

const logger = new Logger("kycInfo");

const KycInfo = ({ kycFields, setKycFields, kycStatus, kycFiles, setKycFiles, addressDocuments, setAddressDocuments }) => {
    const [fileName, setFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [fieldValue, setFieldValue] = useState("");
    const [updateKyc, setUpdateKyc] = useState(false);
    const [isInput, setIsInput] = useState(true);
    const fileInputRef = useRef(null);

    const FIELDS_MAPPER = {
        companyName: "Company Name",
        pancard: "Pan Number",
        aadhar: "Aadhar Number",
        gst: "GST Number",
        authorizedPersonName: "Authorized Person",
        addressProfType: "Address Proof Type",
        incorporationCertificate: "Incorporation Certificate",
        boardResolution: "Board Resolution",
        signature: "Signature",
        authPancard: "Auth Pan Number",
        crfForm: "CRF Form",
    }

    const INPUT_FIELDS_MAPPER = {
        pancard: "panNumber",
        aadhar: "aadharNumber",
        authPancard: "authPanNumber",
        electricityBill: "address",
        noc: "address",
        rentAgreement: "address",
        gst: "gstNumber"
    }

    const STATUS_MAPPER = {
        pending: {
            name: "Pending Review",
            color: "#C09936",
            icon: <AccessTimeIcon style={{ color: "#C09936" }} />,
        },
        rejected: {
            name: "Rejected",
            color: "#E2533F",
            icon: <ErrorOutlineIcon style={{ color: "#E2533F" }} />,
        },
        approved: {
            name: "Validated",
            color: "#50AB64",
            icon: <CheckCircleOutlineIcon style={{ color: "#50AB64" }} />,
        }
    }

    const addressField = ["electricityBill", "noc", "rentAgreement"];

    const uploadDoc = async (filename) => {
        setFileName(filename);

        if (addressField.includes(filename)) {
            kycFields?.address && setIsInput(true);
            setFieldValue(kycFields?.address);
            setUpdateKyc(true);
            return;
        }

        const fileObject = kycFiles.find(({ name }) => name === filename);

        if (fileObject && fileObject?.value) {
            setIsInput(true);
            setFieldValue(fileObject?.value)
        }
        setUpdateKyc(true);
    }

    const handleFileChange = async (e) => {
        try {

            if(!selectedFile){
                return renderErrorMessage(ErrorMessage?.[4018]);
            }
            const formData = new FormData();
            selectedFile && formData.set(fileName, selectedFile);
            INPUT_FIELDS_MAPPER[fileName] && formData.set(INPUT_FIELDS_MAPPER[fileName], fieldValue);

            await axiosPostCall("reUploadKyc", formData);

            if (addressField.includes(fileName)) {
                const updatedAddressDocuments = addressDocuments.map(obj => (obj.name === fileName ? { ...obj, status: 'pending' } : obj));
                const updatedAddress = {...kycFields, address : (fieldValue || kycFields.address) }
                setAddressDocuments(updatedAddressDocuments);
                setKycFields(updatedAddress);
                setFieldValue("");
                setUpdateKyc(false)
                setSelectedFile(null);
                return;
            }

            const updatedKycFiles = kycFiles.map(obj => (obj.name === fileName ? { ...obj, status: 'pending', value: fieldValue || obj.value } : obj))
            setKycFiles(updatedKycFiles);

            setUpdateKyc(false)
            setFieldValue("");
            setSelectedFile(null);
            setIsInput(false);
        }
        catch (error) {
            logger.error("kycInfo [handleFileChange] reUploadKyc api catchError", error)
            renderErrorMessage(error.message);
        }
    }

    const handleClose = () => {
        setUpdateKyc(false);
        setFieldValue("");
        setSelectedFile(null);
        setIsInput(false);
    }

    return (
        <>
            {updateKyc && <UpdateKycModal
                open={updateKyc}
                handleClose={handleClose}
                titleName={fileName}
                inputName={FIELDS_MAPPER[fileName]}
                file={selectedFile}
                handleFile={setSelectedFile}
                field={fieldValue}
                handleField={setFieldValue}
                handleFileChange={handleFileChange}
                isInput={isInput}
            />}
            <MainSection>
                <Heading sx={{ color: 'grey.700' }}>KYC Info</Heading>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e)}
                />
                {kycStatus === "approved" && <StatusBanner sx={{
                    backgroundColor: '#F0FFF0',
                    gap: '10px',
                    border: '1px solid #50AB64'
                }}>
                    {STATUS_MAPPER[kycStatus]?.icon}
                    <Typography sx={{
                        color: '#282828',
                        fontSize: '12px',
                        fontWeight: 400,
                    }}>
                        Your KYC detail is {kycStatus}
                    </Typography>
                </StatusBanner>}
                <KycStatusTable sx={{
                    border: '1px solid #D0D0D1',
                    borderRadius: '5px',
                }}>
                    {Object.entries(kycFields).map(([key, value]) => (
                        FIELDS_MAPPER[key] && <TableRow key={key}>
                            <Cell key={key}> <CustomTypography sx={{ color: 'grey.600' }}>{FIELDS_MAPPER[key] || ""}</CustomTypography></Cell>
                            <Cell key={value}> <CustomTypography sx={{ color: 'grey.100' }}>{value}</CustomTypography></Cell>
                        </TableRow>
                    ))}
                    {kycFiles.map(({ name, value, file, status }) => (
                        <TableRow key={name}>
                            <Cell key={name}> <CustomTypography sx={{ color: 'grey.600' }}>{FIELDS_MAPPER[name]}</CustomTypography></Cell>
                            <Cell key={value}> <CustomTypography sx={{ color: 'grey.100' }}>{value}</CustomTypography></Cell>
                            <Cell key={file} sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <AttachFileIcon />
                                <Typography variant="custom14500" sx={{ color: '#3069B0' }}>{file}</Typography>
                            </Cell>
                            <Cell key={name + value} sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                {STATUS_MAPPER[status]?.icon}
                                <Typography variant={'custom14500'} sx={{ color: STATUS_MAPPER[status]?.color }}>{STATUS_MAPPER[status]?.name}</Typography></Cell>
                            {status === "rejected" && <UploadButton handleClick={uploadDoc} fieldName={name} />}
                        </TableRow>
                    ))}
                    {addressDocuments.length > 0 && <TableRow key={"address"}>
                        <Cell key={"name"}> <CustomTypography sx={{ color: 'grey.600' }}>Address</CustomTypography></Cell>
                        <Cell key={"value"}> <CustomTypography sx={{ color: 'grey.100' }}>{kycFields?.address || ""}</CustomTypography></Cell>
                        <Cell key={"attach icon"} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {addressDocuments.map(({ file, name }) => (
                                file && <Box key={name} width={'100%'} sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                    <AttachFileIcon />
                                    <Typography variant="custom14500" sx={{ color: '#3069B0' }}>{file}</Typography>
                                </Box>
                            ))}
                        </Cell>

                        <Cell key={"status"} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {addressDocuments.map(({ status, name }) => (
                                <Box key={status + name} width={'100%'} sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                    {STATUS_MAPPER[status]?.icon}
                                    <Typography variant={'custom14500'} sx={{ color: STATUS_MAPPER[status]?.color }}>{STATUS_MAPPER[status]?.name}</Typography></Box>
                            ))}
                        </Cell>

                        <Box key={"button"} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {addressDocuments.map(({ name, status }) => (
                                status === "rejected" ? <UploadButton key={name} handleClick={uploadDoc} fieldName={name} /> : <br key={name}></br>
                            ))}
                        </Box>

                    </TableRow>}
                </KycStatusTable>
            </MainSection>
        </>

    )

}

export default KycInfo;